<template>
    <div id = "TagSelect">
        <div v-if="household">
            <div v-for="tagSetup in household.tagSetup.filter(tS=>tS.tags.length>0)" :key="tagSetup.name">
                <h6 class="padbelow">{{tagSetup.name}}</h6>
                <div 
                    :class="{chip : true, pointer : true, 'selected-chip': selectedTags.includes(tag)}"  
                    v-for="tag in tagSetup.tags" :key="tag"
                    v-on:click="toggleTag(tag)">
                    {{tag}}
                </div>
            </div>
        </div>
        
        <h6 v-if="remainingTags.length>0">Andere tags</h6>
        <div 
            :class="{chip : true, pointer : true, 'selected-chip': selectedTags.includes(tag)}"  
            v-for="tag in remainingTags" :key="tag"
            v-on:click="toggleTag(tag)">
            {{tag}}
        </div>

    </div>
</template>


<script>
// import M from '@materializecss/materialize/dist/js/materialize.min.js'

export default {
    name: 'tag-select',
    // props: ['saveTrigger'],
    props: ['allTags','household'],
    data(){
        return {
            selectedTags:[]
        }
    },
    mounted(){
    },
    computed:{
        remainingTags(){
            return this.allTags.filter(tag=>![].concat(...this.tagSetup.map(tS=>tS.tags)).includes(tag))
        },
        tagSetup(){
            if (this.household){
                return this.household.tagSetup ? this.household.tagSetup : []
            } else {
                return []
            }
        },
    },
    methods:{
        toggleTag(tag){
            console.log('Toggle tag', tag)
            if(this.selectedTags.includes(tag)){
                const index = this.selectedTags.indexOf(tag);
                console.log('removing from arr',this.selectedTags, index)
                this.selectedTags.splice(index, 1);
            } else{
                this.selectedTags.push(tag)
            }
            this.$emit('TagEvent',this.selectedTags)
        }

    }
}
</script>

<style scoped>
.padbelow{
    padding-bottom: 14px;
}

.selected-chip{
    color: white;
    background-color: rgb(35,168,112);
}
.pointer{
    cursor: pointer;
}
</style>