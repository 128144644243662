<template>
    <!-- Modal Structure -->
    <div id="editListItem" class="modal bottom-sheet">
        <div class="modal-content">
            <h4>Item aanpassen</h4>
            
            <div class="input-field">
                <input  type="text" v-model="item.name" >
                <!-- /v-on:keyup.enter="$emit('amendItem',item)" -->
                <label  class="active">Item</label>            
            </div>

            <div v-if="!hideAmount" class="input-field">
                <input  type="text" v-model="item.quantity">
                <label v-bind:class="{active: item.quantity}">Aantal</label>      
            </div>

            <select id="catSelect" class="browser-default" v-model="item.category" v-on:change="saveCategory">
                <!-- <option selected value>{{item.category.icon}}{{item.category.categoryName}}</option> -->
                <option v-for="cat in categoryMasterData" v-bind:key="cat.categoryName" v-bind:value="cat">
                    {{cat.icon}}{{cat.categoryName}}
                </option>
            </select>
            
            <!-- 
                Origin map
                //0 = ad-hoc item, 
                //1 = inventory items, 
                //2 = planned recipes
                //3 = ad-hoc recipe
             -->
            <div v-if="item.origin"> 
                <div v-if="item.origin.type == 2 || item.origin.type == 3" class = "gromstyle pointer" v-on:click="goToRecipe">
                    <br>
                    <i class = "material-icons" >local_dining</i>
                    {{item.origin.text}}
                </div>
                <div v-if="item.origin.type == 2">
                    <br>
                    <i class = "material-icons">today</i>
                    {{item.origin.datePretty}}
                </div>
            </div>
        </div>
        <div  class="modal-footer">
            <a class="modal-close waves-effect btn-flat"  v-on:click="$emit('amendItem',item)">Pas aan</a>
            <a class="modal-close waves-effect left  btn-flat red-text" v-on:click="$emit('deleteItem',item)">
                <i class ="fa fa-trash" ></i> Verwijder
            </a>
        </div>
    </div>
</template>

<script>
    // import db from './firebaseInit'
    import sharedlogic from '@/scripts/sharedlogic'
    // import M from 'materialize-css/dist/js/materialize.min.js'
    export default {
        name: 'editListItem',
        props: ['item', 'modal', 'categoryMasterData', 'user','hideAmount'],
        data(){
            return{
                // categories : [],
                selectedCat : null
            }
        },
        created(){
            console.log('Created - Edit list item',this.item)
        },
        methods:{
            goToRecipe(){
                console.log(this.modal)
                this.modal.close()
                window.scrollTo(0, 0);
                this.$router.push({ name:'view-recipe', params: { recipe_id: this.item.origin.recipeTitle } })
            },
            saveCategory(){
                console.log('Edit list item - Save category')

                console.log(this.item)
                sharedlogic.saveCategory(this.item,this.user).then(()=>{
                    // this.$emit('saveList')
                    this.item.renderId+=1000
                })
            }
        }
    }
</script>

<style scoped>
.pointer{
    cursor: pointer;
}
.material-icons{
    display: inline-flex;
    vertical-align: top;
}


</style>
