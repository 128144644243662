<template>
    <!-- Modal Structure -->
    <div id="exportList" class="modal bottom-sheet">
        <div class="modal-content">
            <h4 v-if="displayAH || displayTodoist" >Boodschappenlijst exporteren</h4>

            <div v-if="displayAH || displayTodoist" class="container">
                <div class="row">
                    <div v-if="displayAH" class="col s6 center-align modal-close " v-on:click="exportTo('AH')">
                        <a class="waves-effect ">
                            <img src="https://firebasestorage.googleapis.com/v0/b/recipes-d95e9.appspot.com/o/ah.png?alt=media&token=216bb60f-d8b1-419f-aab3-5836000c918b" alt="AH" height="60" width = "60">
                        </a>
                    </div>
                    <div v-if="displayTodoist" class="col s6 center-align">
                        <a class="waves-effect modal-close" v-on:click="exportTo('Todoist')">
                            <img src="https://firebasestorage.googleapis.com/v0/b/recipes-d95e9.appspot.com/o/todoist.png?alt=media&token=d83f9d8e-6815-4c6e-b4c1-3eb3b1a66943" alt="Todoist" height="60">
                        </a>
                    </div>
                </div>
            </div>   

            <h4>Snel zoeken bij supermarkt</h4>
            <p>Selecteer je favoriete supermarkt. Op elk item zal een link worden gegenereerd om direct bij je supermarkt te zoeken.</p>

            <div class="container">
                <div class="row">
                    <div class="col s3 center-align modal-close " v-on:click="directSearch('AH')">
                        <a class="waves-effect ">
                            <img src="https://firebasestorage.googleapis.com/v0/b/recipes-d95e9.appspot.com/o/ah.png?alt=media&token=216bb60f-d8b1-419f-aab3-5836000c918b" alt="AH" height="60" width = "60">
                        </a>
                    </div>
                    <div class="col s3 center-align">
                        <a class="waves-effect modal-close" v-on:click="directSearch('Jumbo')">
                            <img src="https://www.jumbo.com/INTERSHOP/static/WFS/Jumbo-Grocery-Site/-/-/nl_NL/images/favicon.ico" alt="Jumbo" height="60">
                        </a>
                    </div>
                    <div class="col s3 center-align">
                        <a class="waves-effect modal-close" v-on:click="directSearch('Plus')">
                            <img src="../assets/pluslogo.png" alt="Plus" height="60">
                        </a>
                    </div>
                    <div class="col s3 center-align">
                        <a class="waves-effect modal-close" v-on:click="directSearch('Coop')">
                            <img src="../assets/coop.jpg" alt="Coop" height="60">
                        </a>
                    </div>
                    
                </div>
            </div>   


        </div>
        <!-- <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat" >Pas aan</a>
        </div> -->
    </div>
</template>

<script>
    // import db from './firebaseInit'
    import sharedlogic from '@/scripts/sharedlogic'
    import M from '@materializecss/materialize/dist/js/materialize.min.js'
    import db from './firebaseInit'
    import {doc,updateDoc} from 'firebase/firestore'
    export default {
        name: 'exportList',
        props: ['list','user'],
        
        data(){
            return{
                //https : false
            }
        },
        created(){
            console.log('Modal export list - created')

        },
        mounted(){
            console.log('Modal export list - mounted')
        },
        computed:{
            displayAH(){
                // var https = false
                // var ah = false
                // if(document.location.href.includes('https')){
                //     https = true
                // }
                // if(this.user){
                //     if(this.user.AHConnector){
                //         ah=true
                //     }
                // }
                
                return false //Added on 20221221: Request package is deprecated. This feature is not used so hidden for now.
                // return !https && ah
            },
            displayTodoist(){
                var ret = false
                if(this.user){
                    if(this.user.todoistAPIKey && this.user.todoistProjectId){
                        ret=true
                    }
                }
                return ret
            }

        },
        methods :{
            directSearch(supermarket){
                const docRef = doc(db, "Users",this.user.id)
                updateDoc(docRef,{favoriteSupermarket : supermarket})
                // db.collection('Users').doc(this.user.id).update({favoriteSupermarket : supermarket})
            },
            exportTo(destination){
                //When having a lot of items, add them slowly to prevent hitting the rate limit of the todoist API.
                var delay = 0
                console.log('Export to, destination : ',destination)

                if (destination == 'Todoist'){
                    delay = 0 //Rate limit of v2 api is 450 requests per 15 minutes. I assume a list is less than 450 items.
                } else if (destination == 'AH'){
                    delay = 1000
                }

                // sharedlogic.getUserData().then(user=>{
                    var i = 0
                    // var responseCodeTodoist
                    this.list.items.forEach(item => {
                        if (!item.done){
                            i++
                            console.log('item',item)
                            setTimeout(()=>{
                                var addProm // = new Promise

                                if (destination == 'Todoist'){
                                    addProm = sharedlogic.addTodoistItem(item, this.user)
                                } else if (destination == 'AH'){
                                    // addProm = sharedlogic.addAHItem(item, this.user)
                                    console.error("AH connector is deprecated.") //Added on 20221221: Request package is deprecated. This feature is not used so hidden for now.
                                }
                                console.log('addProm :',addProm)
                                addProm.then(success =>{
                                    console.log('' + item.name + ' succesfully exported to : '+ destination + '. Response code : ' + success)
                                
                                }).catch(err =>{
                                    console.log('Problem exporting ' +  item.name + ' to : '+ destination + '. Response code : ' + err)
                                    M.toast({text: '❌ Problem exporting ' + item.name + ' to ' + destination + '!'})
                                })
                            }, i*delay );
                        }
                    })
                // })

                
            }
        }
    }
</script>

<style scoped>
    .imgpadding{
        padding: 20px
    }
</style>