<template>
    <div id="inspiration">
        <h4>Inspiratie</h4>
        <p>Kijk wat je een jaar geleden at op deze dag</p>
        <button v-on:click="lastYear" class="btn waves-effect waves-ripple">Vorig jaar</button>
        <table>
            <tr>  
                <th>Recept</th>
                <th>Aantal keer</th>
                <th>Dagen sinds laatste keer</th>
                <th>Gemid-delde tijd</th>
                <th>Factor</th>
            </tr>
            <tbody >   
                <tr v-for="recipe in recSorted" v-bind:key="recipe.recipeTitle">
                    <td>
                        <!-- {{recipe.recipeTitle}} -->
                        <router-link class="gromstyle" 
                            v-bind:to="{name: 'view-recipe', params: {recipe_id: recipe.recipeTitle}}">
                            <span>{{recipe.recipeTitle}}
                            </span>
                        </router-link>
                    </td>
                    <td> {{recipe.eatCount}}</td>
                    <td> {{recipe.timeSinceLastEatDate}}</td>
                    <td>{{recipe.avgTimeBetweenEatDates}}</td>
                    <td> {{recipe.dueToEatFactor}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

export default{
    name: 'inspiration',
    props: ['recipes'],
    data(){
        return {
            // recSorted:[]
        }
    },
    methods:{
        lastYear(){
            var lastYear = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            var lastYearString = lastYear.toLocaleDateString('en-GB').split('/').reverse().join(''); // '20211124'
            // console.log(lastYearString)
            this.$router.push('/calendar/'+lastYearString)
        }
    },
    computed:{
        recSorted(){
            function compare( a, b ) {
            if ( a.dueToEatFactor < b.dueToEatFactor ){
                return 1;
            }
            if ( a.dueToEatFactor > b.dueToEatFactor ){
                return -1;
            }
            return 0;
            }
            var recSorted = this.recipes.filter(recipe => recipe.dueToEatFactor>0)
            recSorted = recSorted.filter(recipe => recipe.avgTimeBetweenEatDates>10)
            recSorted = recSorted.filter(recipe => recipe.dishType=="Volledig gerecht")
            // console.log(recSorted)
            return recSorted.sort(compare)
        }
    }
}

</script>