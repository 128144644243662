<template>
    <div>
        <div class="navbar-fixed">
            <nav> 
                <div class="nav-wrapper secondary-color">
                    <div class="container">
                        <ul class="left">
                            <li  v-if="isLoggedIn" class="pointer first-button " v-on:click="$router.go(-1)">
                                <i class="material-icons waves-effect waves-ripple light">chevron_left</i></li>
                            <li  
                                v-if="isLoggedIn && showSave && online" 
                                :class="['pointer non-first-button scale-transition', scaleInSave]" 
                                v-on:click="$emit('save')">
                                    <i class="material-icons waves-effect waves-ripple light">save</i>
                            </li>
                            <li
                                v-if="isLoggedIn && !online">
                                <i 
                                    id="tooltipOnline"
                                    class = "material-icons red-text tooltipped"                                    
                                    data-position="bottom" 
                                    data-tooltip="Je bent offline. De functionaliteit van de app is beperkt als je offline bent."
                                >warning</i>
                            </li>
                            <li v-if="!isLoggedIn" class="brand-logo left"> 
                                <router-link to="/home">
                                    <img class = "logopad" src="../assets/No pizza white.png" width="160">
                                </router-link>
                            </li>

                        </ul>
                        
                        <ul class="right">
                            <li v-if="!isLoggedIn"><router-link to="/login">Log in</router-link></li>
                            <li v-if="!isLoggedIn"><router-link to="/register">Registreer</router-link></li>
                            
                            <li v-if="isLoggedIn" href="#" data-target="mobile" class="sidenav-trigger right pointer show-on-large last-button"><i class="material-icons">menu</i></li>
                            
                            <li v-if="isLoggedIn" class=" pointer non-first-button " ><router-link class="waves-effect waves-ripple light" to="/list"><i class="material-icons">checklist</i></router-link></li>
                            <li v-if="isLoggedIn" class=" pointer non-first-button " ><router-link class="waves-effect waves-ripple light" to="/calendar"><i class="material-icons">today</i></router-link></li>
                            <li v-if="isLoggedIn" class=" pointer non-first-button " ><router-link class="waves-effect waves-ripple light" to="/"><i class="material-icons">local_dining</i></router-link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div>
            <ul class="sidenav" id="mobile">
                <div class="background">
                    <img src="../assets/No pizza.png" width="258">
                </div>
                <li v-if="isLoggedIn" class="sidenav-close"><router-link to="/list"><i class="material-icons">checklist</i>Boodschappenlijst</router-link></li>        
                <li v-if="isLoggedIn" class="sidenav-close"><router-link to="/calendar"><i class="material-icons">today</i>Maaltijd planner</router-link></li>
                <li v-if="isLoggedIn" class="sidenav-close"><router-link to="/"><i class="material-icons">local_dining</i>Recepten</router-link></li>
                <li v-if="isLoggedIn" class="sidenav-close">
                    <router-link to="/settings"><i class="material-icons">settings</i>Instellingen</router-link>
                </li>
                
                <li v-if="isLoggedIn" class="sidenav-close"><button v-on:click="logout"  class="btn black">Log uit</button></li>
              
                <!-- User information -->
                <!-- <li v-if="isLoggedIn" class="sidenav-close">
                    <div class="text-sidenav"><i class="material-icons light">person</i>{{currentUser}}</div>
                    <div v-if="activeHousehold" class="light text-sidenav "><i class="material-icons">restaurant</i>{{activeHousehold.name}}</div>
                </li> -->

                <li v-if="isLoggedIn">
                    <router-link to="/profile" class="truncate text-sidenav sidenav-close"><i class="material-icons">person</i>{{currentUser}}</router-link>
                </li>
                <li v-if="isLoggedIn">
                    <router-link to="/household" v-if="activeHousehold" class="text-sidenav sidenav-close"><i class="material-icons">restaurant</i>{{activeHousehold.name}}</router-link>
                </li>
                
                <div v-if="user">
                <li v-if="isLoggedIn && user.admin"><div class="divider"></div></li>
                <li v-if="isLoggedIn && user.admin" ><a class="subheader">Developer opties</a></li>
                <li v-if="isLoggedIn && user.admin" class="sidenav-close"><router-link to="/bulk_export">Bulk export</router-link></li>
                <li v-if="isLoggedIn && user.admin" class="sidenav-close"><router-link to="/bulk_add">Bulk add</router-link></li>
                <li v-if="isLoggedIn && user.admin" class="sidenav-close"><router-link to="/ingredientlist">Prepare ingredient list</router-link></li>
                <li v-if="isLoggedIn && user.admin" class="sidenav-close"><router-link to="/orphanrecords">Delete orphan records</router-link></li>
                <li v-if="isLoggedIn && user.alpha" class="sidenav-close"><router-link to="/testing_grounds">Testing grounds</router-link></li>
                
                <li v-if="isLoggedIn  && user.beta"><div class="divider"></div></li>
                <li v-if="isLoggedIn  && user.beta"><a class="subheader">Beta functies</a></li>
                <li v-if="isLoggedIn  && user.beta" class="sidenav-close"><router-link to="/inspiration">Inspiratie</router-link></li>
                
                <li v-if="isLoggedIn" class="sidenav-close"><button v-on:click="reloadPage"  class="btn black">App refresh</button></li>               
                </div>
            </ul>
        </div>
    </div>   
</template>


<script>
// import firebase from 'firebase/compat/app'
import {getAuth, signOut} from 'firebase/auth'
import M from '@materializecss/materialize/dist/js/materialize.min.js'

export default {
    name: 'navbar',
    props: ['showSave','activeHousehold','user','dataReady','online'],
    data(){
        return {
            isLoggedIn: false,
            currentUser: false,
            jq: false,
            scaleInSave: '',
            tooltipOnline : null
        }
    },   
    created() {
        if (getAuth().currentUser){
        // if(firebase.auth().currentUser){
            this.isLoggedIn= true
            // this.currentUser = firebase.auth().currentUser.email
            this.currentUser = getAuth().currentUser.email
        }

        //this.scaleInSave = 'scale-in'
    },
    mounted(){
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {draggable: false});
        
        this.initTooltipOnline()
    },
    watch: {
        'online' : 'initTooltipOnline'
    },
    methods:{
        initTooltipOnline(){
            console.log("Init tooltip online : ", this.tooltipOnline)
            if (this.tooltipOnline){
                this.tooltipOnline.destroy()
            }
            
            this.$nextTick().then(()=>{
                var elemsT = document.querySelector('#tooltipOnline');
                console.log("Query selector tooltipOnline :", elemsT)
                this.tooltipOnline = M.Tooltip.init(elemsT, {});
            })

        },
        logout: function () {
            const auth = getAuth()
            signOut(auth).then(()=>{
            // firebase.auth().signOut().then(()=>{
                //this.$router.go({ path: this.$router.path });
                this.$emit('logOff')
                this.$router.push('/login');
            })
        },
        reloadPage(){
            console.log('reloadPage');
            window.location.reload()
        }
    }
}
</script>

<style scoped>
    .text-sidenav{
        padding-right: 10px;
        padding-left: 10px
        }
    /* .navbar-fixed{padding-top:40 px} */
    .logopad{
        padding-top: 5px;           
    }



    .pointer{
        /* padding-right: 10px;
        padding-left: 10px; */
        cursor: pointer;
    }
    .first-button{
        padding-right: 10px;
    }
    .non-first-button{
        padding-left: 8px;
        padding-right: 8px;
    }
    .last-button{
        margin-right: 0px;
    }

    .background{
        padding-left: 21px;
        padding-top: 21px;
    }

    .material-icons{
        vertical-align: middle; 
        padding-right:5px  
    }

    nav .brand-logo {
        font-size: 1rem;
    }

</style>