<template>
    <div :id="'DayCard'+id">
        <div class="card "
            @drop="onDrop($event, day.dateUgly)"   
            @dragover.prevent
            @dragenter.prevent >
            <a 
                class="btn-floating halfway-fab waves-effect waves-light dropdown-trigger gromstyle-light" 
                :data-target="'FABDropdown'+day.index" 
                href='#'>
                <i class="material-icons">add</i>
            </a>
            <!-- FAB Dropdown Structure -->
            <ul :id="'FABDropdown'+day.index" class='dropdown-content'>
                <li v-on:click="newRecipe(day)"><a ><i class = "material-icons ma-styling waves-effect">local_dining</i> Recept </a></li>
                <li v-on:click="
                    day.addNote = true 
                    day.index++"><a><i class = "material-icons ma-styling waves-effect">note_add</i>Notitie</a></li>
            </ul>

            <div class="card-content">
                
                <span v-if ="day.label" :class="['new badge', day.label.color]" data-badge-caption="">{{day.label.msg}}</span>
                <span class="card-title">{{day.datePretty}}  </span>

                <ul v-if ="day.recipesToday || day.notesToday" class="collection collection-light-ul">
                    <li class="collection-item collection-light" v-for="recipe in day.recipesToday" v-bind:key="recipe.id"
                        
                        draggable
                        @dragstart="startDrag($event, recipe, day)"                    
                    >

                        <a class="dropdown-trigger right" :data-target="'RecipeDropdown'+recipe.index+day.index" href="#">
                            <i class ="material-icons ma-styling gromstyle" >more_vert</i>
                        </a>
                        <router-link class="gromstyle" 
                            v-bind:to="{name: 'view-recipe', params: {recipe_id: recipe.recipeTitle}}">
                            <span>{{recipe.recipeTitle}}
                            </span>
                        </router-link>

                        <!-- Recipe Dropdown Structure -->
                        <ul :id="'RecipeDropdown'+recipe.index+day.index" class='dropdown-content'>
                            <li v-on:click="removeRecipeFromDay(day.dateUgly, recipe.id)"><a href="#!"><i class ="fa fa-trash" ></i>Verwijder</a></li>
                        </ul>
                        
                    </li>
                    
                    <li class="collection-item collection-light" v-for="note in day.notesToday" v-bind:key="note.index"
                        
                        draggable
                        @dragstart="startDrag($event, note, day)">
                        
                        <!-- <a :id="'NoteDropdown'+note.index+day.index" class="dropdown-trigger" data-target='NoteDropdown' :data-day="day.dateUgly" :data-note="note.note" href="#"><i class ="material-icons padright ma-styling gromstyle" >more_vert</i></a> -->
                        <a class="dropdown-trigger right" :data-target="'NoteDropdown'+note.index+day.index" href="#">
                            <i class ="material-icons ma-styling gromstyle" >more_vert</i>
                        </a>
                        <span>
                            {{note.note}}                           
                        </span>

                        <!-- Note Dropdown Structure -->
                        <ul :id="'NoteDropdown'+note.index+day.index" class='dropdown-content'>
                            <li v-on:click="deleteNote(day.dateUgly, note.note)"><a href="#!"><i class ="fa fa-trash" ></i>Verwijder</a></li>
                        </ul>

                    </li>
                </ul>
                

                <!-- Empty state -->
                <div v-else >
                    <div v-if="recipesLoaded && notesLoaded" class="lighttext">
                        Nog niets ingepland.
                    </div>
                </div>

                <spinner class='center-align' v-if="!recipesLoaded || !notesLoaded" :size="'small'" />

                <!-- Dropdown Structure -->
                <!-- <ul id='NoteDropdown' class='dropdown-content'> -->
                    <!-- <li v-on:click="deleteNoteViaEl()"><a href="#!"><i class ="fa fa-trash" ></i>Verwijder</a></li> -->
                    <!-- <li v-on:click="deleteNote(day.dateUgly, note.note)"><a href="#!"><i class ="fa fa-trash" ></i>Verwijder</a></li> -->
                    <!-- <li><a href="#!"><i class = "material-icons  waves-effect">edit</i>Notitie</a></li> -->
                <!-- </ul> -->

                <div v-if ="day.addNote">        
                    <input type="text" v-model="day.newNote" v-on:keyup.enter="addNewNote(day)">
                    <button  v-on:click="addNewNote(day)" class="btn btnpad waves-effect waves-ripple">Voeg toe</button>  
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import db from './firebaseInit'
import {doc, updateDoc} from 'firebase/firestore'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import Spinner from './Spinner.vue'

export default {
    name: 'DayCard',
    props:['day','recipes','noteList','calendar','id','notesLoaded','recipesLoaded'],
    data() {
        return {
        }   
    },
    components:{
        Spinner
    },
    computed:{

    },
    mounted(){
        // var elems = document.querySelectorAll('.dropdown-trigger');
        var elems = this.$el.querySelectorAll('.dropdown-trigger')
        
        if(elems.length>0){
            console.log('elems', elems)
            var dropdown = M.Dropdown.init(elems, {
                constrainWidth:false,
                // container:'DayCard'+this.id
                container:document.body
            })
            console.log('Dropdown' , dropdown)
        }

        // console.log('$el', this.$el.querySelectorAll('.dropdown-trigger'))
    },
    methods:{
        onDrop(event,newDate){
            console.log('Dropped : ', event, newDate)
            const type = event.dataTransfer.getData('type')
            
            var previousDate = event.dataTransfer.getData('date')
            

            console.log('Dropped type', type)
            console.log("Dropped previous date : ", previousDate)

            if (previousDate == newDate){
                return // Item is dropped on the same day. Do nothing, to prevent items being rearranged.
            }

            if (type=='note'){
                var note = event.dataTransfer.getData('note')
                console.log("Dropped note: ", note)
                
                this.deleteNote(previousDate,note)
                this.addNote(newDate,note)
            } else if (type == 'recipe'){
                var recipeId = event.dataTransfer.getData('recipeId')
                console.log('Dropped recipe :', recipeId)

                this.removeRecipeFromDay(previousDate,recipeId)
                this.addRecipeOnDay(newDate,recipeId)
            } else{
                console.error('Dropped data not recognized')
            }

            

        },  
        startDrag(event,itemToDrag,day){
            
            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'copyMove'
            
            event.dataTransfer.setData('date', day.dateUgly)
            if(itemToDrag.note){
                event.dataTransfer.setData('type', 'note')
                event.dataTransfer.setData('note', itemToDrag.note)
                // event.dataTransfer.setData('date', itemToDrag.date)
            } else { //Recipe
                event.dataTransfer.setData('type', 'recipe')
                event.dataTransfer.setData('recipeId', itemToDrag.id)
                // event.dataTransfer.setData('date', day.dateUgly)
            }
            
            console.log("Drag start", event, itemToDrag, day)
        },
        newRecipe(day){
            //console.log(window)
            console.log(window.pageYOffset)
            //TODO: Store window.pageYOffset so we can return to the same scroll position on calendar after choosing a recipe.
            window.scrollTo(0, 0)
            this.$router.push({ name:'selectRecipeToEatOn', params: { date: day.dateUgly } })
        },
        addNote(date,note){
            console.log('addNote', date, note)
            this.noteList.notes.push({
                date : date,
                note: note
            })

            this.saveNotes()
        },
        addNewNote(day){
            console.log('addNewNote : ', day)
            console.log(day.addNote)
            if (!day.newNote){
                day.addNote = false
                day.index++
                return
            }

            if (day.newNote.trim().length == 0){
                day.addNote = false
                day.index++
                return
            }
            
            /*This section is not necessary because notelist is already being watched in Calendar? 20220914
            if (day.notesToday == null){
                day.notesToday = []
            }
            day.notesToday.push(day.newNote)
            */
           
            // this.noteList.notes.push({
            //     date : day.dateUgly,
            //     note: day.newNote
            // })

            this.addNote(day.dateUgly, day.newNote)

            day.addNote = false
            day.newNote = null
            day.index++

            // this.saveNotes()

        },
        deleteNote(dateUgly,noteToDelete){
            console.log('deleteNote', dateUgly, noteToDelete)
            console.log(dateUgly)
            console.log(noteToDelete)

            // var indexToRemove = day.notesToday.indexOf(note)          
            // day.notesToday.splice(indexToRemove,1)

            // var indexToRemove = this.noteList.notes.indexOf({date: day.dateUgly, note:note})         
            var i = 0
            var indexToRemove = -1
            console.log('notelist',this.noteList)
            this.noteList.notes.forEach(note=>{
                
                if (note.date == dateUgly && note.note == noteToDelete){
                    indexToRemove = i
                    // console.log('indexToRemove')
                }
                i++
            })
            
            console.log(indexToRemove)
            console.log(this.noteList)
            if(indexToRemove == -1){
                console.error("Unable to find note to delete")
            }
            this.noteList.notes.splice(indexToRemove,1)

            // day.index++
            this.saveNotes()


        },
        saveNotes(){
            // db.collection('Notes').doc(this.noteList.id).update({notes: this.noteList.notes})
            const docRef = doc(db, "Notes", this.noteList.id)
            updateDoc(docRef,{notes: this.noteList.notes})
        },
        fetchRecipeFromRecipes(recipeId){
            var recipeIndex = this.recipes.map(recipe => recipe.id).indexOf(recipeId)
            return this.recipes[recipeIndex]
        },
        addRecipeOnDay(date, recipeId){
            var recipe = this.fetchRecipeFromRecipes(recipeId)
            
            
            if (recipe.eatDate ==null){
                recipe.eatDate = []
            }      
            
            recipe.eatDate.push(date)

            console.log("About to update eatdate on recipe : ", recipe )
            const docRef = doc(db, "Recipes", recipe.id)
            updateDoc(docRef,{
                eatDate: recipe.eatDate
            }).then((msg)=>{
                console.log("Added new eatdate on recipe succesfully with message : ", msg)
            }).catch(error=>{
                console.error("Error while adding eatdate to recipe : ", error)
            })
        },
        removeRecipeFromDay(dateUgly, recipeId){
                    
            console.log('delete eat date', dateUgly, recipeId)
            
            /* 20220915: Removed - not necessary I believe
            //remove recipe from calendar
            var dayIndex = this.calendar.findIndex(calendarDay => calendarDay.dateUgly == day.dateUgly)
            var recipeCalendarIndex = this.calendar[dayIndex].recipesToday.findIndex(recipe => recipe.id ==recipeId)
            this.calendar[dayIndex].recipesToday.splice(recipeCalendarIndex,1)
            */


            //remove eatdate from recipes array           
            var recipeIndex = this.recipes.map(recipe => recipe.id).indexOf(recipeId)
            var newEatDate = this.recipes[recipeIndex].eatDate.filter(ed => ed !== dateUgly)
            this.recipes[recipeIndex].eatDate = newEatDate

            //Remove eatdates from database
            const docRef = doc(db, "Recipes", recipeId)
            updateDoc(docRef,{
                  eatDate: newEatDate
            })

            // this.$forceUpdate() //20220915: Removed - not necessary I believe
        }


    }
}

</script>

<style scoped>
.fa{
    padding-left: 6px;
    line-height: inherit;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}


/* .collection{
    border: 0px;
}

.collection .collection-item{
    padding-left:0px;
} */



.padright{
    padding-right: 10px;
}

.ma-styling{
    /* padding-right: 12px; */
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
}

.lighttext{
    color:grey
}

.grab{
    cursor: grab;
}

/* .grab:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
} */


</style>