<template>
    <div id ="modalHousehold" class="modal modal-fixed-footer">
        <div class="modal-content">
            <h4 v-if="hh.new">Nieuw huis</h4>
            <h4 v-else>Huis wijzigen </h4>
            <br>
            <div class="input-field col s12">
                <input type="text" v-model="hh.name" required class="validate">
                <label :class="{active : hh.name}">Naam huis</label>
            </div>

            <h5 >Leden</h5>
            <ul class="collection">
                <li v-for="member in hh.membersExtraInfo" v-bind:key="member.id" class="collection-item">
                    <a 
                        href="#"
                        class="secondary-content gromstyle"
                    >
                        <i class ="fa fa-trash" v-on:click="removeMember(member)"></i>
                    </a>
                    
                    <div>
                        <i class="material-icons tiny">person</i> {{member.name}}
                        <div class="light"> {{member.email}}</div>
                    </div>

                </li>
                <li v-for="invite in hh.invites" v-bind:key="invite" class="collection-item">
                    <a 
                        href="#"
                        class="secondary-content gromstyle"
                    >
                        <i class ="fa fa-trash" v-on:click="removeInvite(invite)"></i>
                    </a>
                    <i class="material-icons tiny">person_add</i> 
                    Uitgenodigd
                    <div class="light"> {{invite}}</div>
                </li>
            </ul>
        

            <span>Vul een emailadres in om een nieuwe deelnemer uit te nodigen.</span>
            <div class="row">
                <div class="col s10">    
                    <div class="input-field">
                        <input id="newMember" type="text" v-model="newMember" v-on:keyup.enter="addNewMember">
                        <label for="newMember">Email adres</label>            
                    </div>
                </div>
                <div class="col s2">    
                    <button v-on:click="addNewMember" class="btn waves-effect waves-ripple btnpad">
                        <i class="material-icons">add</i>
                    </button>    
                </div>
            </div>


        </div>
        <div class="modal-footer">
            <a href="#" 
                class="btn waves-effect waves-ripple modal-close"
                v-on:click="saveHousehold()">
                Opslaan
            </a>
            <a class="modal-close waves-effect left  btn-flat">Annuleren</a>
        </div>


    </div>
</template>

<script>
import db from './firebaseInit'
import {addDoc, updateDoc, doc, collection} from 'firebase/firestore'

export default {
    name: 'modalHousehold',
    props: ['hh','user'],
    data(){
        return{
            newMember:'',
            // invites:[]
        }
    },
    methods:{
        addNewMember(){
            console.log('Add member')
            if (!this.newMember.includes("@")){
                alert("Vul een geldig emailadres in.")
                return
            }

            if (!this.hh.invites){
                this.hh.invites=[]
            }
            console.log(this.hh.invites.includes(this.newMember))
            console.log(this.hh.invites)
            if(this.hh.membersExtraInfo.map(mei=>mei.email).includes(this.newMember.toLowerCase())){
                alert("Deze gebruiker is al lid van dit huis.")
            } else if (this.hh.invites.includes(this.newMember.toLowerCase())) {
                alert("Deze gebruiker is al uitgenodigd.")
            } else {
                this.hh.invites.push(this.newMember.toLowerCase())
                this.newMember = ''
            }
            
        },
        saveHousehold(){
            if(this.hh.new){
                addDoc(collection(db,"Household"),{
                    name : this.hh.name ? this.hh.name : null,
                    members : this.hh.members ? this.hh.members : [],
                    membersExtraInfo: this.hh.membersExtraInfo? this.hh.membersExtraInfo : [],
                    invites: this.hh.invites ? this.hh.invites : []    
                // })
                // db.collection('Household').add({
                //     name : this.hh.name ? this.hh.name : null,
                //     members : this.hh.members ? this.hh.members : [],
                //     membersExtraInfo: this.hh.membersExtraInfo? this.hh.membersExtraInfo : [],
                //     invites: this.hh.invites ? this.hh.invites : []
                }).then(docRefHousehold=>{
                    console.log("About to save list: " )
                    console.log( {
                        active : true,
                        createdDate: new Date(),
                        creator: this.user.id,
                        household: docRefHousehold.id,
                        items : []
                    })
                    addDoc(collection(db,"List"),{             
                    // })
                    // db.collection('List').add({
                        active : true,
                        createdDate: new Date(),
                        creator: this.user.id,
                        household: docRefHousehold.id,
                        items : []
                    }).then(listRef=>{
                        console.log('Created a new list with ID: ' + listRef.id)
                    })

                    addDoc(collection(db,"Notes"),{
                    // db.collection('Notes').add({
                        creator: this.user.id,
                        household: docRefHousehold.id,
                        notes : []
                    }).then(noteListRef=>{
                        console.log('Created a new note list with ID: ' + noteListRef.id)
                    })

                    addDoc(collection(db,"InventoryList"),{
                    // db.collection('InventoryList').add({
                        creator: this.user.id,
                        household: docRefHousehold.id,
                        inventory : []
                    }).then(inventoryListRef=>{
                        console.log('Created a new inventorylist list with ID: ' + inventoryListRef.id)
                    })

                })

            } else{
                const docRef = doc(db,"Household",this.hh.id)
                updateDoc(docRef,{
                // db.collection('Household').doc(this.hh.id).update({
                    name : this.hh.name ? this.hh.name : null,
                    members : this.hh.members ? this.hh.members : [],
                    membersExtraInfo: this.hh.membersExtraInfo? this.hh.membersExtraInfo : [],
                    invites: this.hh.invites ? this.hh.invites : []
                })
            }

        },
        removeMember(member){
            console.log('Remove member')
            console.log(member)

            if(member.id==this.user.id){
                alert("PAS OP: Als je jezelf uit een huis verwijdert, kun je dit niet ongedaan maken.")
            }
            
            var indexToRemove = this.hh.membersExtraInfo.indexOf(member)          
            this.hh.membersExtraInfo.splice(indexToRemove,1)

            indexToRemove = this.hh.members.indexOf(member.id)   
            this.hh.members.splice(indexToRemove,1)
        },
        removeInvite(invite){
            var indexToRemove = this.hh.invites.indexOf(invite)          
            this.hh.invites.splice(indexToRemove,1)
        }
    }
}
</script>
<style scoped>
.btnpad{
    margin-top: 20px;
}
</style>