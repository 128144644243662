<template>
    <!-- Modal Structure -->
    <div id="importItems" class="modal bottom-sheet">
        <div class="modal-content">
            <h4>Snel items toevoegen aan je boodschappenlijst</h4>
            
            <div class="container">
                <div class="row">
                    <div  class="col s6 center-align modal-close " >
                        <div class="collection waves-effect" v-on:click="$emit('addInvItems')" >
                            <i class="material-icons large">shopping_basket</i>
                            <div class="container center-align"><p>Voeg je vaste boodschappen toe.</p></div>
                        </div>
                    </div>
                    <div class="col s6 center-align">
                        <div class="collection waves-effect modal-close" v-on:click="$emit('addCalendaritems')" >
                            <i class="material-icons large">today</i>
                            <div class="container center-align"><p>Voeg de ingrediënten van je ingeplande maaltijden toe.</p></div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

        <!-- <div class="modal-footer">
            <a href="#!" class="modal-close waves-effect waves-green btn-flat" >Pas aan</a>
        </div> -->
    </div>
</template>

<script>

export default {
    name: 'importItems'
}
</script>