<template>
    <div id ="OrphanRecords">
        <!-- Some records will belong to users and households that do no longer exist. This page intends to identify them and give the admin the oppurtunity to delete them. -->
        <h4>Identify and delete orphan records</h4>
        
        <h5>Users</h5>
        <button v-on:click="getUsers()" class="btn waves-effect waves-ripple">
            <span>Get Users</span>
        </button> 
        <table>
            <th>
            </th>
            <tr v-for="user in users" v-bind:key="user.id">
                <td>
                    {{user.id}}
                </td>
                <td>
                    {{user.email}}
                </td>
            </tr>
        </table>

        <h5>Households</h5>
        <!-- <button v-on:click="getHouseholds()" class="btn waves-effect waves-ripple">
            <span>Get Households</span>
        </button>  -->
        <table>
            <th>
            </th>
            <tr v-for="hh in households" v-bind:key="hh.id">
                <td>
                    {{hh.id}}
                </td>
                <td>
                    {{hh.name}}
                </td>
                <td v-if="hh.membersExtraInfo">
                    <div v-for="member in hh.membersExtraInfo" v-bind:key="member.id" :class="{doesNotExist : !member.exists}">
                        <span>{{member.email}} {{member.id}}</span>
                    </div>
                </td>
                <td v-else class="doesNotExist">
                    No membersExtraInfo
                </td>
                <td>
                    <button v-on:click="deleteHouseHold(hh.id)" class="btn waves-effect waves-ripple">
                        <span>Delete household</span>
                    </button> 
                </td>
            </tr>
        </table>

        <h5>Inventory</h5>

        <table>
            <thead>
                <th>
                    Inv ID
                </th>
                <th>
                    Inv Name
                </th>
                <th>
                    Creator ID
                </th>
                <th>
                    Household ID
                </th>                                                
            </thead>
            <tr v-for="inv in inventory" v-bind:key="inv.id">
                <td>
                    {{inv.id}}
                </td>
                <td>
                    {{inv.invItemName}}
                </td>
                <td :class="{doesNotExist : !inv.creatorExists}">
                    {{inv.creator}}
                </td>            
                <td :class="{doesNotExist : !inv.hhExists}">
                    {{inv.household}}
                </td>                    
                <td>
                    <button v-on:click="deleteInv(inv.id)" class="btn waves-effect waves-ripple">
                        <span>Delete inv</span>
                    </button> 
                </td>
            </tr>
        </table>

        <h5>Notes</h5>

        <table>
            <thead>
                <th>
                    Note ID
                </th>
                <th>
                    Household ID
                </th>
                <th>
                    Number of notes
                </th>                                  
            </thead>
            <tr v-for="note in notes" v-bind:key="note.id">
                <td>
                    {{note.id}}
                </td>
                <td :class="{doesNotExist : !note.hhExists}">
                    {{note.household}}
                </td>
                <td>
                    {{note.numberOfNotes}}
                </td>                           
                <td>
                    <button v-on:click="deleteNote(note.id)" class="btn waves-effect waves-ripple">
                        <span>Delete note</span>
                    </button> 
                </td>
            </tr>
        </table>        

    </div>
</template>

<script>
import db from './firebaseInit'
import {collection, onSnapshot, deleteDoc, doc, query} from 'firebase/firestore'

export default {
    name: 'OrphanRecords',
    data(){
        return{
            users :[],
            households:[],
            inventory:[],
            notes:[]
        }
    },
    created(){
        this.getUsers()
        this.getHouseholds()
        this.getInventory()
        this.getNotes()
    },
    methods:{
        deleteNote(id){
            const docRef = doc(db,"Notes",id)
            deleteDoc(docRef)
            // db.collection('Notes').doc(id).delete()
        },          
        deleteHouseHold(id){
            const docRef = doc(db,"Household",id)
            deleteDoc(docRef)            
            // db.collection('Household').doc(id).delete()
        },
        deleteInv(id){
            const docRef = doc(db,"Inventory",id)
            deleteDoc(docRef)            
            // db.collection('Inventory').doc(id).delete()
        },
        getUsers(){
            const q = query(collection(db,"Users"))
            onSnapshot(q,querySnapshot=>{
                this.users=[]
                querySnapshot.forEach(doc=>{
                    var user = doc.data()
                    user.id = doc.id
                    this.users.push(user)
                })                
            })
            // db.collection('Users').onSnapshot(qs=>{
            //     this.users=[]
            //     qs.forEach(doc=>{
            //         var user = doc.data()
            //         user.id = doc.id
            //         this.users.push(user)
            //     })
            // })
        },
        getHouseholds(){
            const q = query(collection(db,"Household"))
            onSnapshot(q,querySnapshot=>{
                this.households=[]
                querySnapshot.forEach(doc=>{
                    var hh = doc.data()
                    hh.id = doc.id
                    if(hh.membersExtraInfo){
                        hh.membersExtraInfo.forEach(member=>{
                            if( this.users.map(user=>user.id).includes(member.id)){
                                member.exists=true
                            } 
                        })
                    }
                    
                    this.households.push(hh)
                })
            })
            // db.collection('Household').onSnapshot(qs=>{
            //     this.households=[]
            //     qs.forEach(doc=>{
            //         var hh = doc.data()
            //         hh.id = doc.id
            //         if(hh.membersExtraInfo){
            //             hh.membersExtraInfo.forEach(member=>{
            //                 if( this.users.map(user=>user.id).includes(member.id)){
            //                     member.exists=true
            //                 } 
            //             })
            //         }
                    
            //         this.households.push(hh)
            //     })
            // })
        },
        getInventory(){
            const q = query(collection(db,"Inventory"))
            onSnapshot(q,querySnapshot=>{
                this.inventory=[]
                querySnapshot.forEach(doc=>{
                    var inv = doc.data()
                    inv.id = doc.id
                    if(this.households.map(hh=>hh.id).includes(inv.household)){
                        inv.hhExists=true
                    }
                    if( this.users.map(user=>user.id).includes(inv.creator)){
                        inv.creatorExists=true
                    } 
                    this.inventory.push(inv)
                })

            })
            // db.collection('Inventory').onSnapshot(qs=>{
            //     this.inventory=[]
            //     qs.forEach(doc=>{
            //         var inv = doc.data()
            //         inv.id = doc.id
            //         if(this.households.map(hh=>hh.id).includes(inv.household)){
            //             inv.hhExists=true
            //         }
            //         if( this.users.map(user=>user.id).includes(inv.creator)){
            //             inv.creatorExists=true
            //         } 
            //         this.inventory.push(inv)
            //     })
            // })
        },
        getNotes(){
            const q = query(collection(db,"Notes"))
            onSnapshot(q,querySnapshot=>{            
            // db.collection('Notes').onSnapshot(qs=>{
                this.notes=[]
                querySnapshot.forEach(doc=>{
                    var note = doc.data()
                    note.id=doc.id
                    note.numberOfNotes=note.notes.length
                    if(this.households.map(hh=>hh.id).includes(note.household)){
                        note.hhExists=true
                    }                    
                    this.notes.push(note)
                })
            })
        }      
    }
}
</script>

<style scoped>
.doesNotExist{
    background-color: red;
}
</style>