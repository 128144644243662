<template>
  <div>
    <div class="container">
    <div class="row">
      <div class="col s12 ">
        <div class="card-panel grey lighten-4 center">
          <h3>Registreer</h3>
          <!-- <form action="index.html"> -->
            <div class="row">
              <div class="input-field col s12">
                <i class="material-icons prefix gromstyle-light">email</i>
                <input type="email" id="email" v-model="email">
                <label for="email">Email adres</label>
              </div>
              <div class="input-field  col s12">
                <i class="material-icons prefix gromstyle-light">lock</i>
                <input type="password" id="password" v-model="password">
                <label for="password">Wachtwoord</label>
              </div>
              <div class="input-field col s12">
                <i class="material-icons prefix gromstyle-light">person</i>
                <input type="text" id="name" v-model="name">
                <label for="name">Naam</label>
              </div>
              <div class="input-field col s10">
                <i class="material-icons prefix gromstyle-light">lock</i>
                <input type="text" id="househouldName" v-model="householdName">
                <label for="househouldName" class="active">Huis</label>
              </div>
              <div class="col s2">    
                  <button v-on:click="generateHouseholdName" class="btn waves-effect waves-ripple btnpad gromstyle-light"><i class="material-icons">refresh</i></button>    
              </div>
            </div>
            <button v-on:click="register" class="btn btn-large btn-extended ">Registreer</button>
          <!-- </form> -->
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// import firebase from 'firebase/compat/app'
import sharedlogic from '@/scripts/sharedlogic'
import {placeTypes} from '@/data/wordlist'
import {placeNames} from '@/data/wordlist'
import {placeAdjectives} from '@/data/wordlist'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

export default {
  name: 'register',
  data: function() {
    return {
      email: '',
      password: '',
      name: '',
      householdName:''
    };
  },
  created(){
    this.generateHouseholdName()
  },
  methods: {
    generateHouseholdName(){
      // e.preventDefault()
      console.log('Generate household name')
      var placeType = placeTypes[Math.floor(Math.random()*placeTypes.length)];
      var placeAdj = placeAdjectives[Math.floor(Math.random()*placeAdjectives.length)];
      var placeName = placeNames[Math.floor(Math.random()*placeNames.length)];
      
      this.householdName = placeType + " de " +placeAdj + " " + placeName
      // console.log(wordlist.typeOfPlace)
      // console.log(typeOfPlace)

    },
    register(e){
      const auth = getAuth()
      createUserWithEmailAndPassword(auth,this.email, this.password)
      .then(
        userCredential=>{
          sharedlogic.createUserDocs(this.name, this.householdName).then(msg => {
            alert(`Account created for ${userCredential.user.email}`)
            console.log(msg)
            this.$emit('logOn')
            this.$router.push('/');
          }).catch(err=>{
            console.error(err)
            alert('A problem occurred while trying to create your account. Please try again - if the problem persists, contact the administrator.')
          })
        err => {
          alert(err.message);
        }
      })
      e.preventDefault()
    },
    // register: function(e) {
    //   firebase
    //     .auth()
    //     .createUserWithEmailAndPassword(this.email, this.password)
    //     .then(
    //       user => {
    //         sharedlogic.createUserDocs(this.name, this.householdName).then(msg => {
    //           alert(`Account created for ${user.user.email}`)
    //           console.log(msg)
    //           this.$emit('logOn')
    //           this.$router.push('/');
    //         }).catch(err=>{
    //           console.error(err)
    //           alert('A problem occurred while trying to create your account. Please try again - if the problem persists, contact the administrator.')
    //         })
    //       },
    //       err => {
    //         alert(err.message);
    //       }
    //     );
    //   e.preventDefault();
    // }
  }
};
</script>

<style scoped>
  .btnpad{
    margin-top: 20px;
}
</style>