const placeTypes =[
    'Hotel',
    'Restaurant',
    'Café',
    'Cafetaria',
    'Kantine',
    'Eethuis',
    'Grillroom',
    'Snackbar',
    'Pizzeria',
    'Toko',
    'Theehuis',
    'Bistro',
    'Bar',
    'Brasserie',
    'Koffiecorner',
    'Culinaire kelder',
    'Orangerie',
    'Club',
    'Lunchroom',
    'Herberg',
    'Kombuis',
    'Hoeve',
    'IJssalon',
    'Kasteel',
    'Burcht',
    'Mensa',
    'Uitspanning',
    'Zalencentrum',
    'Pleisterplaats',
    'Strandpaviljoen',
    'Beachclub',
    'Skihut'
]

const placeAdjectives=[
    'Kleine',
    'Dronken',
    // 'gele',
    // 'paarse',
    'Boze',
    'Slapende',
    'Aangeschoten',
    'Schreeuwende',
    'Wandelende',
    'Hinkelende',
    'Hollende',
    'Zingende',
    'Gillende',
    'Geeuwende',
    'Afgekeurde',
    'Vergeten',
    'Lelijke',
    'Rustende',
    'Lachende',
    'Valse',
    'Slappe',
    'Koperen',
    'Goede',
    'Gouden',
    'Magere',
    'Dikke',
    'Verdronken',
    'Eenzame',
    'Verlaten',
    'Laatste'
]

const placeNames =[
    'Trompetter',
    'Banaan',
    'Lindeboom',
    'Buurman',
    'Buurvrouw',
    'Hollander',
    'Tandem',
    'Kano',
    'Fles',
    'Fiets',
    'Marionet',
    'Soldaat',
    'Colonel',
    'Prins',
    'Baron',
    'Zuster',
    'Wortel',
    'Aubergine',
    'Pastinaak',
    'Zonnebloem',
    'Jager',
    'Boswachter',
    'Astronaut',
    'Acrobaat',
    'Viool',
    'Ridder',
    'Weg',
    'Verliezer',
    'Ketel',
    'Gazelle',
    'Herder',
    'Bastaard'
]

export {placeTypes}
export {placeAdjectives}
export {placeNames}