<template>
  <div id="app">
    <Navbar 
      :key="$route.fullPath" 
      v-on:logOff="terminateListeners" 
      v-on:save="saveTrigger++" 
      :showSave="showSave"
      :activeHousehold="activeHousehold" 
      :user="user"
      :dataReady="dataReady"
      :online="online"
    />
    <div :class="{container : $route.name != 'Home'}">
      
        <router-view 
          :saveTrigger="saveTrigger"
          :recipes="recipes"
          :noteList="noteList"
          :list="list"
          :categoryMasterData="categoryMasterData"
          :inventory="inventory"
          :inventoryList="inventoryList"
          :user="user"
          :households="households"
          :household="activeHousehold"
          v-on:logOn="initialiseAllListeners"
          v-on:reloadData="reloadData"
          :dataReady="dataReady"
          :invites="invites"
          :allTags="allTags"
          :api="api"
          :recipesLoaded="recipesLoaded"
          :listLoaded="listLoaded"
          :notesLoaded="notesLoaded"
          :productSuggestions="productSuggestions"
          v-on:addProductSuggestion="addProductSuggestion($event)"
          v-on:clearList="clearProductSuggestions()"
        />      
    </div>
  </div>
</template>

<script>
import Navbar from './components/Navbar'
import db from './components/firebaseInit'
// import firebase from 'firebase/compat/app';
import { getAuth } from "firebase/auth";

import sharedlogic from './scripts/sharedlogic'
import { collection, doc, query, onSnapshot , where, orderBy} from "firebase/firestore";
// import sw from '/sw.js'

export default {
  name: 'App',
  
  data(){
    return {
      // localApi : 'http://localhost:8081',
      // productionApi : 'https://nl-supermarket-api.onrender.com',
      api :'https://nl-supermarket-api.onrender.com',
      showSave: false,
      saveTrigger: 0,
      recipes: [],
      //notes:[],
      noteList: {
        id:null,
        notes: []
      },
      list : {
        id : null,
        items : []
      },
      categoryMasterData : [],
      inventory : [],
      inventoryList:{
        id:null,
        inventory:[]
      },
      // user: {
      //   hideDone: null
      // },
      user: null,
      households:[],
      invites:[],
      activeHousehold: null,
      unsubscribeUser : null,
      unsubscribeRecipes : null,
      unsubscribeNotes : null,
      unsubscribeList : null,
      unsubscribeCategoryMasterData : null,
      // unsubscribeInventory : null,
      unsubscribeInventoryList:null,
      unsubscribeHouseholds:null,
      unsubscribeHouseholdInvites:null,
      dataReady : false,
      online: true,
      recipesLoaded : false,
      notesLoaded : false,
      listLoaded: false,
      productSuggestions:[]
    }
  }, 
  components: {
    Navbar
  },
  created(){
    if(getAuth().currentUser){
    // if (firebase.auth().currentUser){
      this.initialiseAllListeners()
    }

    navigator.serviceWorker.register("/sw.js")

    navigator.serviceWorker.addEventListener('message', (event) => {
      this.online = event.data.onlineStatus
    });
  },
  watch: {
    '$route':'showSaveLogic'
  },
  computed : {
    allTags(){
          function dedup(arr) {
            return arr.filter((item, index) => arr.indexOf(item) === index);
          }
          var recipeTags = [].concat(...this.recipes.map(recipe => recipe.tags)).filter(el=>el)
          
          var userTags = []
          if (this.user){
            if (this.user.tagSetup){
               [].concat(...this.user.tagSetup.map(tagSetup => tagSetup.tags)).filter(el=>el)
            }
          }
          console.log('USER TAGS', userTags)
          return dedup([].concat(recipeTags,userTags))
    }
  },
  methods:{
    reloadData(){
      //Used when a household is changed in settings.
      console.log('App.vue - reload data')
      console.log('dataReady = false')
      this.dataReady=false
      this.terminateListeners()
      this.initialiseAllListeners()
    },
    terminateListeners(){
      console.log('terminateListeners')
      this.unsubscribeUser()
      this.unsubscribeRecipes()
      this.unsubscribeNotes()
      this.unsubscribeList()
      this.unsubscribeCategoryMasterData()
      // this.unsubscribeInventory()
      this.unsubscribeInventoryList()
      this.unsubscribeHouseholds()
      this.unsubscribeHouseholdInvites()
    },
    
    initialiseUserListener(){
      console.log('Initializing user listener.')
      // var firebaseUser = firebase.auth().currentUser
      var firebaseUser = getAuth().currentUser
      this.user = null

      return new Promise((resolve,reject) => {
        this.unsubscribeUser =  onSnapshot(doc(db,'Users',firebaseUser.uid),
            user =>{
              console.log(user)
              this.user = user.data()
              this.user.id=user.id
              console.log('Found user document for userID : ' + this.user.id)
              resolve('Found the user document.')
          },
          error =>{
            console.error('Ran into a problem while trying to get the user document : ' + error)
            reject(error);
          }
        )
      })
      
      // return new Promise((resolve,reject) => {
      //   this.unsubscribeUser = db.collection('Users').doc(firebaseUser.uid).onSnapshot(user => {
      //       console.log(user)
      //       this.user = user.data()
      //       this.user.id=user.id
      //       console.log('Found user document for userID : ' + this.user.id)
      //       resolve('Found the user document.')
      //   },
      //   error =>{
      //     console.error('Ran into a problem while trying to get the user document : ' + error)
      //     reject(error);
      //   })
      // })
    },
    initialiseRecipeListener(){
      console.log('Initializing recipes listener.')



      const q = query(collection(db,'Recipes'), where('household', '==',this.user.household), orderBy('recipeTitle'))
      return onSnapshot(q,
          querySnapshot =>{
          console.log('qs recipes')
          this.recipes = []
          querySnapshot.forEach(doc=>{
              var recipe = doc.data()
              recipe.id = doc.id
              
              recipe.avgTimeBetweenEatDates = Math.round(sharedlogic.avgTimeBetweenEatDates(recipe))
              recipe.eatCount = recipe.eatDate ? recipe.eatDate.length : 0
              recipe.timeSinceLastEatDate = sharedlogic.timeSinceLastEatDate(recipe)
              recipe.dueToEatFactor = recipe.avgTimeBetweenEatDates == 0 ? 0 : Math.round(recipe.timeSinceLastEatDate / recipe.avgTimeBetweenEatDates*10)/10
              this.recipes.push(recipe)
          }) 
        this.recipesLoaded = true
        },
        error =>{
          console.error('Failed to get recipes with error : ' + error)       
        }
      )

      // return db.collection('Recipes')
      // .where('household','==',this.user.household)
      // .orderBy('recipeTitle')
      // .onSnapshot((querySnapshot) =>{
      //     this.recipes = []
      //     querySnapshot.forEach(doc=>{
      //         var recipe = doc.data()
      //         recipe.id = doc.id
              
      //         recipe.avgTimeBetweenEatDates = Math.round(sharedlogic.avgTimeBetweenEatDates(recipe))
      //         recipe.eatCount = recipe.eatDate ? recipe.eatDate.length : 0
      //         recipe.timeSinceLastEatDate = sharedlogic.timeSinceLastEatDate(recipe)
      //         recipe.dueToEatFactor = recipe.avgTimeBetweenEatDates == 0 ? 0 : Math.round(recipe.timeSinceLastEatDate / recipe.avgTimeBetweenEatDates*10)/10
      //         this.recipes.push(recipe)
      //     })  
      // },
      // error => {
      //   console.error('Failed to get recipes with error : ' + error)       
      // })
    },
    initialiseNotesListener(){
      console.log('Initializing notes listener.')

      const q = query(collection(db,'Notes'), where('household', '==',this.user.household))
      return onSnapshot(q,
          querySnapshot =>{

            this.noteList = {
              id : null,
              notes: []
            }
            querySnapshot.forEach(doc=>{
              this.noteList.notes = doc.data().notes
              this.noteList.id = doc.id
            })        
            this.notesLoaded=true
        },
        error =>{
          console.error('Encountered a problem while initializing the notes listener. Error code : ' + error.code)
        }
      )

      // return db.collection('Notes')
      // .where('household','==',this.user.household)
      // .onSnapshot((querySnapshot)=>{
      //   this.noteList = {
      //     id : null,
      //     notes: []
      //   }
      //   querySnapshot.forEach(doc=>{
      //     this.noteList.notes = doc.data().notes
      //     this.noteList.id = doc.id
      //   })

      // },
      // error =>{
      //   console.error('Encountered a problem while initializing the notes listener. Error code : ' + error.code)
      // })
      
    },
    initialiseListListener(){
      console.log('Initializing List listener.')
      const q = query(collection(db,'List'), where('active', '==',true), where('household', '==',this.user.household), orderBy('createdDate','desc'))
      return onSnapshot(q,
          querySnapshot =>{

            console.log('ll qs', querySnapshot)

            this.list={
              id : null,
              items : []
            }
            //console.log(querySnapshot)
            var i = 0
            querySnapshot.forEach(doc =>{
                if (i==0){
                  this.list = doc.data()
                  this.list.id = doc.id
                }
                i++
            })

            if (i>1){
              console.error('ERROR - More than 1 active list found.')
              //TODO: Ideally, we put some logic in here to fix this problem. But make sure no infinite loops can be created!!!
            }

            if (this.list.id){
              console.log('Loaded existing list with id: ' + this.list.id)
            } 
            
            this.listLoaded = true
            
        },
        error =>{
          console.error('Encountered a problem while initializing the list listener. Error code : ' + error.code)
        }
      )
        
        
        // return db.collection('List')
        // .where('active', '==',true)
        // .where('household', '==',this.user.household)
        // .orderBy('createdDate','desc').onSnapshot(querySnapshot =>{
        //   this.list={
        //     id : null,
        //     items : []
        //   }
        //   //console.log(querySnapshot)
        //   var i = 0
        //   querySnapshot.forEach(doc =>{
        //       if (i==0){
        //         this.list = doc.data()
        //         this.list.id = doc.id
        //       }
        //       i++
        //   })

        //   if (i>1){
        //     console.error('ERROR - More than 1 active list found.')
        //     //TODO: Ideally, we put some logic in here to fix this problem. But make sure no infinite loops can be created!!!
        //   }

        //   if (this.list.id){
        //     console.log('Loaded existing list with id: ' + this.list.id)
        //   }          
          
        // },
        // error =>{
        //   console.error('Encountered a problem while initializing the list listener. Error code : ' + error.code)
        // })
    },
    // initialiseInventoryListener(){
    //   console.log('Initializing inventory listener.')
    //   return db.collection('Inventory')
    //   .where('household','==',this.user.household)
    //   .orderBy('frequency')
    //   .orderBy('category.supermarketOrder')
    //   .onSnapshot((querySnapshot)=>{
    //     this.inventory = []
    //     var inv
    //     querySnapshot.forEach(doc=>{
    //       inv = doc.data()
    //       inv.id = doc.id
    //       inv.renderId = doc.id
    //       this.inventory.push(inv)
    //     })
    //   },
    //   error =>{
    //     console.error('Encountered a problem while initializing the inventory listener. Error code : ' + error.code)
    //   })
    // },
    initialiseInventoryListListener(){
      console.log('Initializing inventoryList listener.')

      const q = query(collection(db,'InventoryList'), where('household', '==',this.user.household))
      return onSnapshot(q,
          querySnapshot =>{
            this.inventoryList = {
              id : null,
              inventory: []
            }
            querySnapshot.forEach(doc=>{
              this.inventoryList = doc.data()
              this.inventoryList.id = doc.id
            })       
        },
        error =>{
          console.error('Encountered a problem while initializing the inventoryList listener. Error code : ' + error.code)
        }
      )


      // return db.collection('InventoryList')
      // .where('household','==',this.user.household)
      // // .orderBy('frequency')
      // // .orderBy('category.supermarketOrder')
      // .onSnapshot((querySnapshot)=>{
      //   this.inventoryList = {
      //     id : null,
      //     inventory: []
      //   }
      //   querySnapshot.forEach(doc=>{
      //     this.inventoryList = doc.data()
      //     this.inventoryList.id = doc.id
      //   })
      // },
      // error =>{
      //   console.error('Encountered a problem while initializing the inventoryList listener. Error code : ' + error.code)
      // })
    },    
    initialiseHouseholdListener(){
      console.log('Initializing household listener.')

      const q = query(collection(db,'Household'), where('members', 'array-contains',this.user.id))

      return new Promise((resolve,reject)=>{

        this.unsubscribeHouseholds =  onSnapshot(q,
          querySnapshot=>{
            this.households=[]
            this.activeHousehold=null
            querySnapshot.forEach(doc=>{
                let household =doc.data()
                household.id=doc.id
                household.renderId=doc.id
                // household.renderId=Math.round(Math.random()*100000)
                if (this.user.household == household.id){
                  this.activeHousehold = household
                  household.active=true
                }
                this.households.push(household)

            })
            this.households.sort((a,b)=>{
                var c = b.id===this.user.household ? 1 : 0 
                var d = a.id===this.user.household ? 1 : 0
                return c-d
            })
            if(!this.activeHousehold){
              alert("Je bent geen deelnemer meer in het door jou geselecteerde huis. Ga naar Instellingen > Huis om een ander huis te activeren.")
            }
            resolve("Found households")
          },
          error =>{
            console.error('Ran into a problem while trying to get the households : ' + error)
            reject("Problem finding households.")
          }
        )
      })

      // return new Promise((resolve,reject)=>{

      //   this.unsubscribeHouseholds = db.collection('Household')
      //     .where('members', 'array-contains',this.user.id)
      //     .onSnapshot(qS=>{
      //       this.households=[]
      //       this.activeHousehold=null
      //       qS.forEach(doc=>{
      //           let household =doc.data()
      //           household.id=doc.id
      //           household.renderId=doc.id
      //           // household.renderId=Math.round(Math.random()*100000)
      //           if (this.user.household == household.id){
      //             this.activeHousehold = household
      //             household.active=true
      //           }
      //           this.households.push(household)

      //       })
      //       this.households.sort((a,b)=>{
      //           var c = b.id===this.user.household ? 1 : 0 
      //           var d = a.id===this.user.household ? 1 : 0
      //           return c-d
      //       })
      //       if(!this.activeHousehold){
      //         alert("Je bent geen deelnemer meer in het door jou geselecteerde huis. Ga naar Instellingen > Huis om een ander huis te activeren.")
      //       }
      //       resolve("Found households")
      //     },
      //     error =>{
      //       console.error('Ran into a problem while trying to get the households : ' + error)
      //       reject("Problem finding households.")
      //     })
      // })
    },
    initialiseInvitesListener(){
      console.log('Initializing invite listener.')
      console.log(this.user.email)
      // this.dataReady=false

      const q = query(collection(db,'Household'), where('invites','array-contains',this.user.email.toLowerCase()))

      return new Promise((resolve,reject)=>{
        this.unsubscribeHouseholdInvites = onSnapshot(q,
          querySnapshot =>{
            this.invites=[]
            querySnapshot.forEach(doc=>{
              let invite = doc.data()
              invite.id = doc.id
              invite.renderId="I_"+doc.id
              invite.invite = true
              this.invites.push(invite)
            })
            resolve('Invites found')      
          },
          error =>{
            console.error('Ran into a prolem while fetch househould invites : ' + error)
            reject('Failed to fetch household invites.')
          }
        )
      })
      
      
      
      // return new Promise((resolve,reject)=>{
      //   this.unsubscribeHouseholdInvites = db.collection('Household')
      //   .where('invites','array-contains',this.user.email.toLowerCase())
      //   .onSnapshot(qS=>{
      //     this.invites=[]
      //     qS.forEach(doc=>{
      //       let invite = doc.data()
      //       invite.id = doc.id
      //       invite.renderId="I_"+doc.id
      //       invite.invite = true
      //       this.invites.push(invite)
      //     })
      //     resolve('Invites found')
      //   },
      //   error=>{
      //     console.error('Ran into a prolem while fetch househould invites : ' + error)
      //     reject('Failed to fetch household invites.')
      //   })
      // })
    },
    initialiseCategoryMasterDataListener(){
      console.log('Initialising category master data listener')

      const q = query(collection(db,'CategoryMasterData'))

      return onSnapshot(q, 
        querySnapshot=>{
          this.categoryMasterData = []
          var catMd
          querySnapshot.forEach(doc=>{
            catMd = doc.data()
            catMd.id = doc.id
            this.categoryMasterData.push(catMd)
          })
        },
        error =>{
          console.error('Encountered a problem while initializing the category master data listener. Error code : ' + error.code)
        })
    },
    initialiseAllListeners(){
      var userProm = this.initialiseUserListener()

      userProm.then(() =>{
        console.log('Initializing listeners with user ID: ' + this.user.id + '. Household id : ' + this.user.household)
        this.unsubscribeRecipes = this.initialiseRecipeListener()
        


        this.unsubscribeNotes = this.initialiseNotesListener()
        this.unsubscribeList = this.initialiseListListener()
        // this.unsubscribeInventory = this.initialiseInventoryListener()
        this.unsubscribeInventoryList = this.initialiseInventoryListListener()
        // this.unsubscribeHouseholds=this.initialiseHouseholdListener()

        var hhProm = this.initialiseHouseholdListener()
        hhProm.then(()=>{
          console.log('dataready = true')
          // this.dataReady = true
        })
        var inviteProm = this.initialiseInvitesListener()
        inviteProm.then(()=>{
          console.log('Invites initialised')
        })
        Promise.all([hhProm,inviteProm]).then(()=>{
          this.dataReady = true
        })
      })
      .catch(error=>{
        console.error('Encountered a problem while initiliazing the user dependent listeners. Error : ' + error)
      })
      
      //The category master data listener does not depend on the user (for now)
      console.log('Initializing category master data listener.')
      this.unsubscribeCategoryMasterData = this.initialiseCategoryMasterDataListener()
      
      
      // db.collection('CategoryMasterData')//.where('supermarketOrder','>',0)
      // .onSnapshot((querySnapshot)=>{
      //   this.categoryMasterData = []
      //   var catMd
      //   querySnapshot.forEach(doc=>{
      //     catMd = doc.data()
      //     catMd.id = doc.id
      //     this.categoryMasterData.push(catMd)
      //   })
      // },
      // error =>{
      //   console.error('Encountered a problem while initializing the category master data listener. Error code : ' + error.code)
      // })

      
    },
    addProductSuggestion(pS){
      // if (!this.productSuggestions.find(existingSuggestions=>existingSuggestions.itemName==pS.itemName)){
        this.productSuggestions.push(pS)
      // }
    },
    clearProductSuggestions(){
      this.productSuggestions=[]
    },
    showSaveLogic(){
      console.log('App - route watcher')
      if (
        this.$route.name == 'edit-recipe'
        || this.$route.name == 'profile'
      ){
        this.showSave = true
      }
      else {
        this.showSave = false
      }
    }
  }
}


</script>


<style >

/* Primary: rgb(35,168,112)
Lighter: rgb(133,195,164)
Darker: rgb(19,96,57) */


/* 
 .btn-floating.btn-large.waves-ripple {
    background-color: rgb(35,168,112)
 }

 .btn.waves-effect.waves-ripple {
    background-color: rgb(35,168,112)
 }

 */
  .btn.waves-effect.waves-ripple.gromstyle-light {
    background-color: rgb(133,195,164);
 }


 .btn-floating.halfway-fab.gromstyle-light{
    background-color: rgb(133,195,164);
 }


 /*
 
*/
 .chip.gromstyle-bg-light{
    background-color: rgb(133,195,164);
 }

  .chip.gromstyle-bg{
    background-color: rgb(35,168,112);
 }


.gromstyle{
    color: rgb(35,168,112);
}
.gromstyle-light{
    color: rgb(133,195,164);
}
.gromstyle-dark{
    color: rgb(19,96,57);
}

/*
.secondary-content.gromstyle {
  color: rgb(35,168,112);
}

.gromstyle-bg{
    background-color: rgb(35,168,112);
}
.gromstyle-bg-light{
    background-color: rgb(133,195,164);
}

.gromstyle-bg-dark{
    background-color: rgb(19,96,57);
}

*/
.new.badge.gromstyle{
  background-color: rgb(35,168,112);
}
.new.badge.gromstyle-light{
    background-color: rgb(133,195,164);
}
.new.badge.gromstyle-dark{
    background-color: rgb(19,96,57);
}
/*

.card-panel.gromstyle-light{
    background-color: rgb(133,195,164);
} */





/* Datepicker 
.datepicker-date-display {
    background-color:  rgb(35,168,112)
}

.datepicker-day-button:focus {
    color: white !important;
    background-color:rgb(19,96,57)
}

.datepicker-done,
.datepicker-cancel,
.select-dropdown li>span,
.is-today {
    color: rgb(19,96,57) !important
}

td.is-selected,
.month-prev:active,
.month-prev:focus,
.month-next:active,
.month-next:focus {
    background-color: rgb(19,96,57)!important
}

td.is-selected.is-today {
    color: white !important
}
/* End of date picker */

/* label focus color 
 .input-field input:focus + label {
   color: rgb(35,168,112) !important;
 }
 /* label underline focus color 
 .row .input-field input:focus {
   border-bottom: 1px solid rgb(35,168,112) !important;
   box-shadow: 0 1px 0 0 rgb(35,168,112) !important
 }

 .input-field .prefix.active {
    color: rgb(35,168,112)
}
*/
.unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.collection-light {
    padding-left:0px !important; 
    padding-right:0px !important; 
    margin-bottom:0px;
}

.collection-light-ul{
    border: 0px !important;
    margin-bottom:0px !important;
}

</style>

