<template>
    <div id ="modalCalendar" class="modal modal-fixed-footer">
        <div class="modal-content">
            <h4>Ingrediënten toevoegen
                <i 
                    id="tooltipTitle"
                    class="material-icons tooltipped gromstyle-light" 
                    data-position="bottom" 
                    data-tooltip='Selecteer hier de dagen waarvoor je boodschappen wilt doen. De ingrediënten voor de recepten in je maaltijdplan worden dan toegevoegd aan je boodschappenlijst.'
                >
                    info_outline
                </i>        
            </h4>
            
            <button v-on:click="loadMore(7,true)" class="btn waves-effect waves-ripple gromstyle-light">7 dagen terug...</button>    
            <br><br>

            <table >
                <tbody >        
                    <tr v-for="day in calendar" v-bind:key="day.index"  v-bind:class="{ 'highlighted': day.order, 'pointer' : true}"
                         v-on:click="day.order=!day.order
                            status.statusCode='OK'
                            " >

                        <td width="25%"> 
                            <strong>
                                {{day.dayOfWeekString}}
                            </strong>
                            <span v-if ="day.label" :class="['new badge', day.label.color]" data-badge-caption="">{{day.label.msg}}</span>
                        </td>                            
                        <td>
                            <div v-if ="day.recipesToday || day.notesToday">
                                <div v-for="recipe in day.recipesToday" v-bind:key="recipe.id">
                                    
                                        <i class = "material-icons " >local_dining</i> 
                                        {{recipe.recipeTitle}}
                                    
                                </div>
                                <div v-for="note in day.notesToday" v-bind:key="note">
                                    
                                        <i class = "material-icons " >chat_bubble_outline</i> 
                                        {{note}} 
                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                
            </table> 
            <br>         
            <button v-on:click="loadMore(7,false)" class="btn waves-effect waves-ripple light">Volgende 7 dagen...</button>    
        </div>

        <div class="modal-footer">
            <a href="#!" class="waves-effect  btn-flat right" v-on:click="addToList">
                Voeg toe aan lijst</a>
        </div>
        
    </div>
</template>

<script>
// import db from './firebaseInit'
import sharedlogic from '@/scripts/sharedlogic'
import M from '@materializecss/materialize/dist/js/materialize.min.js'

export default {
    name: 'modalCalendar',
    props:['list','modal','recipes','noteList'],
    data(){
        return{
            //recipes: [],
            calendar: [],
            //notes: [],
            specialDates: [],
            status: {statusCode : 'OK' , message : '', problemIngredients: []},
        }
    },
    mounted(){
        console.log('Modal calendar - Mounted')
        // var elem = document.querySelectorAll('.collapsible.expandable');
        //var col = 
        // M.Collapsible.init(elem, {
        //     accordion: false,
        //     inDuration: 0,
        //     outDuration: 0
        // });
        //console.log(col)
        // col.open(1)
    },
    created(){
        console.log('Modal calendar - Created')

        var today = new Date();
        //today = new Date(today.valueOf() -1000*60*60*24*2)
        var numberOfDays = 14

        this.calendarAddDaysProm(today,numberOfDays).then(msg =>{
            console.log(msg)
            this.addContentToCalendar(numberOfDays)
        })
    },
    watch: {
        'recipes':'addContentToCalendar',
        'noteList':'addContentToCalendar'
    },
    methods : {
        addToList(){
            var noIngr = 0
            var noDays = 0
            var noRec = 0
            this.calendar.forEach(day =>{
                if(day.order && day.recipesToday){
                    day.recipesToday.forEach(recipe=>{
                        recipe.ingredients.forEach(ingredient=>{
                            var ingredientToAdd= {
                                recipeTitle : recipe.recipeTitle,
                                origin : {
                                    text: recipe.recipeTitle,
                                    type: 2, //0 = ad-hoc, //1 = inventory items, //2 = planned recipes
                                    recipeTitle: recipe.recipeTitle,
                                    datePretty: day.datePretty
                                },
                                renderId : sharedlogic.uid(this.list.items.map(item => item.renderId)),
                                name : ingredient.ingredientName,
                                category : ingredient.category,
                                dateAdded : new Date()
                                //quantity: ingredient.quantity
                            }
                            if (ingredient.quantity){
                                ingredientToAdd.quantity= ingredient.quantity
                            }

                            this.list.items.push(ingredientToAdd)
                            noIngr++
                        })
                        noRec++
                    })
                    noDays++
                }
            })

            if (noDays > 0){
                this.modal.close()
                M.toast({text: noIngr + ' ingredienten voor ' +noRec+ ' recepten op ' +noDays+ ' dagen toegevoegd.'})
                this.$emit('saveList')
            } else {
                //TODO: error to define here
                //this.status= {statusCode : 'NOTOK' , message : 'Selecteer ten minste één dag.', problemIngredients: []}
                var toastHtml = '<i class ="fa fa-warning red-text"></i> Selecteer ten minste één dag met een recept.'
                M.toast({unsafeHTML: toastHtml })
            }
                        
            
        },
        loadMore(numberOfDays,before){
            //Before = true --> days are loaded before current range
            //Before = false --> days are loaded after current range

            console.log('load more')
            var len = this.calendar.length            
            
            var startDay
            if (before){
                startDay = this.calendar[0].date
                startDay = new Date(startDay.valueOf()+1000*60*60*24*-numberOfDays)
            } else{
                startDay = this.calendar.slice(len-1)[0].date
                startDay = new Date(startDay.valueOf()+1000*60*60*24*1)
            }
            
            this.calendarAddDaysProm(startDay,numberOfDays).then(msg =>{
                console.log(msg)
                this.addContentToCalendar(numberOfDays)
            }).catch(error => console.log(error))
        },
        specialDateLabel(date){
            
            //Today
            var today = new Date();  
            //today = new Date(today.valueOf() -1000*60*60*24*1)
            if (this.dateUgly(date) == this.dateUgly(today)){
                return {
                    msg: "Vandaag",
                    color: "gromstyle-dark"
                }
            }
            
            var daysTillWeekend = 6 - today.getDay()
            var saturday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend))
            var sunday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+1))

            if (this.dateUgly(date) == this.dateUgly(saturday) || this.dateUgly(date) == this.dateUgly(sunday)){
                return {
                    msg: "Weekend",
                    color: "gromstyle"
                }
            }

            var nextSaturday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+7))
            var nextSunday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+8))

            if (this.dateUgly(date) == this.dateUgly(nextSaturday) || this.dateUgly(date) == this.dateUgly(nextSunday)){
                return {
                    msg: "Weekend",
                    color: "gromstyle-light"
                }
            }

        },
        dateUgly(date){
            //Based on a proper date format, this function returns the ugly date format YYYYMMDD
            
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();
            
            return yyyy + mm + dd;
        },
        addContentToCalendar(numberOfDays){
            //Loop through days and recipes to see which recipes match a certain day.
            var len = this.calendar.length
            console.log("Add content to calendar")
            //console.log(len)
            //console.log(numberOfDays)
            this.calendar.slice(len-numberOfDays,len).forEach(day =>{
                delete day.recipesToday
                // console.log(day.dateUgly)
                this.recipes.forEach(recipe=>{
                    if(recipe.eatDate){
                        if (recipe.eatDate.includes(day.dateUgly)){     
                            // console.log(recipe)               
                            if (day.recipesToday ==null){
                                day.recipesToday = []
                            }
                            day.recipesToday.push(recipe)
                            day.index += 1
                        }
                    }
                })
                //Notes
                this.noteList.notes.forEach(note=>{
                    if (note.date == day.dateUgly){                    
                        if (day.notesToday ==null){
                            day.notesToday = []
                        }
                        day.notesToday.push(note.note)
                        day.index += 1
                    }
                })

                day.label = this.specialDateLabel(day.date)
                //day.order = false
            })
            
            this.calendar.sort((a,b)=>{
                if(a.dateUgly == b.dateUgly){
                    return 0
                }
                
                if(a.dateUgly > b.dateUgly){
                    return 1
                } else {
                    return -1
                }
            })
        },     
       
        calendarAddDaysProm(startDate, numberOfDays){
            
            return new Promise ((resolve,reject) =>{
                try {
                    //Adds numberOfDays to 
                    var i;
                    
                    for (i = 0; i < numberOfDays; i++) {
                        
                        var date = new Date();  
                        
                        date = new Date(startDate.valueOf()+1000*60*60*24*i)

                        var dd = String(date.getDate()).padStart(2, '0');
                        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                        var yyyy = date.getFullYear();
                        
                        var dayOfWeekInt = date.getDay()
                        var dayOfWeekString = sharedlogic.dayOfWeekString(dayOfWeekInt)
                        
                        //var dateUgly = yyyy + mm + dd;
                        var dateUgly = this.dateUgly(date)
                        var datePretty = dayOfWeekString + ' ' + dd + '/' + mm  + '/' + yyyy;
                        var index = date.valueOf()
                        var order = false
                        this.calendar.push({
                            index,
                            dateUgly,
                            datePretty,
                            date,
                            dayOfWeekString,
                            order
                        })
                        
                    }
                    resolve("Succesfully added " + numberOfDays + " days to calendar. Starting from " + startDate)
                }
                catch (error) {
                    reject(error)
                }
            })
        }
    }

}
</script>

<style scoped>
/*
.new-plate-icon {
    background: url('new-plate-small40-26.png');
    height: 26px;
    width: 40px;
    display: block;
  
}
*/
.fa{
    padding-left: 6px;
    line-height: inherit;
    cursor: pointer;
}
.pointer{
    cursor: pointer;
}

.material-icons{
    padding-right: 6px;
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
}
.highlighted{
    background-color: #e0f2f1;
}

</style>
