<template>
<!-- Admin feature to moderate the ingredient list for auto complete -->
    <div id ="IngredientList">
        <h5>Ingrediëntenlijst</h5>
        
        <button v-on:click="loadIngredients()" class="btn waves-effect waves-ripple">
            <!-- <i class="material-icons">refresh</i> -->
            <span>Refresh</span>
        </button> 

        <button v-on:click="createAutoCompleteObject()" class="btn waves-effect waves-ripple">
            <!-- <i class="material-icons">refresh</i> -->
            <span>Console log AutoComplete object</span>
        </button>    
        
        <div class="row">
            <span>
                {{ingredientMasterDataFiltered.length}} / {{ingredientMasterData.length}}
            </span>
            
            <span class="right">
                <a v-if="!hideDefined" v-on:click="changeVisibility" class="waves-effect  btn-flat "><i class="material-icons">visibility</i></a>
                <a v-if="hideDefined" v-on:click="changeVisibility" class="waves-effect  btn-flat "><i class="material-icons">visibility_off</i></a>
            </span>
        </div>           

        <ul class="collection">
            <li class="collection-item"
                v-for="ingr in ingredientMasterDataFiltered" v-bind:key="ingr.id">
                
                    <label>
                        <input 
                            :id="'ic'+ingr.id" 
                            type="checkbox" 
                            v-model="ingr.autoComplete"
                            v-on:change="saveACState(ingr)"
                            />
                        <span>{{ingr.ingredientName}}</span>
                    </label>
                
                
                
                <a class="pointer gromstyle">
                    <i class ="material-icons right pointer" v-on:click="deleteIMDFromDb(ingr)">delete</i>
                </a>
                

                <!-- <a class="pointer gromstyle">
                    <i class ="material-icons right pointer" v-on:click="deleteIMDFromDb(ingr)">block</i>
                </a>
                <a class="pointer gromstyle">
                    <i class ="material-icons right pointer" v-on:click="promoteItemToAC(ingr)">cloud_upload</i>
                </a> -->

            </li>
        </ul>

    </div>
</template>

<script>
import db from './firebaseInit'
import {collection, query, orderBy, getDocs, deleteDoc, doc, updateDoc} from 'firebase/firestore'

export default {
    name: 'IngredientList',
    data(){
        return {
            ingredientMasterData : [],
            ingredientAC : {},
            hideDefined : false
        }
    },
    mounted(){
        
    },
    computed:{
        ingredientMasterDataFiltered(){
            if (this.hideDefined){
                return this.ingredientMasterData.filter(ingr=>ingr.autoCompleteUndef)
            } else {
                return this.ingredientMasterData
            }   
        }
    },
    methods:{
        createAutoCompleteObject(){
            this.ingredientMasterData.forEach(ingr =>{
                if (ingr.autoComplete){
                    this.ingredientAC[ingr.ingredientName] = null
                }
                
            })

            console.log("AutoComplete:",this.ingredientAC)


        },
        changeVisibility(){
            this.hideDefined = !this.hideDefined
        },
        loadIngredients(){
            this.ingredientMasterData = []
            const q = query(collection(db,"IngredientMasterData"), orderBy('ingredientName'))
            getDocs(q).then(qs=>{
                qs.forEach(doc=>{
                    var ingr = doc.data()
                    ingr.id = doc.id
                    if (ingr.autoComplete == undefined){
                        ingr.autoCompleteUndef = true
                    }
                    this.ingredientMasterData.push(ingr)
                    this.$nextTick(function () {
                        if (ingr.autoComplete == undefined){
                            ingr.autoCompleteUndef = true
                            document.getElementById('ic'+ingr.id).indeterminate = true;
                        }
                        
                    });
                    
                })
                console.log(this.ingredientMasterData.filter(ingr=>ingr.autoComplete == undefined))
            })

            // db.collection('IngredientMasterData').orderBy('ingredientName').get().then(qs=>{
            //     qs.forEach(doc=>{
            //         var ingr = doc.data()
            //         ingr.id = doc.id
            //         if (ingr.autoComplete == undefined){
            //             ingr.autoCompleteUndef = true
            //         }
            //         this.ingredientMasterData.push(ingr)
            //         this.$nextTick(function () {
            //             if (ingr.autoComplete == undefined){
            //                 ingr.autoCompleteUndef = true
            //                 document.getElementById('ic'+ingr.id).indeterminate = true;
            //             }
                        
            //         });
                    
            //     })
            //     console.log(this.ingredientMasterData.filter(ingr=>ingr.autoComplete == undefined))
            // })

            
            
        },
        deleteIMDFromDb(ingr){
            var indexToRemove = this.ingredientMasterData.indexOf(ingr)                     
            this.ingredientMasterData.splice(indexToRemove,1)
            // db.collection('IngredientMasterData').doc(ingr.id).delete()
            const docRef = doc(db, "IngredientMasterData", ingr.id)
            deleteDoc(docRef)
        },
        saveACState(ingr){
            // db.collection('IngredientMasterData').doc(ingr.id).update({autoComplete : ingr.autoComplete})
            const docRef = doc(db, "IngredientMasterData", ingr.id)
            updateDoc(docRef,{autoComplete : ingr.autoComplete})
        }

    }

}
</script>

<style scoped>
.material-icons{
    padding-right: 20px
}
.pointer{
    cursor: pointer;
}
</style>