<template>
    <div id ="calendar">
        <h4>Maaltijdplanner
            <i 
                id="tooltipTitle"
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip='Hier kun je maaltijden inplannen om te eten. Vervolgens voeg je de ingrediënten gemakkelijk toe aan je lijstje. Ook kun je hier notities toevoegen.'
            >info_outline
            </i>
        </h4>

        <div class="row">
            <div class="col s12 l6 ">
                <div class="center-align">
                    <button v-on:click="loadMore(1,true)" class="btn waves-effect waves-ripple gromstyle-light">
                        <i class = "material-icons">keyboard_arrow_up</i> 
                        <!-- Eén dag terug... -->
                    </button>    
                </div>
            </div>
        </div>

        <div v-for="day in calendar" v-bind:key="day.index">
            <div
                :id="'day'+day.index" 
                class="row">
                <div class="col s12 l6">
                                            <!-- class='drop-zone'
                        @drop="onDrop($event)"   
                        @dragover.prevent
                        @dragenter.prevent   -->
                    <DayCard 
                      
                        :day="day"
                        :recipes="recipes"
                        :noteList="noteList"
                        :calendar="calendar"
                        :id="Math.round(Math.random()*100000)"
                        :notesLoaded="notesLoaded"
                        :recipesLoaded="recipesLoaded"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col s12 l6 ">
                <div class="center-align">
                    <button v-on:click="loadMore(1,false)" class="btn waves-effect waves-ripple gromstyle-light">
                        <i class = "material-icons">keyboard_arrow_down</i> 
                    </button>    
                </div>
            </div>
        </div>
        <br>  <br>  <br>  
    </div>
</template>

<script>
// import db from './firebaseInit'
import sharedlogic from '@/scripts/sharedlogic'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import DayCard from './DayCard.vue'

export default {
    name: 'calendar-page',
    props: ['recipes','noteList','notesLoaded','recipesLoaded'],
    data(){
        return{
            //recipes: [],
            calendar: [],
            // notes: [],
            specialDates: []
        }
    },
    components:{
        DayCard
    },
    created(){
        console.log('Meal calendar - Created')
        console.log(this.$route.params.date)
        var today = new Date();


        var dateString  = this.$route.params.date;
        if  (!isNaN(dateString) && !isNaN(parseFloat(dateString) && dateString.length ==8)){

            today = sharedlogic.parseDateStringToDate(dateString);
        }

        var numberOfDays = 21

        this.calendarAddDaysProm(today,numberOfDays).then(msg =>{
            console.log(msg)
            this.addContentToCalendar(numberOfDays)
        })
    },
    mounted(){
        var elemsT = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elemsT, {}); 

        window.addEventListener('scroll', this.shouldLoadMore);
    },
    destroyed(){
        window.removeEventListener('scroll', this.shouldLoadMore)
    },
    computed :{

    },
    watch: {
        'recipes':'addContentToCalendar',
        'noteList':'addContentToCalendar'
    },
    methods : {
        onDrop(event){
            console.log('Dropped : ', event)
        },        
        shouldLoadMore(){
            
            // console.log('scroll Y', window.scrollY)
            // console.log('inner height', window.innerHeight)
            // console.log('document.body.scrollHeight', document.body.scrollHeight)
            
            var YBottom = window.scrollY + window.innerHeight
            console.log('Should load more, space left', document.body.scrollHeight -YBottom)
            if (document.body.scrollHeight -YBottom< 2000){
                this.loadMore(7,false)
            }

            // console.log('scroll Y', window.scrollHeight)
        },
        newRecipe(day){
            //console.log(window)
            console.log(window.pageYOffset)
            //TODO: Store window.pageYOffset so we can return to the same scroll position on calendar after choosing a recipe.
            window.scrollTo(0, 0)
            this.$router.push({ name:'selectRecipeToEatOn', params: { date: day.dateUgly } })
        },
        loadMore(numberOfDays,before){
            //Before = true --> days are loaded before current range
            //Before = false --> days are loaded after current range
            console.log('load more')
            var len = this.calendar.length            
            
            var startDay
            if (before){
                startDay = this.calendar[0].date
                startDay = new Date(startDay.valueOf()+1000*60*60*24*-numberOfDays)
            } else{
                startDay = this.calendar.slice(len-1)[0].date
                startDay = new Date(startDay.valueOf()+1000*60*60*24*1)
            }
            
            this.calendarAddDaysProm(startDay,numberOfDays).then(msg =>{
                console.log(msg)
                this.addContentToCalendar(numberOfDays)

                if(!before){
                    // window.scrollTo(0, document.body.scrollHeight);
                }
            }).catch(error => console.log(error))

            
        },
        specialDateLabel(date){
            
            //Today
            var today = new Date();  
            //today = new Date(today.valueOf() -1000*60*60*24*1)
            if (this.dateUgly(date) == this.dateUgly(today)){
                return {
                    msg: "Vandaag",
                    //color: "grey darken-2"
                    color: "gromstyle-dark"
                }
            }
            
            var daysTillWeekend = 6 - today.getDay()
            var saturday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend))
            var sunday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+1))

            if (this.dateUgly(date) == this.dateUgly(saturday) || this.dateUgly(date) == this.dateUgly(sunday)){
                return {
                    msg: "Dit weekend",
                    // color: "teal"
                    color: "gromstyle"
                }
            }

            var nextSaturday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+7))
            var nextSunday = new Date(today.valueOf()+1000*60*60*24*(daysTillWeekend+8))

            if (this.dateUgly(date) == this.dateUgly(nextSaturday) || this.dateUgly(date) == this.dateUgly(nextSunday)){
                return {
                    msg: "Volgend weekend",
                    // color: "teal lighten-3"
                    color: "gromstyle-light"
                }
            }

        },
        dateUgly(date){
            //Based on a proper date format, this function returns the ugly date format YYYYMMDD
            
            var dd = String(date.getDate()).padStart(2, '0');
            var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = date.getFullYear();
            
            return yyyy + mm + dd;
        },
        addContentToCalendar(numberOfDays){
            //Loop through days and recipes to see which recipes match a certain day.
            var len = this.calendar.length
            console.log("Add content to calendar")
            //console.log(len)
            //console.log(numberOfDays)
            

            this.calendar.slice(len-numberOfDays,len).forEach(day =>{
                //console.log(day.dateUgly)
                day.index++
                delete day.recipesToday
                this.recipes.forEach(recipe=>{
                    if(recipe.eatDate){
                        if (recipe.eatDate.includes(day.dateUgly)){                    
                            if (day.recipesToday ==null){
                                day.recipesToday = []
                            }
                            day.recipesToday.push(recipe)
                            day.index += 1

                            //var indexToRemove = recipe.eatDate.indexOf(day.dateUgly)
                            //console.log(recipe.recipeTitle + indexToRemove)
                            //recipe.eatDate.splice(indexToRemove,1)
                            //console.log(recipe.eatDate)
                        }
                    }
                })
                //Notes
                delete day.notesToday
                var i = 0
                this.noteList.notes.forEach(note=>{
                    if (note.date == day.dateUgly){                    
                        if (day.notesToday ==null){
                            day.notesToday = []
                        }
                        note.index=i
                        i++
                        day.notesToday.push(note)
                        day.index += 1
                    }
                })
                day.label = this.specialDateLabel(day.date)

            })
            
            this.calendar.sort((a,b)=>{
                if(a.dateUgly == b.dateUgly){
                    return 0
                }
                
                if(a.dateUgly > b.dateUgly){
                    return 1
                } else {
                    return -1
                }
            })
        },     
       
        calendarAddDaysProm(startDate, numberOfDays){
            
            return new Promise ((resolve,reject) =>{
                try {
                    //Adds numberOfDays to 
                    var i;
                    
                    for (i = 0; i < numberOfDays; i++) {
                        
                        var date = new Date();  
                        
                        date = new Date(startDate.valueOf()+1000*60*60*24*i)

                         var dd = String(date.getDate()).padStart(2, '0');
                         var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
                         var yyyy = date.getFullYear();
                        
                        var dayOfWeekInt = date.getDay()
                        var dayOfWeekString = sharedlogic.dayOfWeekString(dayOfWeekInt)
                        
                        //var dateUgly = yyyy + mm + dd;
                        var dateUgly = this.dateUgly(date)
                        var datePretty = dayOfWeekString + ' ' + dd + '/' + mm  + '/' + yyyy;
                        var index = date.valueOf()
                        this.calendar.push({
                            index,
                            dateUgly,
                            datePretty,
                            date
                        })
                        
                    }
                    resolve("Succesfully added " + numberOfDays + " days to calendar. Starting from " + startDate)
                }
                catch (error) {
                    reject(error)
                }
            })
        }
    }

}
</script>


