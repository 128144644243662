<template>
    <div id="Household" >
        <h4 >Huis 
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip="Je deelt je recepten, kalender en boodschappenlijst met je huisgenoten."
            >info_outline
            </i>
        </h4>  

        <ul 
        id="hh_collapsible" 
            class="collapsible">
            <li  
                v-for="hh in householdsInclInvites" 
                v-bind:key="hh.renderId" 
                >
                <div v-if="dataReady" class="collapsible-header" >
                    <i class="material-icons">restaurant</i>
                    {{hh.name}}
                       
                    <span v-if="hh.active" class="new badge gromstyle" data-badge-caption="Actief"></span>
                    <span v-if="hh.invite" class="new badge gromstyle-light" data-badge-caption="Uitnodiging"></span>

                </div>
                <div class="collapsible-body">
                    <div v-if="dataReady">
                        <div class="right-align">
                            <button 
                                v-if="!hh.active && !hh.invite"
                                v-on:click="activateHousehold(hh)" 
                                class="btn waves-effect waves-ripple first-button">
                                Activeer
                            </button>
                            <button 
                                v-if="hh.invite"
                                v-on:click="acceptInvite(hh)" 
                                class="btn waves-effect waves-ripple first-button">
                                Accepteer uitnodiging
                            </button>
                            <button 
                                v-if="!hh.invite"
                                href="#modalHousehold" 
                                class="btn waves-effect waves-ripple modal-trigger"
                                v-on:click="setModalContent(hh)">
                                <i class="material-icons">edit</i>
                            </button>
                        </div>
                    </div>
                    <ul class="collection with-header">
                    
                        <li class="collection-header">Deelnemers</li>
                        <li v-for="member in hh.membersExtraInfo" v-bind:key="member.id" class="collection-item">
                            <i class="material-icons tiny">person</i> {{member.name}}
                            <div class="light"> {{member.email}}</div>
                            <!-- <span v-if="member.isAdmin" class="new badge gromstyle" data-badge-caption="Admin"></span> -->
                            
                        </li>
                        <li v-for="invite in hh.invites" v-bind:key="invite" class="collection-item">
                            <a 
                                href="#"
                                class="secondary-content gromstyle"
                            >
                                <!-- <i class ="fa fa-trash" v-on:click="removeInvite(invite)"></i> -->
                            </a>
                            <i class="material-icons tiny">person_add</i> 
                            Uitgenodigd
                            <!-- <span  class="new badge gromstyle" data-badge-caption="Uitgenodigd"></span> -->
                            <div class="light"> {{invite}}</div>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>

        <div 
            class="fixed-action-btn btn-floating btn-large waves-ripple modal-trigger"
            href="#modalHousehold"
            v-on:click="setModalContent(null)">
            <!-- <router-link to="/edit-recipe/new" class = "btn-floating btn-large waves-ripple"> -->
                <i class ="fa fa-plus"></i>
            <!-- </router-link> -->
        </div>


        <modalHousehold
            :hh="editHousehold"
            :user="user"
        />
    </div>
</template>

<script>
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import db from './firebaseInit'
import modalHousehold from './modalHousehold.vue'
import {updateDoc, doc} from 'firebase/firestore'

export default {
    name: 'household',
    props: ['user','recipes','households','dataReady','invites'],
    data(){
        return{
            // households :[],
            collapsibleI: null,
            editHousehold:{
                name:null,
                members:[]
            }
        }
    },
    components: {
        modalHousehold
    },
    mounted(){
        console.log('Mounted - household')

        var elems = document.querySelectorAll('.tooltipped');
        console.log(elems)
        M.Tooltip.init(elems, {});   
        
        var elemsM = document.querySelectorAll('.modal');
        M.Modal.init(elemsM, {});
        
        // var elemsTT = document.querySelectorAll('#emailTT');
        // M.Tooltip.init(elemsTT);       
        this.buildCollapsibleHouseholds()
    },
    created(){
        console.log('Household created')
        

    },
    computed:{
        householdsInclInvites(){
            return this.dataReady ? [...this.households,...this.invites] : []
        }
    },
    watch:{
        // 'user' : 'buildCollapsibleHouseholds',
        // 'households' : 'buildCollapsibleHouseholds'
        'dataReady' : 'buildCollapsibleHouseholds'
    },
    methods:{
        acceptInvite(hh){
            console.log('Accept invite')

            this.householdsInclInvites.forEach(hh=>{
                console.log(hh.id)
            })

            var indexToRemove = hh.invites.indexOf(this.user.email.toLowerCase())          
            hh.invites.splice(indexToRemove,1)

            const docRef = doc(db,"Household", hh.id)
            updateDoc(docRef,{
                members: [this.user.id,...hh.members],
                invites: hh.invites,
                membersExtraInfo: [
                    {
                        id: this.user.id,
                        email: this.user.email,
                        name: this.user.name ? this.user.name : ''
                    },
                    ...hh.membersExtraInfo
                ]
            })

            // db.collection('Household').doc(hh.id).update({
            //     members: [this.user.id,...hh.members],
            //     invites: hh.invites,
            //     membersExtraInfo: [
            //         {
            //             id: this.user.id,
            //             email: this.user.email,
            //             name: this.user.name ? this.user.name : ''
            //         },
            //         ...hh.membersExtraInfo
            //     ]
            // })
        },
        setModalContent(hh){
            console.log('setModalContent')
            console.log(hh)
            if (!hh){
                hh = {
                    new : true,
                    members:[this.user.id],
                    invites:[],
                    membersExtraInfo:[{
                        id: this.user.id,
                        name: this.user.name,
                        email: this.user.email
                    }]
                }
            }
            console.log(hh)
            this.editHousehold = JSON.parse(JSON.stringify(hh))

        },
        activateHousehold(hh){

            console.log("Activate household")
            console.log(hh)

            const docRef = doc(db,"Users", this.user.id)
            updateDoc(docRef,{
                household : hh.id
            })


            // db.collection('Users').doc(this.user.id).update({
            //     household : hh.id
            // })
            // this.households=[]
            this.collapsibleI.destroy()
            console.log('Reload data')
            this.$emit('reloadData')
        },
        buildCollapsibleHouseholds(){
            console.log('BUILD COLLAPSIBLE')
            console.log("user" + this.user)
            
            console.log("households"+this.households)
            console.log(this.households)
            console.log(this.user!=null)
            console.log(this.households.length>0)
            console.log(!(this.user!=null && this.households.length>0))
            // if(!(this.user!=null && this.households.length>0)){
            console.log('this.dataReady')
            console.log(this.dataReady)
            if(!this.dataReady){
                console.log('ABORT BUILDING COLLAPSIBLE')
                return //Data not complete yet, so wait for the other listener.
            }
            if(this.collapsibleI){
                this.collapsibleI.destroy()
            }
            var elemsC = this.$el.querySelector('#hh_collapsible');
            // console.log("ElemsC household : ")
            console.log("ElemsC household : ", elemsC)
            this.collapsibleI = M.Collapsible.init(elemsC);
            console.log('this.collapsibleI')
            console.log(this.collapsibleI)
            this.collapsibleI.open(0);
        }
    }
}
</script>
<style scoped>
    .first-button{
        margin-right: 10px;
    }
/* .middle{
    vertical-align: middle;   
     line-height: inherit;
} */
</style>