export {autoCompleteList}

const autoCompleteList = {
    "Aardappel": null,
    "Aardappel kroketjes": null,
    "Aardappelbolletjes": null,
    "Aardappelpuree": null,
    "Aardbeien": null,
    "Abrikozenjam": null,
    "Ahornsiroop": null,
    "Alcoholvrije rode wijn": null,
    "Alcoholvrije wijn": null,
    "Alcoholvrije witte wijn": null,
    "Allspice": null,
    "Amandelen": null,
    "Amandelmeel": null,
    "Amandelschaafsel": null,
    "Amarillo peper": null,
    "Andijvie": null,
    "Ansjovis": null,
    "Appel": null,
    "Appelstroop": null,
    "Arachideolie": null,
    "Arrabiata pesto": null,
    "Artisjokharten": null,
    "Asperges": null,
    "Aubergine": null,
    "Augurk": null,
    "Avocado": null,
    "Azijn": null,
    "Bacon": null,
    "Bakpapier": null,
    "Bakpoeder": null,
    "Balsamico azijn": null,
    "Banaan": null,
    "Basilicum": null,
    "Basmatirijst": null,
    "Belegen kaas": null,
    "Beschuit": null,
    "Biefstuk": null,
    "Biefstuk reepjes": null,
    "Bier": null,
    "Bieslook": null,
    "Bimi": null,
    "Bladerdeeg": null,
    "Bladerdeeg vers": null,
    "Bladpeterselie": null,
    "Blauwe bessen": null,
    "Bleekselderij": null,
    "Bloem": null,
    "Bloemkool": null,
    "Boerenkool": null,
    "Bonito flakes": null,
    "Borlottibonen": null,
    "Bosbessen": null,
    "Bospeen": null,
    "Bosui": null,
    "Bosvruchtenjam": null,
    "Boter": null,
    "Bouillon": null,
    "Bouillonblokjes": null,
    "Broccoli": null,
    "Brood": null,
    "Broodjes": null,
    "Broodkruim": null,
    "Bruin bier": null,
    "Bruine bonen": null,
    "Bruine suiker": null,
    "Bulgur": null,
    "Cacaopoeder": null,
    "Camembert": null,
    "Cantaloupe meloen": null,
    "Cantharellen": null,
    "Cashewnoten": null,
    "Cayennepeper": null,
    "Champignons": null,
    "Cheddar": null,
    "Cherrytomaten": null,
    "Chili vlokken": null,
    "Chilipeper": null,
    "Chilipoeder": null,
    "Chilisaus": null,
    "Chinese kool": null,
    "Chinese roerbakmix": null,
    "Chipolata worstjes": null,
    "Chocola": null,
    "Chocopasta": null,
    "Chorizo": null,
    "Ciabatta": null,
    "Ciderazijn": null,
    "Citroen": null,
    "Citroengras": null,
    "Citroengras / Sereh": null,
    "Citroensap": null,
    "Clementine": null,
    "Cognac": null,
    "Confit de canard": null,
    "Cordon Blue": null,
    "Courgette": null,
    "Courgetti": null,
    "Couscous": null,
    "Cranberry jam": null,
    "Crème fraiche": null,
    "Currypasta": null,
    "Diepvries spinazie": null,
    "Dijonmosterd": null,
    "Donker bier": null,
    "Dorade": null,
    "Dragon": null,
    "Droge rode wijn": null,
    "Droge witte wijn": null,
    "Druiven": null,
    "Duitse biefstuk": null,
    "Eendenborst": null,
    "Ei": null,
    "Erwten": null,
    "Extra vierge olijfolie": null,
    "Fajitakruiden": null,
    "Feta": null,
    "Filodeeg": null,
    "Flespompoen": null,
    "Friet": null,
    "Frituurolie": null,
    "Fruit": null,
    "Garam masala": null,
    "Garnalen": null,
    "Gebakken aardappelen": null,
    "Gebroken lijnzaad": null,
    "Gecondenseerde volle melk": null,
    "Gedroogde cranberries": null,
    "Gedroogde kikkererwten": null,
    "Gedroogde oregano": null,
    "Gedroogde rode pepertjes": null,
    "Gegrilde paprika": null,
    "Gehakt": null,
    "Gehakt (half om half)": null,
    "Gehakte gember": null,
    "Geitenkaas": null,
    "Gekookte aardappels": null,
    "Gekruide tofu": null,
    "Gelatine": null,
    "Gele paprika": null,
    "Gele rozijnen": null,
    "Gele tomaat": null,
    "Gemalen komijn": null,
    "Gember": null,
    "Gemberjam": null,
    "Gemberpoeder": null,
    "Gemengde paddestoelen": null,
    "Gepeld hennepzaad": null,
    "Gepelde tomaten": null,
    "Geraspte kaas": null,
    "Gerookte forelfilet": null,
    "Gerookte kipfilet": null,
    "Gerookte zalm": null,
    "Gerookte zalmflakes": null,
    "Geroosterde paprika": null,
    "Gesneden rode kool": null,
    "Gezouten cashewnoten": null,
    "Gezouten pindas": null,
    "Gist": null,
    "Glasnoedels": null,
    "Gnocchi": null,
    "Gorgonzola": null,
    "Griekse yoghurt": null,
    "Groene asperges": null,
    "Groene olijven": null,
    "Groene paprika": null,
    "Groene peper": null,
    "Groene pesto": null,
    "Groentenbouillon": null,
    "Gruyère": null,
    "Haas": null,
    "Halfvolle melk": null,
    "Halloumi": null,
    "Ham": null,
    "Hamblokjes": null,
    "Hamburger": null,
    "Hamburger broodjes": null,
    "Hamlap": null,
    "Hamreepjes": null,
    "Handsinaasappel": null,
    "Haring": null,
    "Hartige taart deeg": null,
    "Havermout": null,
    "Hazelnoten": null,
    "Hazenbout": null,
    "Hele kipfilet": null,
    "Honing": null,
    "Hummus": null,
    "Hüttenkäse": null,
    "Ijsbergsla": null,
    "Ingemaakte citroen": null,
    "Inktvis": null,
    "Italiaanse bol": null,
    "Italiaanse kruiden": null,
    "Jalapeño peper": null,
    "Jam": null,
    "Jambon": null,
    "Japanse mayonaise (kewpie)": null,
    "Japanse wokgroente": null,
    "Jeneverbessen": null,
    "Jong belegen kaas": null,
    "Jonge kaas": null,
    "Jusblokjes": null,
    "Kaas": null,
    "Kabeljauw": null,
    "Kaffir limoen blad": null,
    "Kalamata olijven": null,
    "Kalfschenkel": null,
    "Kalfsfond": null,
    "Kandijkoeken": null,
    "Kaneel": null,
    "Kaneelstokje": null,
    "Kappertjes": null,
    "Karbonade": null,
    "Kardemom": null,
    "Kardemompeulen": null,
    "Kastanje champignons": null,
    "Kerrie": null,
    "Kerrie blaadjes": null,
    "Kerriepoeder": null,
    "Kervel": null,
    "Ketchup": null,
    "Ketjap": null,
    "Ketoembar": null,
    "Kibbeling": null,
    "Kikkererwten": null,
    "Kip": null,
    "Kipdijfilet": null,
    "Kipfilet": null,
    "Kippenbouillon": null,
    "Kippenpoot": null,
    "Knoflook": null,
    "Knoflooksaus": null,
    "Knolselderij": null,
    "Koffie": null,
    "Koffiebonen": null,
    "Kokoscreme / Santen": null,
    "Kokosmelk": null,
    "Komijn": null,
    "Komijnekaas": null,
    "Komijnzaad": null,
    "Komkommer": null,
    "Konijnenbout": null,
    "Kookroom": null,
    "Kool": null,
    "Koolrabi": null,
    "Koriander": null,
    "Korianderzaad": null,
    "Krenten": null,
    "Krieltjes": null,
    "Kristalsuiker": null,
    "Kroepoek": null,
    "Kruidenroomkaas": null,
    "Kruidnagel": null,
    "Kurkuma": null,
    "Kwark": null,
    "Lamsworstjes": null,
    "Laospoeder": null,
    "Lasagnebladen": null,
    "Laurierblad": null,
    "Lichtbruine basterdsuiker": null,
    "Lijnzaad": null,
    "Limoen": null,
    "Limoen sap": null,
    "Limoenschil": null,
    "Madeleines": null,
    "Magere runderlappen": null,
    "Mais": null,
    "Makreel": null,
    "Mandarijn": null,
    "Mango": null,
    "Mango chutney": null,
    "Mascarpone": null,
    "Mayonaise": null,
    "Melba toast": null,
    "Melk": null,
    "Mexicaanse kruiden": null,
    "Mie": null,
    "Mihoen": null,
    "Mirin": null,
    "Mosselen": null,
    "Mosterd": null,
    "Mozzarella": null,
    "Muesli": null,
    "Munt": null,
    "Noodles": null,
    "Nootmuskaat": null,
    "Oestersaus": null,
    "Okonomiyaki saus": null,
    "Okra": null,
    "Olie": null,
    "Olijfolie": null,
    "Olijfolie extra vierge": null,
    "Olijven": null,
    "Ongezouten pecannoten": null,
    "Ongezouten roomboter": null,
    "Ontbijtkoek": null,
    "Ontbijtspek": null,
    "Oregano": null,
    "Oude kaas": null,
    "Ovenfriet": null,
    "Paardebiefstuk": null,
    "Paddestoel": null,
    "Paksoi": null,
    "Palmsuiker": null,
    "Pancetta": null,
    "Paneermeel": null,
    "Pannenkoekenmix": null,
    "Paprika": null,
    "Paprikapoeder": null,
    "Parmaham": null,
    "Parmezaanse kaas": null,
    "Passata": null,
    "Pasta": null,
    "Pasta penne": null,
    "Pastinaak": null,
    "Patentbloem": null,
    "Pecorino": null,
    "Peer": null,
    "Peper": null,
    "Peperkorrels": null,
    "Peterselie": null,
    "Peultjes": null,
    "Pietermannen": null,
    "Pijnboompitten": null,
    "Piment d’esplette": null,
    "Pimentbessen": null,
    "Pindakaas": null,
    "Pindakaas met stukjes": null,
    "Pinda’s": null,
    "Pisco": null,
    "Pistachenootjes": null,
    "Pita broodjes": null,
    "Platte peterselie": null,
    "Poedersuiker": null,
    "Pomodoro": null,
    "Pompoen": null,
    "Pompoenpitten": null,
    "Prei": null,
    "Pruimen": null,
    "Puntpaprika": null,
    "Pure chocola": null,
    "Puy linzen": null,
    "Quinoa": null,
    "Radicchio": null,
    "Radijs": null,
    "Ras el hanout": null,
    "Rauwe ham": null,
    "Rauwe tonijn": null,
    "Ravioli funghi porcini": null,
    "Rawitpeper": null,
    "Ricotta": null,
    "Rietsuiker": null,
    "Rijst": null,
    "Rijst noodles": null,
    "Rijstazijn": null,
    "Rijstolie": null,
    "Rijstvellen groot": null,
    "Risottorijst": null,
    "Rode curry pasta": null,
    "Rode kool": null,
    "Rode linzen": null,
    "Rode paprika": null,
    "Rode peper": null,
    "Rode pesto": null,
    "Rode ui": null,
    "Rode wijn": null,
    "Rode wijn azijn": null,
    "Rookworst": null,
    "Room": null,
    "Roomboter": null,
    "Roomboterspritsen": null,
    "Roomkaas": null,
    "Roomkaas naturel": null,
    "Roquefort": null,
    "Rozemarijn": null,
    "Rozijnen": null,
    "Rucola": null,
    "Rucola mix": null,
    "Rum": null,
    "Runderbouillon": null,
    "Rundergehakt": null,
    "Runderreepjes": null,
    "Runderriblappen": null,
    "Rundvleesbouillon": null,
    "Rösti": null,
    "Saffraan": null,
    "Salami": null,
    "Salie": null,
    "Salsasaus": null,
    "Sambal": null,
    "Satesaus": null,
    "Saucijs": null,
    "Selderij": null,
    "Selderijknol": null,
    "Semigedroogde tomaten": null,
    "Semolinabloem": null,
    "Sereh": null,
    "Sesamolie": null,
    "Sesamzaad": null,
    "Sherry": null,
    "Sherry azijn": null,
    "Shitake": null,
    "Shoarma": null,
    "Sinaasappel": null,
    "Sjalot": null,
    "Sla": null,
    "Slagroom": null,
    "Smeerkaas": null,
    "Snijbonen": null,
    "Soep": null,
    "Soepballetjes": null,
    "Soepgroenten": null,
    "Sojasaus": null,
    "Spa rood": null,
    "Spaanse peper": null,
    "Spaghetti": null,
    "Spek": null,
    "Spekblokjes": null,
    "Speklap": null,
    "Sperziebonen": null,
    "Spinazie": null,
    "Spinazie a la creme": null,
    "Spitskool": null,
    "Spliterwten": null,
    "Spruitjes": null,
    "Stemgember op siroop": null,
    "Stokbrood": null,
    "Stroop": null,
    "Sugarsnaps": null,
    "Suiker": null,
    "Suikerstroop": null,
    "Sultanas": null,
    "Sultana’s": null,
    "Tabasco": null,
    "Taco schelpen": null,
    "Tagliatelle": null,
    "Tahini": null,
    "Tamarindepasta": null,
    "Tandoori kruiden": null,
    "Tartaar": null,
    "Tarwebloem": null,
    "Tauge": null,
    "Thaise roerbakmix": null,
    "Tijm": null,
    "Tobasco": null,
    "Tofu": null,
    "Tomaat": null,
    "Tomaten frito": null,
    "Tomatenblokjes": null,
    "Tomatenpuree": null,
    "Tomatensaus": null,
    "Tonijn": null,
    "Tortellini ai fromaggi": null,
    "Tortilla chips": null,
    "Trassie": null,
    "Truffel tapenade": null,
    "Tuinbonen": null,
    "Tuinerwten": null,
    "Tuinkruiden": null,
    "Turkse pizza": null,
    "Udon noedels": null,
    "Ui": null,
    "Vanillestokje": null,
    // "Vanillestokjes": null,
    "Vanillesuiker": null,
    "Varkensblokjes": null,
    "Varkenshaas": null,
    "Varkenslapje": null,
    "Varkensschenkel": null,
    "Vastkokende aardappelen": null,
    "Veldsla": null,
    "Venkel": null,
    "Venkelzaad": null,
    "Verdechio": null,
    "Vers bladerdeeg": null,
    "Verse kruiden": null,
    "Vijgen": null,
    "Vis": null,
    "Visfilet": null,
    "Vissaus": null,
    "Vlaamse friet": null,
    "Vlees": null,
    "Vleestomaat": null,
    "Vloeibare boter": null,
    "Volkoren penne": null,
    "Volle melk": null,
    "Vruchten yoghurt": null,
    "Walnoten": null,
    "Water": null,
    "Waterkers": null,
    "Whiskey cocktail saus": null,
    "Wijn": null,
    "Wijnazijn": null,
    "Wijting": null,
    "Wilde rijst": null,
    "Wildfond": null,
    "Winterpeen": null,
    "Wit brood": null,
    "Witlof": null,
    "Witte balsamico azijn": null,
    "Witte basterdsuiker": null,
    "Witte kool": null,
    "Witte reuzenbonen": null,
    "Witte rijst": null,
    "Witte wijn": null,
    "Witte wijnazijn": null,
    "Wokgarnalen met knoflook": null,
    "Worcestershire saus": null,
    "Wortel": null,
    "Wraps": null,
    "Yoghurt": null,
    "Zalm": null,
    "Zalmfilet": null,
    "Zalmforel": null,
    "Zalmsnippers": null,
    "Zeewiersalade": null,
    "Zeezout": null,
    "Zelfrijzend bakmeel": null,
    "Zoete aardappelen": null,
    "Zoetstof": null,
    "Zonnebloemolie": null,
    "Zout": null,
    "Zure room": null,
    "Zuurdesembrood": null,
    "Zuurkool": null,
    "Zwarte olijven": null,
    "Zwarte peper": null
}
