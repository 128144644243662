<template>
    <div id ="inventory">

        <h4 >Vaste boodschappen 
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip="Onder vaste boodschappen configureer je die items die je altijd in huis wilt hebben, maar die niet bij een recept horen. Ontbijt, lunch, tussendoortjes en huishoudelijke artikelen bijvoorbeeld.">
                    info_outline
                </i>
        </h4>  
        <div class="row">
            <div class="col s10">    
                <div class="input-field">
                    <input 
                        id="newItemInput" 
                        autocomplete="off"
                        class="autocomplete" 
                        type="text" 
                        v-model="newItem" 
                        v-on:keyup.enter="addToList(null)">
                    <label for="newItemInput">Nieuw item...</label>            
                </div>
            </div>
            <div class="col s2">    
                <button v-on:click="addToList(null)" class="btn waves-effect waves-ripple btnpad">
                    <i class="material-icons">add</i>
                </button>    
            </div>
        </div>

        <ul class='collection withlastdropzone' v-if="inventoryList.inventory.length>0">
            <li 
                v-bind:key="item.uid" 
                v-for="item in inventoryList.inventory"
                class="collection-item droppable"
                draggable
                @drop="onDrop($event, item)"   
                @dragstart="startDrag($event, item)"                    
                @dragover.prevent
                @dragenter.prevent 

                >
                    <span class='normal' id='innercontent'>
                        <!-- {{item.successMark}} -->
                        {{item.category.icon}}
                        {{item.invItemName}}
                        
                    </span>
                    
                    <div class="right gromstyle" id='innercontent'>
                        <!-- <a class="dropdown-trigger" :data-target="'invDropdown'+item.renderId" href="#" data-alignment="right"> <i class ="material-icons" >more_vert</i></a> -->
                        <a id='innercontent' href="#editListItem" v-on:click="setModalContent(item)" class="secondary-content modal-trigger">
                            <i id='innercontent' class="material-icons">more_vert</i>
                        </a>
                    </div>
                
                    <!-- Recipe Dropdown Structure -->
                    <!-- <ul :id="'invDropdown'+item.renderId" class='dropdown-content'>
                        <li v-on:click="deleteInvItem(item)"><a href="#!"><i class ="fa fa-trash" ></i>Verwijder</a></li>
                    </ul> -->
                    <!-- <i class ="fa fa-trash secondary-content" v-on:click="deleteInvItem(item)"></i> -->
            </li>

        </ul>
        <div 
                class="droppable lastdropzone"
                @drop="onDrop($event, 'Place at end')"  
                @dragover.prevent
                @dragenter.prevent 
        >
        </div>

        <div class = "collection center-align" v-if="inventoryList.inventory.length == 0">    
            <br>
            <i class ="material-icons">inbox</i>
            <div class="light">Je hebt nog geen vaste boodschappen.</div>
            <br>
            <!-- <router-link to="/inventory/new" class="btn waves-effect waves-ripple quickAddPad">
                Voeg item toe
                <i class="fa fa-plus"></i>
            </router-link> -->
        </div>

        <br>

        <!-- <div class="fixed-action-btn">
            <router-link to="/inventory/new" class = "btn-floating btn-large teal grey">
                <i class ="fa fa-plus"></i>
            </router-link>
        </div> -->
        
        <br>
        <br>
        <br>

        <editListItem
            :item="editItem"
            v-on:deleteItem="deleteItem"
            v-on:amendItem="amendItem"
            :categoryMasterData="categoryMasterData"
            :user="user"
            :modal="editListItemModal"
            :hideAmount="true"
        />    
                  
        </div>
    
</template>

<script>
import db from './firebaseInit'
import {doc, updateDoc} from 'firebase/firestore'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import sharedlogic from '@/scripts/sharedlogic'
import {autoCompleteList} from '@/data/autoComplete'
import editListItem from './modalEditListItem.vue'

export default {
    name: 'inventory',
    props : ['inventory','inventoryList','user','categoryMasterData'],
    data(){
        return{
            dropables: [],
            autoComplete: null,
            newItem: null,
            editListItemModal: null,
            editItem : {
                name:"",
                renderId:1,
                category:{
                    icon:"🤷🏼‍♂️",
                    categoryName:"Onbekend",
                    supermarketOrder:0,
                    id: "Onbekend"
                },
                done:false
            },
        }
    },  
    components:{
        editListItem
    },
    mounted(){  

        //initialize modals
        var elemsM = document.querySelectorAll('.modal');
        M.Modal.init(elemsM, {
        });

        var elemsELI = document.querySelector("#editListItem")
        this.editListItemModal = M.Modal.getInstance(elemsELI);


        var elemsT = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elemsT, {});

        var elems = this.$el.querySelectorAll('.dropdown-trigger')

        var elemsA = document.querySelector("#newItemInput")
        var options = {
            data : autoCompleteList,
            onAutocomplete: (item) => {
                console.log('In onAutoComplete - item : ', item)
                this.addToList(item)
            }
        }
        this.autoComplete = M.Autocomplete.init(elemsA,options) 
        
        if(elems.length>0){
            console.log('elems', elems)
            var dropdown = M.Dropdown.init(elems, {
                constrainWidth:false,
                // container:'DayCard'+this.id
                container:document.body
            })
            console.log('Dropdown' , dropdown)
        }

        this.initialiseDragStyleListeners()   
    },
    destroyed(){
        this.removeEventListeners()
    },
    created(){
        console.log('Created - Inventory')
        // this.sortInventoryItems()
    },
    watch:{
        'inventoryList.id' : 'initialiseDragStyleListeners'
    },
    methods : {
        findIndexOfIngredient(item){
            var orgItem = this.inventoryList.inventory.find(orgiginalItem=>orgiginalItem.uid==item.uid)
            return this.inventoryList.inventory.indexOf(orgItem)        
        },
        amendItem(item){
            console.log('amendItem',item)
            console.log('inventory', this.inventory)

            var indexToRemove = this.findIndexOfIngredient(item)
            console.log('indexToRemove',indexToRemove)
            item.invItemName = item.name
            
            this.inventoryList.inventory.splice(indexToRemove,1,item)
            this.saveInventoryList()
        },        
        deleteItem(item){
            console.log('deleteItem',item)
            var indexToRemove = this.findIndexOfIngredient(item)
            this.inventoryList.inventory.splice(indexToRemove,1)
            this.saveInventoryList()
        },
        setModalContent(item){
            console.log('setModalContent',item)
            console.log('categoryMasterData',this.categoryMasterData)
            item.name = item.invItemName
            item.category.id=item.category.categoryName.replaceAll('/','ß')
            this.editItem = JSON.parse(JSON.stringify(item))
        },
        removeEventListeners(){
            this.$el.removeEventListener('dragenter', (event) => this.addDraggingClass(event))
            this.$el.removeEventListener('dragleave', (event) => this.removeDraggingClass(event))
            this.$el.removeEventListener('drop', (event) => this.removeDraggingClass(event))
        },
        addToList(autoCompletedItem){
            console.log("AddToList, autoCompletedItem : ", autoCompletedItem)
            const nItem = autoCompletedItem ? autoCompletedItem : this.newItem
            if (!nItem){
                return //In case enter is pressed, while no item is filled in (OR when autocomplete is confirmed with enter)
            }
            var newItemToAdd = {
                invItemName: nItem.trim(),
                uid: sharedlogic.safeUID(nItem),
            }
           
            sharedlogic.categorize(newItemToAdd.invItemName, this.categoryMasterData,this.user).then(cat =>{
                newItemToAdd.category = cat
                this.inventoryList.inventory.unshift(newItemToAdd)
                this.removeEventListeners()
                this.initialiseDragStyleListeners()        
                this.saveInventoryList()
            }).catch(error => console.error(error))

            this.autoComplete.close()
            this.newItem = null    

        },
        addDraggingClass(event){
            console.log('Dragenter', event)
            event.preventDefault()



            if(event.toElement.id=='innercontent'){
                return
            }

            var draggingRemains = this.$el.querySelectorAll('.dragging')
            draggingRemains.forEach(dr=>{
                dr.classList.remove('dragging')
            })

            event.target.classList.add('dragging')

            // // console.log(event.target)
            // // console.log(event.target.classList)
            // if(event.target.classList.contains('droppable')){
            //     event.target.classList.add('dragging')
            //     // console.log('Target dragging add',event.target.classList)
            //     return
            // }
            // // console.log('Parent node', event.target.parentNode)
            // if(event.target.parentNode.classList.contains('droppable')){
            //     event.target.parentNode.classList.add('dragging')
            //     // console.log('Parent dragging add',event.target.parentNode.classList)
            //     // console.log('Parent node classlist',event.target.parentNode.classList)
            // }
            
        },
        removeDraggingClass(event){
            console.log('Dragleave', event)
            // console.log('Dragleave from element', event.fromElement)
            event.preventDefault()
            
            // if(!event.fromElement.id){
            //     event.target.classList.remove('dragging')    
            // }
            
            if(!event.fromElement){
                console.log('No from element', event.fromElement)
                return
            }


            if(event.fromElement.id=='innercontent'){
                return
            }
            event.target.classList.remove('dragging')

            // if(event.target.classList.contains('droppable')){
                
            //     event.target.classList.remove('dragging')
            //     // console.log('Target dragging remove',event.target.classList)
            //     // console.log('Target dragging remove',event)
            //     return
            // }
            // if(event.target.parentNode.classList.contains('droppable')){
            //     event.target.parentNode.classList.remove('dragging')
            //     // console.log('Parent dragging remove',event.target.parentNode.classList)
            // }
        },        
        initialiseDragStyleListeners(){
            console.log('initialiseDragStyleListeners', this.inventoryList)
            this.$nextTick().then(()=>{

                this.dropables = this.$el.querySelectorAll('.droppable')
                
                if (this.dropables.length==0){
                    console.log('Drag style listener initialisation aborted.')
                    return
                }
                console.log('Drag style listener initialisation starting.')
                // console.log('Dropables', this.dropables)

                this.dropables.forEach(da=>{
                    da.addEventListener('dragenter',(event) => this.addDraggingClass(event))

                    da.addEventListener('dragleave', (event) => this.removeDraggingClass(event));

                    da.addEventListener('drop', (event) => this.removeDraggingClass(event));
                })
                console.log('Dropables', this.dropables)
            })
        },
        onDrop(event,item){
            //item = the item the dragged item was dropped on --> the dragged item should move BEFORE this item
            console.log('Dropped : ', event, item)

            event.target.classList.remove('dragging')    

            var droppedItemID = event.dataTransfer.getData('itemID')
            console.log('Dropped itemID', droppedItemID)
            console.log('Place before :', item)

            var indexToDropBefore
            if(item=='Place at end'){
                indexToDropBefore = this.inventoryList.inventory.length
            } else{
                indexToDropBefore = this.inventoryList.inventory.indexOf(item)          
            }
            
            var droppedItemA = this.inventoryList.inventory.filter(inv=>inv.uid == droppedItemID)
            var droppedItem

            droppedItemA.length == 1 ? droppedItem = droppedItemA[0] : console.error('Dropped item not found')            
            if(!droppedItem){
                return
            }


            console.log("Dropped item", droppedItem)
            //Remove dropped item first
            var removedIndex = this.removeItemFromInventoryArray(droppedItem)
            console.log('indexes', removedIndex, indexToDropBefore)
            removedIndex < indexToDropBefore ? indexToDropBefore-- : null
            console.log('indexes', removedIndex, indexToDropBefore)
            //Then place it back on new position
            this.inventoryList.inventory.splice(indexToDropBefore, 0, droppedItem);
            this.saveInventoryList()

            // this.sortList()
        },
        // sortList(){
        //     this.inventoryList.inventory.sort((a,b)=>{
        //         if(a.orderBy<b.orderBy){
        //             return -1
        //         }

        //         if(a.orderBy>b.orderBy){
        //             return 1
        //         }

        //         return 0

        //     })
        // },
        startDrag(event,item){
            console.log('Start drag : ', event, item)

            var draggingRemains = this.$el.querySelectorAll('.dragging')
            draggingRemains.forEach(dr=>{
                dr.classList.remove('dragging')
            })

            event.dataTransfer.dropEffect = 'move'
            event.dataTransfer.effectAllowed = 'copyMove'
            if(!item.uid){
                item.uid = sharedlogic.safeUID(item.invItemName)
            }
            event.dataTransfer.setData('itemID',item.uid)
        },        
        // sendOrderStatusToDB(item){
        //     db.collection('Inventory').doc(item.id).update({
        //         order : item.order
        //     })
        // },
        removeItemFromInventoryArray(item){
                var indexToRemove = this.inventoryList.inventory.indexOf(item)          
                console.log(item)
                console.log(indexToRemove)
                
                this.inventoryList.inventory.splice(indexToRemove,1)
                return indexToRemove
        },
        deleteInvItem(item){
            if(confirm('Weet je het zeker?')){
                this.removeItemFromInventoryArray(item)
                this.saveInventoryList()
            }
        },
        saveInventoryList(){
            console.log('Save inventory list',this.inventoryList)
            const docRef = doc(db,"InventoryList",this.inventoryList.id)
            updateDoc(docRef,{
                inventory:this.inventoryList.inventory
            })
        }
    }
}

</script>

<style scoped>
.fa { 
    line-height: inherit
}

.light{
    color:grey
}
.normal{
    color:black
}

.quickAddPad{
    margin-top: 20px;
    margin-bottom: 20px
}

/* .collection-item */
.dragging {
    /* outline: 4px solid rgb(35,168,112); */
    /* box-shadow: 4px -4px 0 rgb(35,168,112); */
    box-shadow: 0 4px 0 rgb(35,168,112) inset;
    /* border: 4px dashed #ccc; */
}

.lastdropzone{
    /* border:1px solid black; */
    height:100px;
}

.withlastdropzone{
    margin-bottom:0px;
}
.btnpad{
    margin-top: 20px;
}
</style>
