<template>
    <!-- Modal Structure -->
    <div id="addInvItems" class="modal modal-fixed-footer">
        <div class="modal-content">
        
            <h4>Vaste boodschappen
                <i 
                    id="tooltipTitle"
                    class="material-icons tooltipped gromstyle-light" 
                    data-position="bottom" 
                    data-tooltip='Selecteer welke items van je vaste boodschappen je aan je boodschappenlijst wilt toevoegen. Je configureert je vaste voorraad items onder Instellingen > Vaste boodschappen.'
                >
                    info_outline
                </i>      
            </h4>

            <ul class="collection" v-if="inventoryList.inventory.length>0">
                <li 
                    class="collection-item"
                    v-bind:key="item.uid" 
                    v-for="item in inventoryList.inventory" 
                    >
                    <!-- v-bind:class="{'is-notToOrder':item.done}"  --> 
                            <label>  
                                <input 
                                    type="checkbox" 
                                    v-model="item.order" 
                                    @change="saveInventoryList()"/>
                                <span class='normal'>
                                    <!-- v-bind:class="{'is-notToOrder':item.done}" -->
                                    {{item.category.icon}}
                                    {{item.invItemName}}
                                </span>
                            </label>     
                </li>
            </ul>

            <div class = "collection center-align" v-if="inventoryList.inventory.length == 0">    
                <br>
                <i class ="material-icons">inbox</i>
                <div class="light">Je hebt nog geen vaste boodschappen geconfigureerd.</div>
                <br>
                <router-link to="/inventory" >
                    Beheer je vaste boodschappen onder Instellingen > Vaste boodschappen.
                </router-link>
                <br>
                <br>
            </div>
        </div>

        <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect btn-flat" v-on:click="addToList">
            Voeg toe aan lijst</a>
        </div>
    </div>
</template>

<script>
    import db from './firebaseInit'
    import {doc, updateDoc} from 'firebase/firestore'
    import M from '@materializecss/materialize/dist/js/materialize.min.js'
    import sharedlogic from '@/scripts/sharedlogic'
    export default {
        name: 'addInvItems',
        props: ['list', 'inventory','inventoryList'],
        data(){
            return{
                // inventory : [],
                selectedCat : null
            }
        },
        created(){
            console.log('AddInvItems created', this.inventoryList)
        },
        methods : {
            // sendOrderStatusToDB(record){
            //     db.collection('Inventory').doc(record.id).update({
            //         order : record.order
            //     })
            // },
            saveInventoryList(){
                console.log('Save inventory list',this.inventoryList)

                const docRef = doc(db,"InventoryList",this.inventoryList.id)
                updateDoc(docRef,{inventory:this.inventoryList.inventory})

                // db.collection('InventoryList').doc(this.inventoryList.id).update({
                //     inventory:this.inventoryList.inventory
                // })
            },
            addToList(){
                var i=0
                this.inventoryList.inventory.forEach(invItem =>{
                    if(invItem.order){
                        this.list.items.push({
                            name: invItem.invItemName,
                            origin: {
                                text: 'Vaste boodschappen',
                                type: 1 //0 = ad-hoc, //1 = inventory items, //2 = planned recipes
                            },
                            renderId: sharedlogic.uid(this.list.items.map(item => item.renderId)),
                            category: invItem.category,
                            dateAdded : new Date()
                        })
                        i++
                    }
                })
                M.toast({text: i + ' items toegevoegd aan je lijst!'})
                this.$emit('saveList')
            },

        }
    }
</script>

<style scoped>
.normal{
    color:black
}
</style>