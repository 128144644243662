export default{

    apiKey: "AIzaSyAprB1ti5jaUGMalnfvO6jbT0MBOHNueEw",
    authDomain: "recipes-d95e9.firebaseapp.com",
    databaseURL: "https://recipes-d95e9.firebaseio.com",
    projectId: "recipes-d95e9",
    storageBucket: "recipes-d95e9.appspot.com",
    messagingSenderId: "667510504791",
    appId: "1:667510504791:web:24094f076dad625648d212",
    measurementId: "G-1LY3YPLJYL"
}