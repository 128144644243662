<template>
    <div id='Spinner'>
        <div class="preloader-wrapper active " :class="{ big: size!='small', small: size=='small', spinnermargins: size!='small' }" >
            <div class="spinner-layer spinner-green-only">
                <div class="circle-clipper left">
                    <div class="circle"></div>
                </div>
                <div class="gap-patch">
                    <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                    <div class="circle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name:'Spinner',
    props:['size']
}

</script>


<style scoped>
.spinnermargins{
    margin-top:50px;
    margin-bottom:50px
}
</style>