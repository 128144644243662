<template>
    <div id="Profile">
        <h4>Profiel</h4>
          <div v-if="user" class="row">
            <form class="col s12">
            <div class="row">
                <div class="input-field col s12">
                    <i class="material-icons prefix gromstyle-light">email</i>
                    <input disabled id="icon_prefix" type="text" class="validate" v-model="user.email">
                    <label for="icon_prefix" class="active">Email</label>
                </div>
                <div class="input-field col s12">
                    <i class="material-icons prefix gromstyle-light">account_circle</i>
                    <input id="icon_telephone" type="text" class="validate" v-model="user.name">
                    <label for="icon_telephone" :class="{active: user.name}">Naam</label>
                </div>
            </div>
            </form>
        </div>
    </div>
</template>

<script>
import db from './firebaseInit'
import {doc,collection,updateDoc} from 'firebase/firestore'

export default {
    name: 'profile',
    props: ['user','saveTrigger','households'],
    data(){
        return{
            // inventory : []
        }
    },
    methods:{
        saveProfile(){
            console.log('save profile p')
            const docRef = doc(collection(db,"Users"),this.user.id)
            updateDoc(docRef,this.user)
            // db.collection('Users').doc(this.user.id).update(this.user)
            console.log('after update')
            this.households.forEach(hh=>{
                console.log('inside')
                hh.membersExtraInfo.forEach(mei=>{
                    if(mei.id==this.user.id){
                        mei.name=this.user.name
                    }
                })
                console.log(hh)
                var hhRef = doc(collection(db,"Household"),hh.id)
                updateDoc(hhRef,{
                    membersExtraInfo : hh.membersExtraInfo                    
                })
                // db.collection('Household').doc(hh.id).update({
                //     membersExtraInfo : hh.membersExtraInfo
                // })
            })
        }
    },
    watch: {
        'saveTrigger': 'saveProfile',
    }
}
</script>

<style scoped>
.material-icons{
    vertical-align: middle;   
}


</style>