    <template>
    <div id ="list">
        <h4>Boodschappenlijst
            <i 
                id="tooltipTitle"
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip='Op je boodschappenlijst voeg je items toe die je wilt kopen. Dit kan direct voor losse items, maar gebruik ook de optie "Snel toevoegen" om ingrediënten van ingeplande recepten of vaste boodschappen toe te voegen.'
            >info_outline
            </i>
        </h4>
        <div class="row">
            <div class="col s10">    
                <div class="input-field">
                    <input 
                        id="newItemInput" 
                        autocomplete="off"
                        class="autocomplete" 
                        type="text" 
                        v-model="newItem" 
                        v-on:keyup.enter="addToList(null)">
                    <label for="newItemInput">Nieuw item...</label>            
                </div>
            </div>
            <div class="col s2">    
                <button v-on:click="addToList(null)" class="btn waves-effect waves-ripple btnpad">
                    <i class="material-icons">add</i>
                </button>    
            </div>
        </div>
 
        <div class = "row">
            <span class="left">
                <a href='#importItems' class="waves-effect  btn-flat  modal-trigger"><i class="material-icons">playlist_add</i></a> 
                <a v-if="displayExport" href='#exportList' class="waves-effect  btn-flat  modal-trigger"><i class="material-icons">file_upload</i></a> 
                <a v-if="itemsTotal>0" v-on:click="clearList" class="waves-effect  btn-flat"><i class="material-icons">clear</i></a>   
                <span v-if="itemsTotal>0">{{itemsNotDone}} / {{itemsTotal}}</span>  
            </span>

            <span class=" right">
                <span v-if="user && supermarketSupportsDirectSearch(user.favoriteSupermarket)">
                    <a v-if="user.directSearch && supermarketSupportsDirectSearch" v-on:click="toggleDirectSearch" class="waves-effect  btn-flat "><i class="material-icons">search_off</i></a>
                    <a v-else v-on:click="toggleDirectSearch" class="waves-effect  btn-flat "><i class="material-icons">manage_search</i></a>
                </span>
                <span v-if="user">
                    <a v-if="!user.hideDone" v-on:click="changeVisibility" class="waves-effect  btn-flat "><i class="material-icons">visibility</i></a>
                    <a v-if="user.hideDone" v-on:click="changeVisibility" class="waves-effect  btn-flat "><i class="material-icons">visibility_off</i></a>
                </span>
                <a v-on:click="cycleSortMode" class="waves-effect  btn-flat "><i class="material-icons">sort</i></a>
            </span>
        </div>
        
        <div class = "row" v-if="filteredItems.length > 0">
            <div v-if="user">
                <div v-if="user.directSearch && supermarketSupportsDirectSearch(user.favoriteSupermarket)">
                    <div class='center-align'>
                        <button v-on:click="addToBasketAtSupermarket()" class="btn waves-effect waves-ripple">
                            Toevoegen aan lijstje op AH.nl
                        </button>    
                    </div>
                    <div
                        v-for="item in filteredItems"
                        v-bind:key="item.renderId"> 
                        <ul  class="collection" >
                            <li 
                                class="collection-item truncate"
                                v-bind:class="{'is-notToOrder':item.done}"
                                >
                                
                                <list-item
                                    :item="item"
                                    :supermarketSearchPrefix="supermarketSearchPrefix"
                                    v-on:set-modal-content="setModalContent(item)"
                                    @save-list="saveList"
                                />
                                
                            </li>
                        </ul>

                        <div v-if="!item.done" class="row">
                            <div v-if="isItemLoading(item.name)" class="progress">
                                <div class="indeterminate"></div>
                            </div>
                            <!-- && item.productsFromSupermarket == user.favoriteSupermarket -->
                            <!-- <div v-if="productSuggestions.find(pS=>pS.itemName == item.name && user.favoriteSupermarket == pS.supermarket).products">
                                <div v-if="productSuggestions.find(pS=>pS.itemName == item.name && user.favoriteSupermarket == pS.supermarket).products.length == 0" class="light"> -->
                            <div v-if="productSuggestionsForItem(item)">
                                <div v-if="productSuggestionsForItem(item).length == 0 && !isItemLoading(item.name)" class="light">
                                    Geen producten gevonden.
                                </div>
                            
                                <!-- <div class="col s12 m6 xl3" v-for="product in item.products.slice(0,item.noProductsToShow)" v-bind:key="product.webshopId"> -->
                                <!-- <div class="col s12 m6 xl3" v-for="productSuggestion in productSuggestions.find(pS=>pS.itemName == item.name && user.favoriteSupermarket == pS.supermarket).products" v-bind:key="productSuggestion.itemName"> -->
                                <div class="col s12 m6 xl3" v-for="productSuggestion in productSuggestionsForItem(item).slice(0,noProductsToShow(item))" v-bind:key="productSuggestion.itemName">

                                    
                                    <product
                                        :webshopId="productSuggestion.webshopId"
                                        :quantity="currentQuantity(productSuggestion,item)"
                                        :imgUrl="getImage(productSuggestion)"
                                        :description="productSuggestion.title"
                                        :price="productSuggestion.currentPrice ? productSuggestion.currentPrice : productSuggestion.priceBeforeBonus"
                                        :amount="productSuggestion.salesUnitSize"
                                        :unitPriceDescription="productSuggestion.unitPriceDescription"
                                        
                                        @order="saveOrderOnItem($event,item, productSuggestion)"
                                    />
                                </div>
                                <div class="col s12 m6 xl3 input-field" >
                                    <!-- <div>
                                        <i class="material-icons prefix gromstyle-light">search</i>
                                        <input id="icon_prefix" type="text" class="validate">
                                        <label for="icon_prefix" class="active">Zoek een ander product</label>
                                    </div> -->
                                    <!-- <div v-if="productSuggestions.find(pS=>pS.itemName == item.name && user.favoriteSupermarket == pS.supermarket).products.length >3" class = "center-align padtop"> -->
                                    <div v-if="productSuggestionsForItem(item).length >3" class = "center-align padtop">
                                        <button v-on:click="toggleMoreLess(item)" class="btn waves-effect waves-ripple btnpad ">
                                            <div v-if="noProductsToShow(item)==3">Toon meer<i class="material-icons ma-centered">expand_more</i> </div>
                                            <div v-if="noProductsToShow(item)==100">Toon minder<i class="material-icons ma-centered">expand_less</i> </div>
                                        </button>    
                                    </div>
                                </div>                                

                            </div>
                        </div>

                        <div v-else class="row">
                            <div v-if="item.orders && item.orderTransferredToSupermarket">
                                <div class="col s12 m6 xl3" v-for="order in item.orders.filter(order => order.quantity>0)" v-bind:key="order.webshopId">   
                                    <product
                                        :webshopId="order.webshopId"
                                        :imgUrl="order.imgUrl"
                                        :description="order.description"
                                        :price="order.price"
                                        :amount="order.amount"
                                        :unitPriceDescription="order.unitPriceDescription"
                                        :quantity="order.quantity"
                                        :quantityFixed="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                <div v-else>
                    <ul  class="collection" >
                        <li 
                            class="collection-item truncate"
                            v-bind:class="{'is-notToOrder':item.done}"
                            v-for="item in filteredItems"
                            v-bind:key="item.renderId" 
                            >
                            
                            <list-item
                                :item="item"
                                :supermarketSearchPrefix="supermarketSearchPrefix"
                                v-on:set-modal-content="setModalContent(item)"
                                @save-list="saveList"
                            />
                            
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
        <div class = "collection center-align" v-if="filteredItems.length == 0 && list.items.length !=0">    
            <br>
            <i class ="material-icons">done_all</i>
            <div class="light">Alle items zijn afgevinkt!</div>
            <a v-if="supermarketPlaceOrder" :href="supermarketPlaceOrder" class="btn waves-effect waves-ripple quickAddPad">
                Bestel nu bij {{user.favoriteSupermarket}}
                <i class="material-icons ma-centered">launch</i>
            </a>   
            <br>
        </div>

        <spinner class = "center-align" v-if="!listLoaded"/>

        <div class = "collection center-align" v-if="filteredItems.length == 0 && list.items.length ==0 && listLoaded">    
            <br>
            <i class ="material-icons">inbox</i>
            <div class="light">Je lijst is nog leeg!</div>
            <button href='#importItems' class="btn waves-effect waves-ripple quickAddPad modal-trigger">
                Snel toevoegen 
                <i class="material-icons ma-centered">playlist_add</i>
            </button>    
            <br>
        </div>


        <addInvItems
            :list="list"
            v-on:saveList="saveList"
            :inventory="inventory"
            :inventoryList="inventoryList"
        />
        <br>
        <br>

        <modalCalendar
            :list="list"
            v-on:saveList="saveList"
            :modal="calendarModal"
            :recipes="recipes"
            :noteList="noteList"
        />
        <br>
        <br>
        
        <editListItem
            :item="editItem"
            :modal="editListItemModal"
            v-on:deleteItem="deleteItemFromList"
            v-on:amendItem="amendItem"
            v-on:saveList="saveList"
            :categoryMasterData="categoryMasterData"
            :user="user"
        />

        <exportList
            :list="list"
            :user="user"
        />

        <importItems 
            v-on:addInvItems="invItemModal.open()"
            v-on:addCalendaritems="calendarModal.open()"
        />

        
        
    </div>
</template>

<script>
import db from './firebaseInit'
import {setDoc, updateDoc, doc, collection} from 'firebase/firestore'
import sharedlogic from '@/scripts/sharedlogic'
import editListItem from './modalEditListItem.vue'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
// const M = require('@materializecss/materialize/dist/js/materialize.min.js');
import addInvItems from './modalAddInvItems.vue'
import modalCalendar from './modalCalendar.vue'
import exportList from './modalExportList.vue'
import importItems from './modalImportItems.vue'

import {autoCompleteList} from '@/data/autoComplete'
import ListItem from './ListItem.vue'
import Product from './Product.vue'
import Spinner from './Spinner.vue'

export default {

    name: 'list',
    props: ['recipes','noteList','list','categoryMasterData', 'inventory','user','inventoryList','api','listLoaded','productSuggestions'],
    data(){
        return {
            newItem: null,
            editItem : {
                name:"",
                renderId:1,
                category:{
                    icon:"🤷🏼‍♂️",
                    categoryName:"Onbekend",
                    supermarketOrder:0,
                    id: "Onbekend"
                },
                done:false
            },
            invItemModal: null,
            calendarModal: null,
            editListItemModal: null,
            sortMode: 0 ,
            jumboSearchPrefix : "https://www.jumbo.com/zoeken?searchTerms=",
            ahSearchPrefix : "https://www.ah.nl/zoeken?query=",
            // plusSearchPrefix : "https://www.plus.nl/zoekresultaten?SearchTerm="
            // plusSearchPrefix : "https://www.awin1.com/cread.php?awinmid=12479&awinaffid=1127067&clickref=user&ued=https%3A%2F%2Fwww.plus.nl%2Fzoekresultaten%3FSearchTerm%3D"
            autoComplete : null,
            dateZ : new Date(0),
            today : new Date(),
            products :[],
            // productSuggestions:[],
            dedupItems:[]
        }
    },
    components: {
        editListItem,
        addInvItems,
        modalCalendar,
        exportList,
        importItems,
        ListItem,
        Product,
        Spinner
    },
    mounted(){
        console.log('List - mounted')
        var elems = document.querySelectorAll('.modal');
        
        M.Modal.init(elems, {
        });

        var elemsIIM = document.querySelector("#addInvItems")
        this.invItemModal = M.Modal.getInstance(elemsIIM);
        
        var elemsCM = document.querySelector("#modalCalendar")
        this.calendarModal = M.Modal.getInstance(elemsCM);

        var elemsELI = document.querySelector("#editListItem")
        this.editListItemModal = M.Modal.getInstance(elemsELI);
        console.log('edit list item modal', this.editListItemModal)

        var elemsA = document.querySelector("#newItemInput")
        var options = {
            data : autoCompleteList,
            onAutocomplete: (item) => {
                console.log('In onAutoComplete - item : ', item)
                this.addToList(item)
                // this.newItem = res

                // this.addToList()
            }
        }
        this.autoComplete = M.Autocomplete.init(elemsA,options) 

        var elemsT = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elemsT, {});   
        
        
    },
    created(){
        console.log('Created - List')

        this.findProductsForItems(false)
        // this.productSearch('Brood')

        // window.addEventListener('blur',(event)=>console.log("on blur:", event));
    },
    watch: {
        // 'list':'findProductsForItems',
    },
    computed :{
        ijsbergsla(){
            return this.productSuggestions.filter(pS=>pS.itemName == 'Ijsbergsla' && this.user.favoriteSupermarket == pS.supermarket)
        },

        // directSearch(){
        //     return this.user.favoriteSupermarket == 'AH' ? true : false
        // },
        filteredItems : function (){
            console.log('Computed - filtered items')
            var filteredItems = this.hideCompleted(this.list.items)
            filteredItems = this.sortList(filteredItems)
            return filteredItems
        },
        itemsTotal(){
            return this.list.items.length
        },
        itemsNotDone(){
            return this.list.items.filter(item=>!item.done).length
        },
        displayExport(){
            // var todoist = false
            // var ah =false
            // if(this.user){
            //     if (this.user.todoistAPIKey && this.user.todoistProjectId){
            //         todoist = true
            //     }
            //     if (this.user.AHConnector){
            //         ah = true
            //     }
            // }
            
            // return todoist || ah
            return true
        },
        plusSearchPrefix(){
            return `https://www.awin1.com/cread.php?awinmid=12479&awinaffid=1127067&clickref=${this.user.id}&clickref2=${this.user.household}&ued=https%3A%2F%2Fwww.plus.nl%2Fzoekresultaten%3FSearchTerm%3D`
        },
        coopSearchPrefix(){
            return `https://www.awin1.com/cread.php?awinmid=12871&awinaffid=1127067&clickref=${this.user.id}&clickref2=${this.user.household}&ued=https%3A%2F%2Fwww.coop.nl%2Fzoeken%2F`
        },
        supermarketPlaceOrder(){
            console.log('SupermarketPlaceOrder',this.user)
            var link
            switch(this.user.favoriteSupermarket) {
                    case 'Jumbo':
                        // return 'https://www.jumbo.com'
                        link =  "https://jumbo.com"
                        break
                    case 'AH':
                        // return 'https://ah.nl/mijnlijst'
                        link =  "https://ah.nl/mijnlijst"
                        break
                    case 'Plus':
                        link =  `https://www.awin1.com/cread.php?awinmid=12479&awinaffid=1127067&clickref=${this.user.id}&clickref2=${this.user.household}&clickref3=final&ued=https%3A%2F%2Fwww.plus.nl%2Fbestelling`
                        break
                    case 'Coop':
                        // return 'https://coop.nl'
                        link =  `https://www.awin1.com/cread.php?awinmid=12871&awinaffid=1127067&clickref=${this.user.id}&clickref2=${this.user.household}&clickref3=final&ued=https%3A%2F%2Fwww.coop.nl`
                        break
                    default:
                        link = null
                    }  
                    console.log('Link',link)
            return link
        },
        supermarketSearchPrefix (){
            console.log('SupermarketSearchPrefix')
            var supermarketSearchPrefix
            switch(this.user.favoriteSupermarket) {
                case 'Jumbo':
                    supermarketSearchPrefix = this.jumboSearchPrefix
                    break;
                case 'AH':
                    supermarketSearchPrefix = this.ahSearchPrefix
                    break;
                case 'Plus':
                    supermarketSearchPrefix = this.plusSearchPrefix
                    break;
                case 'Coop':
                    supermarketSearchPrefix = this.coopSearchPrefix
                    break;
                default:
                    supermarketSearchPrefix = null
                }  
            return supermarketSearchPrefix  
        }
    },
    methods:{
        isItemLoading(itemName){
            var item = this.dedupItems.find(item=>item.name==itemName)
            return item ? item.loading : false
        },
        noProductsToShow(item){
            return item.noProductsToShow ? item.noProductsToShow : 3
        },
        productSuggestionsForItem(item){
            var prodSuggest = this.productSuggestions.find(pS=>pS.itemName == item.name && this.user.favoriteSupermarket == pS.supermarket)

            return prodSuggest ? prodSuggest.products : []
            // productSuggestions.find(pS=>pS.itemName == item.name && user.favoriteSupermarket == pS.supermarket).products
        },
        supermarketSupportsDirectSearch(supermarket){
            return supermarket == 'AH' ? true : false
        },
        getImage(product){
            // console.log('getImage', product)
            if(product){
                const mediumImage = product.images.find(img => img.width==200)
                return mediumImage ? mediumImage.url : 'https://static.ah.nl/__assets/zoeken/assets/f2d6fe76.svg'
            }
        },
        toggleMoreLess(item){
            console.log('moreless')
            if (item.noProductsToShow ==3){
                
                item.noProductsToShow =100
                console.log('moreless3', item)
            } else {
                
                item.noProductsToShow =3
                console.log('moreless100', item)
            }
            item.renderId=Math.floor(Math.random() * 100000)
            
        },
        addToBasketAtSupermarket(){
            // const allOrders = this.list.items.flatMap(item => item.orders);
            console.log('Add to basket at supermarket')
            // console.log(allOrders);
            var url = 'https://www.ah.nl/mijnlijst/add-multiple?'
            var noProducts = 0
            this.list.items.forEach(item=>{
                if (!item.done){
                    if (item.orders){
                        item.orders.forEach(order=>{
                            noProducts++
                            if (noProducts==1){
                                url=url+`p=${order.webshopId}:${order.quantity}`
                            } else {
                                url=url+`&p=${order.webshopId}:${order.quantity}`
                            }
                            console.log('item',item)
                            item.done=true
                            item.orderTransferredToSupermarket=true
                        })
                    }
                    
                }
            })

            console.log('Saving list, then redirecting to : ', url)
            if (noProducts>0){
                this.saveList().then(()=>{
                    console.log('Redirecting to :', url)
                    window.location.href = url
                }).catch(err=>console.error('Problem saving list', err))
                
            } else {
                M.toast({text: 'Kies eerst producten bij de items op je lijstje.'})
            }
            
        },
        currentQuantity(product,item){
            // console.log('current quantity', item, product)
            
            if (item.orders){
                // console.log('current quantity return',item.orders.filter(order=>order.webshopId==product.webshopId))
                // if (item.orders.filter(order=>order.webshopId==product.webshopId).length==1){
                //     return item.orders.filter(order=>order.webshopId==product.webshopId)[0].quantity
                // }
                var order = item.orders.find(order=>order.webshopId==product.webshopId)
                return order ? order.quantity : 0
            } 
            return 0
            
        },
        saveOrderOnItem(event,item, product){
            console.log('saving order on item', event, item, product)
            if (event.webshopId ==product.webshopId){
                if (!item.orders){
                    item.orders= []
                    item.orders.push(event)
                } else {
                    const index = item.orders.findIndex(order=>order.webshopId==event.webshopId)
                    if (index==-1){
                        item.orders.push(event)
                    } else {
                        item.orders[index]=event
                    }
                }
                this.saveList()
            }
        },
        findProductsForItems(force){
            function dedup(array) {
            const uniqueItems = [];
            const itemNames = {};

            for (const item of array) {
                const itemName = item.name;

                if (!itemNames[itemName]) {
                itemNames[itemName] = true;
                uniqueItems.push(item);
                }
            }

            return uniqueItems;
            }

            this.dedupItems = dedup(this.filteredItems.map(item=>{
                return {
                    name: item.name,
                    loading: false
                }
            }))
            console.log('Dedup items', this.dedupItems)
            if (!this.user){
                return
            }

            console.log('Going into product suggestion fetch', force == true ||
                    (this.user.directSearch && this.supermarketSupportsDirectSearch(this.user.favoriteSupermarket)))
            if (
                    force == true||
                    (this.user.directSearch && this.supermarketSupportsDirectSearch(this.user.favoriteSupermarket))
                ){
                    this.thottledProductSearch(this.dedupItems,3,250)
                // this.dedupItems.forEach(item=>{
                //     // console.log('find products for item', item.name, item.products)
                //     if (!this.productSuggestions.find(pS=>pS.itemName==item.name)){
                //     // if (!1item.products){
                //         item.loading = true
                //         // fetch(`http://localhost:8081/search?query=${item.name}`)
                //         console.log('About to get product suggestions for :',item.name)
                //         fetch(`${this.api}/search?query=${item.name}`)
                //         .then(res=>res.text())
                //         .then(res=>{
                //             var response = JSON.parse(res)
                //             console.log('Product suggestion response', item.name, response.products)
                //             item.noProductsToShow = 3
                            
                //             var productSuggestion={
                //                 // itemId:item.uid,
                //                 itemName:item.name,
                //                 supermarket: 'AH',
                //                 products:response.products
                //             }

                //             // this.productSuggestions.push(productSuggestion)
                //             this.$emit('addProductSuggestion', productSuggestion)

                //             // item.renderId=Math.floor(Math.random() * 100000)
                //             item.loading = false
                //             // this.$forceUpdate()
                //         }).catch(err=>{
                //             item.loading = false
                //             console.log('Problem fetching search results for item : ', item.name, 'Error : ', err)
                //         })
                //     }   
                    

                // })
            }

            
            
        },
        thottledProductSearch(items, limit, delay) {
            console.log('Throttled product search')
            const api = this.api
            const thiss = this
            function fetchItem(item){
                return fetch(`${api}/search?query=${item.name}`)
                        .then(res=>res.text())
                        .then(res=>{
                            var response = JSON.parse(res)
                            console.log('Product suggestion response', item.name, response.products)
                            item.noProductsToShow = 3
                            
                            var productSuggestion={
                                itemName:item.name,
                                supermarket: 'AH',
                                products:response.products
                            }

                            thiss.$emit('addProductSuggestion', productSuggestion)

                            item.loading = false
                        }).catch(err=>{
                            item.loading = false
                            console.log('Problem fetching search results for item : ', item.name, 'Error : ', err)
                        })
            }

            return new Promise((resolve, reject) => {
                // const results = [];
                let count = 0
                let countToBatch =0
                function processNext() {
                if (count >= items.length) {
                    resolve('All done');
                    return;
                }

                const item = items[count];
                fetchItem(item)
                    .then(() => {
                    count++;
                    countToBatch++
                    if (countToBatch < limit) {
                        processNext();
                    } else {
                        countToBatch=0
                        setTimeout(processNext, delay);
                    }
                    })
                    .catch(error => reject(error));
                }

                processNext();
            });
        },
        supermarketLinkClick(event){
            //Experimental function to detect click events on the supermarket link. Add the below event listeners on the supermarket link anchor tag.
            // v-on:click="supermarketLinkClick($event)" v-on:auxclick="supermarketLinkClick($event)" v-on:newTabAction="supermarketLinkClick($event)"
            console.log("Supermarket link clicked : ", event)

            if(event.which == 1 && event.ctrlKey){
                console.log('CTRL+Click open in new tab')
            }
            
            if(event.which == 1 && event.shiftKey){
                console.log('SHIFT+Click open in new window')
            }

            if(event.which == 1 && event.metaKey){
                console.log('Metakey(apple)+Click open in new window')
            }

            if(event.which == 3){
                console.log('Right mouse click : open context menu')
            }

            if(event.which == 2){
                console.log('Middle mouse click : open in new tab')
            }
        },
        hideCompleted(list){
            return list.filter(item =>{
                    if(this.user.hideDone){
                        return !item.done
                    } else{
                        return true
                    }
            })
        },
        changeVisibility(){
            const docRef = doc(db,"Users",this.user.id)
            updateDoc(docRef,{hideDone : !this.user.hideDone})
            // db.collection('Users').doc(this.user.id).update({hideDone : !this.user.hideDone})
        },
        toggleDirectSearch(){
            var directSearch = !this.user.directSearch
            const docRef = doc(db,"Users",this.user.id)
            updateDoc(docRef,{directSearch : directSearch})
            if(directSearch){
                this.findProductsForItems(true)
            }
        },
        cycleSortMode(){
            //Sort modes are:
            // 0 = On supermarketorder
            // 1 = On recipe, then alphabetically

            //Cycle through sort modes
            this.sortMode++
            if (this.sortMode ==2) {
                this.sortMode =0
                M.toast({text: 'Lijst gesorteerd op categorie!'})
            }else{
                M.toast({text: 'Lijst gesorteerd op recept!'})
            }
            
            //Let go of any sorting based on dateAdded
            this.list.items.forEach(item => {
                delete item.dateAdded
            })

            this.saveList()
        },
        prepareList(list){
            list.forEach(item=>{
                if (!item.origin.text){
                    item.origin.text=''
                }
                // console.log('preparelist', item)
                if (!item.dateAdded){
                    item.dateAdded = new Date(0)
                } 
                else if ('seconds' in item.dateAdded) {
                    //This is a Firestore timestamp that needs to be converted to a date.
                    item.dateAdded = item.dateAdded.toDate()
                }
            })
        },
        sortList(list){

            this.prepareList(list)

            // console.log('sortList, list :', list)

            switch(this.sortMode){
                
                case 0 : //Supermarket order
                    list.sort((a,b) => {

                        if (a.dateAdded.valueOf() < b.dateAdded.valueOf()){
                            return 1
                        } 

                        if (a.dateAdded.valueOf() > b.dateAdded.valueOf()){
                            return -1
                        } 

                        if(a.category.supermarketOrder == b.category.supermarketOrder){
                            
                            if( a.name.toLowerCase() > b.name.toLowerCase()) {
                                return 1; 
                            }
                            if( a.name.toLowerCase() < b.name.toLowerCase()){   
                                return -1 
                            }
                                return 0   
                        } else {
                            if( a.category.supermarketOrder > b.category.supermarketOrder) {
                                return 1; 
                            }
                            if( a.category.supermarketOrder < b.category.supermarketOrder){   
                                return -1 
                            }
                        } 
                    })
                    
                break
                case 1 : //Origin (recipe)
                    list.sort((a,b) => {

                        if (a.dateAdded.valueOf() < b.dateAdded.valueOf()){
                            return 1
                        }

                        if (a.dateAdded.valueOf() > b.dateAdded.valueOf()){
                            return -1
                        } 

                        if(a.origin.text == b.origin.text){
                            if( a.name.toLowerCase() > b.name.toLowerCase()) {
                                return 1; 
                            }
                            if( a.name.toLowerCase() < b.name.toLowerCase()){   
                                return -1 
                            }
                                return 0   
                        } else {
                            if( a.origin.text.toLowerCase() > b.origin.text.toLowerCase()) {
                                return 1; 
                            }
                            if( a.origin.text.toLowerCase() < b.origin.text.toLowerCase()){   
                                return -1 
                            }
                        } 
                    })
                break
            }
            return list
        },
        addToList(autoCompletedItem){
            console.log("AddToList, autoCompletedItem : ", autoCompletedItem)
            const nItem = autoCompletedItem ? autoCompletedItem : this.newItem
            if (!nItem){
                return //In case enter is pressed, while no item is filled in (OR when autocomplete is confirmed with enter)
            }
            var newItemToAdd = {
                name: nItem.trim(),
                renderId: Math.floor(Math.random() * 100000),
                origin: {
                    text: 'Ad-hoc',
                    type: 0 //0 = ad-hoc, //1 = inventory items, //2 = planned recipes
                },
                dateAdded : new Date()
            }
           
            sharedlogic.categorize(newItemToAdd.name, this.categoryMasterData,this.user).then(cat =>{
                newItemToAdd.category = cat
                this.list.items.unshift(newItemToAdd)
                this.saveList()
                // document.getElementById("#newItemInput").focus(); //This doesn't work (produces an error) and is unnecessary (new item field is in focus anyway)
            }).catch(error => console.error(error))

            this.autoComplete.close()
            this.newItem = null
            
            
        },
        findIndexOfIngredient(item){
            var orgItem = this.list.items.find(orgiginalItem=>orgiginalItem.uid==item.uid)
            return this.list.items.indexOf(orgItem)        
        },
        amendItem(item){
            console.log('Amend item on list', item)

            var indexToRemove = this.findIndexOfIngredient(item)                   
            this.list.items.splice(indexToRemove,1,item)

            this.saveList()
        },
        deleteItemFromList(item){
            console.log('delete item from list',item)
            
            var indexToRemove = this.findIndexOfIngredient(item)                           
            this.list.items.splice(indexToRemove,1)

            this.saveList()
        },
        saveList(){
            console.log('List will be stored using id : ' + this.list.id)
            console.log('The list object size is : ', JSON.stringify(this.list).length)

            if (!this.list.id){
                alert('There is a problem with your shopping list. Try clearing it.')
            } else {
                console.log('List : ' , this.list)
                const docRef = doc(db, "List", this.list.id)
                return new Promise ((resolve,reject)=>{setDoc(docRef,this.list).then(()=>resolve('List saved.')).catch(err=>reject(err))})
                
                // db.collection('List').doc(this.list.id).set(this.list)
            }     
            this.findProductsForItems(false)      
        },
        clearList(){
            if(confirm('Weet je zeker dat je deze lijst leeg wilt maken?')){
                console.log('Clear list')

                this.$emit('clearList')
                // this.productSuggestions=[]

                if (this.list.id!=null){
                    console.log('Deactivating list with id : ' + this.list.id)
                    const docRefol = doc(db,"List",this.list.id)
                    updateDoc(docRefol,{active:false})
                    // db.collection('List').doc(this.list.id).update({active:false})
                    //Query listener on App level should now create a new list.
                }

                const docRefnl = doc(collection(db, "List"))
                
                // var listid = db.collection('List').doc().id
                // console.log('Creating a new list with ID: ' + listid)
                console.log('Creating a new list with ID: ' + docRefnl.id)
                setDoc(docRefnl,{
                    id : docRefnl.id,
                    active : true,
                    createdDate: new Date(),
                    creator: this.user.id,
                    household: this.user.household,
                    items : []
                })

                // db.collection('List').doc(listid).set({
                //     id : listid,
                //     active : true,
                //     createdDate: new Date(),
                //     creator: this.user.id,
                //     household: this.user.household,
                //     items : []
                // })

                M.toast({text: 'Boodschappenlijst leeg gemaakt.'})
            }

        },
        setModalContent(item){
            console.log('Set modal content')
            console.log(item)
            this.editItem = new Date(0)
            var dateAdded = item.dateAdded
            // this.editItem = item
            item.uid= sharedlogic.safeUID(item.name)
            item.category.id=item.category.categoryName.replaceAll('/','ß') // Bit of an ugly fix to make v-bind on the dropdown in the editlistitem component work. There are many ingredients that have the id prop missing  (while categoryMasterData has it).
            this.editItem = JSON.parse(JSON.stringify(item))
            this.editItem.dateAdded = dateAdded
            console.log(this.editItem)
        },

    }
}
</script>

<style scoped>

.btnpad{
    margin-top: 20px;
}
.quickAddPad{
    margin-top: 20px;
    margin-bottom: 20px
}



.light{
    color:grey
}

.as-icon{
    padding-right: 3px;
}


.ma-centered{
    vertical-align: middle !important;
    padding-bottom: 3px;
    /* margin-left : 6px; */
}
.padtop{
    padding-top:40px
}
</style>