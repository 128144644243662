<template>
  <div>
    <div class="container">
    <div class="row">
      <div class="col s12 m8 offset-m2">
        <div class="card-panel grey lighten-4  center">
          <h3>Log in</h3>
          <form action="index.html">
            <div class="input-field">
              <i class="material-icons prefix gromstyle-light">email</i>
              <input type="email" id="email" v-model="email">
              <label for="email">Email adres</label>
            </div>
            <div class="input-field">
              <i class="material-icons prefix gromstyle-light">lock</i>
              <input type="password" id="password" v-model="password">
              <label for="password">Wachtwoord</label>
            </div>
            <button v-on:click="login" class="btn btn-large btn-extended grey lighten-4 black-text">Log in</button>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
// import firebase from 'firebase/compat/app'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import sharedlogic from '@/scripts/sharedlogic'
export default {
  name: 'login',
  data: function() {
    return {
      email: '',
      password: ''
    };
  },
  methods: {
    login(e){
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
      .then((userCredential) => {
        // Signed in 
        console.log('Logged in user:',userCredential)
        //TODO: replace check if user docs exist function
        sharedlogic.checkIfUserDocsExist().then(msg => {
          // alert(`You are logged in as ${user.user.email}`);
          console.log(msg)
          this.$emit('logOn')
          this.$router.push('/');
        }).catch(err=>{
          console.error(err)
          alert('A problem occurred while trying to log you in. Please try again - if the problem persists, contact the administrator.')
        })
        
      },
      error => {
        alert(error.message);
      });
      e.preventDefault()
    },
    // login: function(e) {
    //   firebase
    //     .auth()
    //     .signInWithEmailAndPassword(this.email, this.password)
    //     .then(
    //       user => {
    //         console.log(user)
            
    //         //TODO: replace check if user docs exist function
    //         sharedlogic.checkIfUserDocsExist().then(msg => {
    //           // alert(`You are logged in as ${user.user.email}`);
    //           console.log(msg)
    //           this.$emit('logOn')
    //           this.$router.push('/');
    //         }).catch(err=>{
    //           console.error(err)
    //           alert('A problem occurred while trying to log you in. Please try again - if the problem persists, contact the administrator.')
    //         })
    //       },
    //       err => {
    //         alert(err.message);
    //       }
    //     );
    //   e.preventDefault();
    // }
  }
};
</script>

<style scoped>
.gromstyle{
    color: rgb(35,168,112);
}
</style>