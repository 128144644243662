<template>
    
    <div id ="view-recipe">
        <div v-if="recipe">
            <br>

            <!-- <div v-if="recipe.dishType" class="chip">{{recipe.dishType}}</div> -->

            <div v-if="wakelockSupported"  class="switch right">
                <label>
                Kookmodus
                <input @change="enableWakelock" v-model="cookModeEnabled" type="checkbox">
                <span class="lever"></span>
                
                </label>
            </div>
            
            <h3>{{recipe.recipeTitle}}</h3>
            <Rating class="gromstyle"
                :score="this.recipe.score"
                v-on:score="setScore"
            />
<!-- class="s12 m9 l6" -->
            <div v-if="recipe.pictures.dish" >
                <div v-if="recipe.pictures.dish.url">
                    <img class="maxwidth materialboxed" v-bind:src="recipe.pictures.dish.url" alt="Dish photo" >
                </div>
            </div>

            <a v-bind:href="recipe.urlRecipe"> {{recipe.urlRecipe}} </a>
            
            <p>{{recipe.recipeText}}</p>
            
            <!-- <div v-if="recipe.meatType" class="chip">{{recipe.meatType}}</div>
            <div v-if="recipe.carbType" class="chip">{{recipe.carbType}}</div> -->

            <div class="chip" v-for="tag in recipe.tags" v-bind:key="tag" >
                {{tag}}
            </div>
            
            <h4>Ingrediënten</h4>
            <ul class="collection">
                <li v-bind:key="record.ingredientName" v-for="record in recipe.ingredients"
                    class="collection-item"> 
                        <span v-if="record.category">{{record.category.icon}} </span>
                        <span>{{record.ingredientName}} </span>
                        <div class="light">{{record.quantity}}</div>
                        <!-- <span v-if="record.category">{{record.category.icon}}</span>
                        {{record.ingredientName}}
                        {{record.quantity}} -->
                        <!--{{record.unit}}  Deze bestaat helaas nog niet.-->
                    </li>

                
            </ul>
            
            <div v-if="recipe.pictures.recipe">
                <div v-if="recipe.pictures.recipe.url">
                    <h4>Recept foto</h4>
                    <img class="maxwidth materialboxed" v-bind:src="recipe.pictures.recipe.url" alt="Recipe photo" >
                </div>
            </div>
            

            <div v-if="recipe.pictures.diagram">
                <div v-if="recipe.pictures.diagram.url">
                    <h4>Recept diagram</h4>
                    <img class="maxwidth materialboxed" v-bind:src="recipe.pictures.diagram.url" alt="Diagram photo" >
                </div>
            </div>

            <h4>Planning</h4>
            <!--<label for="EatNext">Ingepland op</label>-->
            <input 
                type="text" 
                class="datepicker" 
                v-on:change="newDate" 
                placeholder="Voeg een datum toe om dit te eten" 
                v-model.lazy="eatNext">
            
            <ul class="collection">
                <li v-bind:key="day.index" v-for="day in recipe.eatDate"
                    class="collection-item"> 
                        {{friendlyDateFormat(day)}}
                        <i class ="fa fa-trash right gromstyle" v-on:click="deleteEatDate(day)"></i>
                    </li>

                
            </ul>

            
            
            <button v-on:click="addRecipeDirectToList" class="btn waves-effect waves-ripple">Voeg direct toe aan je lijst</button>
            <br>      
            <br>
            <button v-on:click="deleteRecipe" class="btn red">Verwijder</button>
            <div class="fixed-action-btn">
                <router-link v-bind:to="{name: 'edit-recipe', 
                    params:{recipe_id: recipe.recipeTitle}}" 
                    class = "btn-floating btn-large waves-ripple">
                        <i class ="fa fa-pencil"></i>
                </router-link>
            </div>

            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
    
</template>

<script>
import db from './firebaseInit'
import {doc, collection, updateDoc, deleteDoc} from 'firebase/firestore'
import {deleteObject, getStorage, ref} from 'firebase/storage'
// import firebase from 'firebase/compat/app'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import sharedlogic from '@/scripts/sharedlogic'
import Rating from './Rating.vue'
import NoSleep from 'nosleep.js';


export default {
    name: 'view-recipe',
    props: ['saveTrigger', 'recipes','user','list'],
    components:{
        Rating
    },
    data(){
        return{
            ingredients: [],
            recipe: null,
            eatNext: null,
            wakelockSupported: true,
            cookModeEnabled: false,
            wakeLock : null,
            noSleep:null
            // score : 2
        }
    },
    created(){
        console.log('View recipe - created')
        if (this.recipes.length>0){
            this.fetchData()
        }
        this.noSleep = new NoSleep();
        // if ('wakeLock' in navigator) {
        //     // isSupported = true;
        //     this.wakelockSupported = true;
        // } else {
        //     // wakeButton.disabled = true;
        //     this.wakelockSupported = false;
        // }
    },
    mounted(){
        // this.$nextTick().then(()=>{
            // var elemsMB = document.querySelectorAll('.materialboxed');
            // var ins = M.Materialbox.init(elemsMB, {});
            // console.log("Materialboxed instance", ins)
        // })


        this.initialiseMaterialize()
    },
    watch: {
        '$route':'fetchData',
        'recipes' (){
            this.fetchData()
            this.initialiseMaterialize()
        }
    },
    methods: {
        enableWakelock(){
            
            if (this.cookModeEnabled){
                // navigator.wakeLock.request('screen').then(
                //     (wakeLockSentinel)=>{
                //         console.log('Wake lock enabled')
                //         this.wakeLock = wakeLockSentinel
                //     },
                //     (err)=>{
                //     console.log('Failed to enable wake lock with error :', err) 
                //     this.cookModeEnabled = false
                //     }
                // )
                this.noSleep.enable()
                console.log('Cook mode enabled')
                

            } else {
                // if(this.wakeLock){
                //     this.wakeLock.release().then(()=>console.log('Wake lock released'))
                // }

                if (this.noSleep){
                    this.noSleep.disable()
                    console.log('Cook mode disabled')
                }
            }


            // } catch (err) {
            // // The Wake Lock request has failed - usually system related, such as battery.
            //     console.log('Failed to enable wake lock with error :', err)
            // }

        },
        initialiseMaterialize(){
            this.$nextTick().then(()=>{


                var elemsMB = document.querySelectorAll('.materialboxed');
                var ins = M.Materialbox.init(elemsMB, {});
                console.log("Materialboxed instance", ins)

                var elems = document.querySelectorAll('.datepicker');
                M.Datepicker.init(elems,{ 
                    format: 'yyyymmdd',
                    firstDay : 1,
                    autoClose: true,
                    showDaysInNextAndPreviousMonths: true,
                    // events:[new Date(2022,10,26).toDateString()],
                    i18n:{
                        cancel: 'Annuleer',
                        months:[
                            'Januari',
                            'Februari',
                            'Maart',
                            'April',
                            'Mei',
                            'Juni',
                            'Juli',
                            'Augustus',
                            'September',
                            'Oktober',
                            'November',
                            'December'
                        ],
                        monthsShort:[
                            'Jan',
                            'Feb',
                            'Maa',
                            'Apr',
                            'Mei',
                            'Jun',
                            'Jul',
                            'Aug',
                            'Sep',
                            'Okt',
                            'Nov',
                            'Dec'
                        ],
                        weekdays:[
                            'Zondag',
                            'Maandag',
                            'Dinsdag',
                            'Woensdag',
                            'Donderdag',
                            'Vrijdag',
                            'Zaterdag'
                        ],
                        weekdaysShort:[
                            'Zo',
                            'Ma',
                            'Di',
                            'Wo',
                            'Do',
                            'Vr',
                            'Za'
                        ],
                        weekdaysAbbrev:['Z','M','D','W','D','V','Z']
                    }
                });  
            })
        },
        friendlyDateFormat(day){
            const date = new Date(day.substring(0,4), day.substring(4,6)-1,day.substring(6,8))
            return date.toLocaleDateString("nl-NL", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        },
        fetchData () {
            console.log('ViewRecipe - FetchData')
            this.recipe = this.recipes.find(recipe => recipe.recipeTitle == this.$route.params.recipe_id)
        },
        addRecipeDirectToList(){
            this.recipe.ingredients.forEach(ingredient=>{
                var ingredientToAdd= {
                    recipeTitle : this.recipe.recipeTitle,
                    origin : {
                        text: this.recipe.recipeTitle,
                        type: 3, //0 = ad-hoc, //1 = inventory items, //2 = planned recipes //3 = direct to list
                        recipeTitle: this.recipe.recipeTitle,
                    },
                    renderId : sharedlogic.uid(this.list.items.map(item => item.renderId)),
                    name : ingredient.ingredientName,
                    category : ingredient.category,
                    //quantity: ingredient.quantity
                }
                if (ingredient.quantity){
                    ingredientToAdd.quantity= ingredient.quantity
                }

                this.list.items.push(ingredientToAdd)
            })
            this.$router.push('/list')

        },
        newDate(){
            console.log('New date!')
            console.log(this.eatNext)
            
            if (this.recipe.eatDate ==null){
                this.recipe.eatDate = []
            }
            
            this.recipe.eatDate.push(this.eatNext)
            this.eatNext =null

            const docRef = doc(collection(db,"Recipes"),this.recipe.id)
            updateDoc(docRef,{
                eatDate: this.recipe.eatDate                
            })
            
            // db.collection('Recipes').doc(this.recipe.id).update({
            //     eatDate: this.recipe.eatDate
            // })
            
            
        },
        setScore(score){
            console.log('View recipe - score')
            this.recipe.score = score

            const docRef = doc(collection(db,"Recipes"),this.recipe.id)
            updateDoc(docRef,{
                score: score
            })

            // db.collection('Recipes').doc(this.recipe.id).update({
            //     score: score
            // })
        },
        deleteEatDate(date){
            console.log('delete eat date')
            
            var indexToRemove = this.recipe.eatDate.indexOf(date)          
            this.recipe.eatDate.splice(indexToRemove,1)

            const docRef = doc(collection(db,"Recipes"),this.recipe.id)
            updateDoc(docRef,{
                eatDate: this.recipe.eatDate                
            })

            // db.collection('Recipes').doc(this.recipe.id).update({
            //     eatDate: this.recipe.eatDate
            // })
            
        },
        deletePhoto(photo){
            // var rStorage = firebase.storage();     
            var rStorage = getStorage()
            
            if(photo){
                if(photo.filename){
                    if (photo.dontDelete){
                        console.log('Picture not deleted (new user content).')
                    } else {
                        console.log('About to delete : '+ photo.filename)
                        const refD = ref(rStorage,photo.filename)
                        deleteObject(refD)
                        // rStorage.ref(photo.filename).delete()
                        .then(()=>{
                            console.log('Delete successful.')
                        })
                    }
                    
                }
            }
        },
        deleteRecipe() {
            if(confirm('Weet je het zeker?')){

                this.deletePhoto(this.recipe.pictures.dish)
                this.deletePhoto(this.recipe.pictures.recipe)
                this.deletePhoto(this.recipe.pictures.diagram)

                const docRef = doc(collection(db,"Recipes"),this.recipe.id)
                deleteDoc(docRef).then(this.$router.push('/'))
                // db.collection('Recipes').doc(this.recipe.id).delete().then(this.$router.push('/'))
            }
        }
    }
}
</script>

<style scoped>

p {
 white-space: pre-wrap;       /* css-3 */
 white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
 white-space: -pre-wrap;      /* Opera 4-6 */
 white-space: -o-pre-wrap;    /* Opera 7 */
 word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.fa { line-height: inherit;}

.light{
    color:grey
}

.maxwidth {
    width: 100%;
    max-width: 450px;   
}

</style>