<template>   
    <div id ="testing-grounds"> 
        <div v-if="user">
            
            <div v-if="!user.alpha" class='card-panel red lighten-4'>
                This page is only accessible for administrators.
            </div>
            <div v-else>

                <button v-on:click="testButton1" class="btn waves-effect waves-ripple">Test 1</button>                
                <button v-on:click="loadData" class="btn waves-effect waves-ripple">Load data</button>                
                <button v-on:click="testButton2" class="btn waves-effect waves-ripple">Test 2</button>  
                <br>
                <br>


                <div class="preloader-wrapper big active">
                    <div class="spinner-layer spinner-green-only">
                        <div class="circle-clipper left">
                            <div class="circle"></div>
                        </div>
                        <div class="gap-patch">
                            <div class="circle"></div>
                        </div>
                        <div class="circle-clipper right">
                            <div class="circle"></div>
                        </div>
                    </div>
                </div>


                
                <div class="row">
                    <div class="input-field col s12">
                        <i class="material-icons prefix gromstyle-light">search</i>
                        <input id="icon_prefix" type="text" class="validate" v-model="search">
                        <label for="icon_prefix" class="active">Search at ah</label>
                    </div>
                </div>

                <!-- <img v-if="firstProduct" :src="firstProduct.img.url"> -->

                
                <ul class="collection">
                    <li class="collection-item avatar">
                    <!-- <img src="images/yuna.jpg" alt="" class="circle"> -->
                    <img v-if="firstProduct" :src="firstProduct.img.url" class="circle">
                    <span class="title">Title</span>
                    <p>First Line <br>
                        Second Line
                    </p>
                    <a href="#!" class="secondary-content"><i class="material-icons">grade</i></a>
                    </li>
                </ul>
                
                

                <div class="row" >
                    <div class="col s12 l6 xl3" v-for="product in products.slice(0,4)" v-bind:key="product.webshopId">
                        <!-- <img  :src="product.images.filter(img => img.width==200)[0].url" > -->
                        <product 
                            :imgUrl="product.images.filter(img => img.width==200)[0].url"
                            :description="product.title"
                            :price="product.priceBeforeBonus"
                            :amount="product.salesUnitSize"
                        />
                    </div>
                </div>

                <quantity-picker />


                <tag-select
                    :household="household"
                    :allTags="allTags"
                />


                <br>
                <br>
                <h4>Long term test</h4>
                <!-- Keep this section. Usefull tools in future. -->
                <h5>External link behavior</h5>
                <p>Testscenarios</p>
                <ul>
                    <li> Simple click in mobile website.</li>
                    <li> Simple click in mobile installed app.</li>
                    <li> Simple click in desktop website.</li>
                    <li> Simple click in desktop installed app</li>
                    <li> CTRL click in desktop website.</li>
                    <li> CTRL click in desktop installed app</li>
                </ul>
                <h6> AH - add to basket link </h6>
                <a  v-bind:href="'https://www.ah.nl/mijnlijst/add-multiple?p=415088:1&p=547711:2'" > 
                    AH - Koopknop
                </a>
                <h6> Jumbo - direct link </h6>
                <a  v-bind:href="jumboSearchPrefix+'Brood'" > 
                    Jumbo - Target default
                </a>
                <br><br>
                <a  v-bind:href="jumboSearchPrefix+'Brood'" target="_self"> 
                    Jumbo - Target _self
                </a>
                <br><br>
                <a  v-bind:href="jumboSearchPrefix+'Brood'" target="_blank"> 
                    Jumbo - Target _blank
                </a>
                <br><br>
                <a  v-bind:href="jumboSearchPrefix+'Brood'" target="_parent"> 
                    Jumbo - Target _parent
                </a>                                
                <br><br>
                <a  v-bind:href="jumboSearchPrefix+'Brood'" target="_top"> 
                    Jumbo - Target _top
                </a>

                <h6> Coop - affiliate link </h6>
                <a  v-bind:href="coopSearchPrefix+'Brood'" rel="sponsored"> 
                    Coop - Target default
                </a>
                <br><br>
                <a  v-bind:href="coopSearchPrefix+'Brood'" target="_self" rel="sponsored"> 
                    Coop - Target _self
                </a>
                <br><br>
                <a  v-bind:href="coopSearchPrefix+'Brood'" target="_blank" rel="sponsored"> 
                    Coop - Target _blank
                </a>
                <br><br>
                <a  v-bind:href="coopSearchPrefix+'Brood'" target="_parent" rel="sponsored"> 
                    Coop - Target _parent
                </a>                                
                <br><br>
                <a  v-bind:href="coopSearchPrefix+'Brood'" target="_top" rel="sponsored"> 
                    Coop - Target _top
                </a>                                                
                <h6> Butlon - affiliate link </h6>
                <a  v-bind:href="butlonSearchPrefix+'Brood'" rel="sponsored"> 
                    Butlon - Target default
                </a>

            </div>
        </div>

    </div>      
</template>   

<script>
import db from './firebaseInit'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import {  doc, updateDoc } from "firebase/firestore";
// import { collection, query,  getDocs, limit, doc, updateDoc } from "firebase/firestore";
import TagSelect from './TagSelect.vue'
import Product from './Product.vue'
import QuantityPicker from './QuantityPicker.vue';
// import Rating from './Rating.vue'
// import sharedlogic from '../scripts/sharedlogic'
// import firebase from 'firebase';
// import Router from 'vue-router'

export default {
    name: 'testing-grounds',
    props: ['recipes','noteList','list', 'user', 'household','allTags'],
    data(){
        return{
            search:'Brood',
            products:[],
            firstProduct:null,
            items: [
                {
                    id: 0,
                    title: 'Item A',
                    list: 1,
                },
                {
                    id: 1,
                    title: 'Item B',
                    list: 1,
                },
                {
                    id: 2,
                    title: 'Item C',
                    list: 2,
                },
            ],
            jumboSearchPrefix : "https://www.jumbo.com/zoeken?searchTerms=",
            ahSearchPrefix : "https://www.ah.nl/zoeken?query=",
            coopSearchPrefix: "https://www.awin1.com/cread.php?awinmid=12871&awinaffid=1127067&clickref=testinggroundUser&clickref2=testinggroundsUser&ued=https%3A%2F%2Fwww.coop.nl%2Fzoeken%2F",
            butlonSearchPrefix: "https://www.awin1.com/cread.php?awinmid=22413&awinaffid=1127067&clickref=testinggroundUser&clickref2=testinggroundsUser&ued=https%3A%2F%2Fwww.coop.nl%2Fzoeken%2F",
            //recipes:[],
            ingredients:[],
            ingredientMasterData:[],
            categoryMasterData:[],
            date: null,
            recipesFiltered:[],
            item : {
                name:"Ontbijtkoek",
                renderId:34282,
                category:{
                    icon:"🍞",
                    categoryName:"Brood",
                    supermarketOrder:30
                },
                done:false
            },
            frequency: null,
            frequencyNumber: null,
            liveDoc: null,
            liveCollection: [],
            notes: [],
            inventory:[],
            wakelockSupported: null
            
        }
    },
    components: {
        TagSelect,
        Product,
        QuantityPicker
    },
    created(){

        if ('wakeLock' in navigator) {
            // isSupported = true;
            this.wakelockSupported = 'Screen Wake Lock API supported!';
        } else {
            // wakeButton.disabled = true;
            this.wakelockSupported = 'Wake lock is not supported by this browser.';
        }

        
    },
    mounted(){
        console.log('mounted')

            var elemsMB = document.querySelectorAll('.materialboxed');
            var ins = M.Materialbox.init(elemsMB, {});
            console.log("Materialboxed instance", ins)

            var elems = document.querySelectorAll('.dropdown-trigger');
            var dropdown = M.Dropdown.init(elems, {
            // specify options here
            });
            console.log('Dropdown' , dropdown)


            var elemsC = document.querySelectorAll('.chips');
            var instances = M.Chips.init(elemsC, {
            // specify options here
            });
            console.log(instances)

    },
    computed: {
        listOne() {
            return this.items.filter((item) => item.list === 1)
        },
        listTwo() {
            return this.items.filter((item) => item.list === 2)
        },
    },
    methods : {
        startDrag(evt, item) {
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move'
        evt.dataTransfer.setData('itemID', item.id)
        },
        onDrop(evt, list) {
        const itemID = evt.dataTransfer.getData('itemID')
        const item = this.items.find((item) => item.id == itemID)
        item.list = list
        },
        testButton1(){

            console.log('Test 1')
            const docRef = doc(db, "Household", "he73bqhMvGs4tpUoyzla");
            updateDoc(docRef, {
                tagSetup: [
                    {
                        name: 'Koolhydraat type',
                        tags:[
                            'Aardappel',
                            'Rijst',
                            'Pasta',
                            'Deeg',
                            'Bami/Noodles'
                        ]
                    },
                    {
                        name: 'Vlees/Vis/Vega',
                        tags:[
                            'Vlees',
                            'Vis',
                            'Vega'
                        ]
                    },
                    {
                        name:'Soort gerecht',
                        tags:[
                            'Volledig gerecht',
                            'Vlees/Vis speciaal',
                            'Vlees/vis',
                            'Groente speciaal',
                            'Groente',
                            'Koolhydraat speciaal',
                            'Koolhydraat',
                            'Desert/Bakken'
                        ]
                    }
                ]
            });

            

        },
        loadData(){
            // this.ingredientMasterData = []
            // db.collection('IngredientMasterData').get().then(querySnapshot => {
            //     querySnapshot.forEach(imd=>{
            //         this.ingredientMasterData.push(imd.data())
            //     })
            //     console.log('Done loading Ingredient Master Data')
            // })

        },
        testButton2(){
            console.log('test button 2')

            fetch(`http://localhost:8081/search?query=${this.search}`)
                .then(res=>res.text())
                .then(res=>{
                    var response = JSON.parse(res)
                    this.products = response.products
                    this.firstProduct=this.products[0]
                    this.firstProduct.img = this.firstProduct.images.filter(img=>img.width==800)[0]
                })

        
        }
    }
}
</script>

<style scoped>
.drop-zone {
  background-color: #eee;
  margin-bottom: 10px;
  padding: 10px;
}

.drag-el {
  background-color: #fff;
  margin-bottom: 10px;
  padding: 5px;
}
</style>

