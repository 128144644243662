import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from '@/components/Dashboard'
//import NewRecipe from '@/components/NewRecipex'
import ViewRecipe from '@/components/ViewRecipe'
import EditRecipe from '@/components/EditRecipex'
import Login from '@/components/Login'
import Register from '@/components/Register'
import Inspiration from '@/components/Inspiration'
import BulkAdd from '@/components/BulkAdd'
import BulkExport from '@/components/BulkExport'
import List from '@/components/List'
import Calendar from '@/components/Calendar'
import Inventory from '@/components/Inventory'
import TagSetup from '@/components/TagSetup'
// import EditInventory from '@/components/EditInventory'
import Settings from '@/components/Settings'
import Profile from '@/components/Profile'
import Household from '@/components/Household'
import Home from '@/components/Home'
import IngredientList from '@/components/IngredientList'
import OrphanRecords from '@/components/OrphanRecords'
// import Compile from '@/components/Compile'
import TestingGrounds from '@/components/TestingGrounds'
// import firebase from 'firebase/compat/app'
import {getAuth} from 'firebase/auth'


Vue.use(Router)

let router = new Router({
    //scrollBehavior (to, from, savedPosition) {
    scrollBehavior () {
        return { x: 0, y: 0 }
      },
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/selectRecipeToEatOn/:date?',
            name: 'selectRecipeToEatOn',
            component: Dashboard,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                requiresGuest: true
            }
        },
        {
            path: '/register',
            name: 'register',
            component: Register,
            meta: {
                requiresGuest: true
            }
        },
        /*
        {
            path: '/new',
            name: 'edit-recipe',
            component: EditRecipe,
            meta: {
                requiresAuth: true
            }
        },
        
        {
            path: '/new',
            name: 'new-recipe',
            component: NewRecipe,
            meta: {
                requiresAuth: true
            }
        },
        */
        {
            path: '/bulk_add',
            name: 'bulk-add',
            component: BulkAdd,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/bulk_export',
            name: 'bulk-export',
            component: BulkExport,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/testing_grounds',
            name: 'testing-grounds',
            component: TestingGrounds,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/calendar/:date?',
            name: 'calendar-page',
            component: Calendar,
            meta: {
                requiresAuth: true
            }
        },
        // {
        //     path: '/compile',
        //     name: 'compile',
        //     component: Compile,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
        {
            path: '/inventory',
            name: 'inventory',
            component: Inventory,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/tag-setup',
            name: 'tag-setup',
            component: TagSetup,
            meta: {
                requiresAuth: true
            }
        },
        // {
        //     path: '/inventory/:inv_id',
        //     name: 'edit-inventory',
        //     component: EditInventory,
        //     meta: {
        //         requiresAuth: true
        //     }
        // },
        {
            path: '/view-recipe/:recipe_id',
            name: 'view-recipe',
            component: ViewRecipe,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/edit-recipe/:recipe_id',
            name: 'edit-recipe',
            component: EditRecipe,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/list',
            name: 'list',
            component: List,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/inspiration',
            name: 'inspiration',
            component: Inspiration,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Profile,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/household',
            name: 'household',
            component: Household,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/ingredientlist',
            name: 'ingredientlist',
            component: IngredientList,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/orphanrecords',
            name: 'orphanrecords',
            component: OrphanRecords,
            meta: {
                requiresAuth: true
            }
        },        
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                requiresAuth: false
            }
        }

    ]
});


//Nav guards

router.beforeEach((to, from, next)=>{
    //check for requiredAuth guard
    if(to.matched.some(record => record.meta.requiresAuth)) {
        //Check if NOT logged in
        // if(!firebase.auth().currentUser) {
        if(!getAuth().currentUser) {
            //Go to login
            next({
                path: '/home',
                query : {
                    redirect: to.fullPath
                }
            })
        } else {
            //Proceed to route
            next()
        }
    } else if (to.matched.some(record=> record.meta.requiresGuest)){
        //Check if  logged in
        if(getAuth().currentUser) {
            //Go to login
            next({
                path: '/',
                query : {
                    redirect: to.fullPath
                }
            })
        } else {
            //Proceed to route
            next()
        }
    } else {
        //Proceed to route
            next()
    }
})

export default router