<template>
    <div id ="dashboard">
        
        <!--<button v-if="selectMode" @click="$router.go(-1)" class= "btn grey">Back</button>-->
        <h4 v-if="!selectMode">Recepten
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip="Dit is je recepten database. Je kunt filteren of zoeken om snel een recept te vinden. Wist je dat je ook kunt zoeken in de ingrediënten van je recepten?"
            >info_outline
            </i>
        </h4>
        <h4 v-if="selectMode">Wat wil je eten op {{datePretty}}?
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip="Selecteer een recept om te eten. In plaats van dat het recept opent, wordt het ingepland op de door jou geselecteerde dag."
            >info_outline
            </i>
        </h4>
        <div class="input-field">
            <input id="search" type="text" autocomplete="off" v-model="searchTerm" v-on:input="search">
            <label for="search">Zoeken... </label>            
        </div>
        <ul class="collapsible">
            <li>
                <div class="collapsible-header">
                    <i class="material-icons">filter_list</i>Filters
                </div>
                <div class="collapsible-body">
                    <!-- <span>Lorem ipsum dolor sit amet.</span> -->
                    <div :class="{chip : true, pointer : true, 'selected-chip': filterNIAWActive}" 
                        v-on:click="filterNIAW">
                        Tijdje niet gegeten
                    </div>
                    <!-- <div :class="{chip : true, pointer : true, 'selected-chip': filterDishTypeActive}" 
                        v-on:click="filterDishType">
                        Volledig gerecht
                    </div> -->
                    <!-- <div :class="{chip : true, pointer : true, 'selected-chip': filterMeatTypeActive}" 
                        v-on:click="filterMeatType">
                        Vega
                    </div> -->
                    <div :class="{chip : true, pointer : true, 'selected-chip': filter4StarsActive}"
                        v-on:click="filter4Stars">
                        4
                        <i class ="fa fa-star"></i>
                    </div>
                    <div :class="{chip : true, pointer : true, 'selected-chip': filter5StarsActive}"
                        v-on:click="filter5Stars">
                        5
                        <i class ="fa fa-star"></i>
                    </div>
                    <div :class="{chip : true, pointer : true, 'selected-chip': filterNoScoreActive}"
                        v-on:click="filterNoScore">
                        Geen beoordeling
                    </div>
                    <div :class="{chip : true, pointer : true, 'selected-chip': filterEatenMaxOnceActive}"
                        v-on:click="filterEatenMaxOnce">
                        Max 1 keer gegeten
                    </div>
                    
                    <tag-select
                        :household="household"
                        :allTags="allTags"
                        v-on:TagEvent="filterOnTag"
                    />      
                </div>
            </li>
        </ul>
        <div class= "bpadding">
            <ul class="collection " v-if="recipesFiltered.length !=0 ">
                <li v-if="!selectMode">
                    <router-link   v-for="recipe in recipesFiltered"
                        v-bind:key="recipe.id" class="collection-item truncate" 
                        v-bind:to="{name: 'view-recipe', params: {recipe_id: recipe.recipeTitle}}">
                        
                        <span class="normal">{{recipe.recipeTitle}}</span> 
                        <br>
                        <span class="light">{{commaSeparatedTags(recipe)}}</span>
                        <!-- <span class="light">{{recipe.dishType}}</span>
                        <span v-if="recipe.meatType" class="light">, {{recipe.meatType}}</span> -->
                    
                
                    </router-link> 
                </li>
                <li v-if="selectMode">
                    <a href="#!"   v-for="recipe in recipesFiltered"
                        v-bind:key="recipe.id" class="collection-item  truncate" 
                        v-bind:to="{name: 'view-recipe', params: {recipe_id: recipe.recipeTitle}}"
                        v-on:click="selectRecipe(recipe)">
                        
                        <span class="normal">{{recipe.recipeTitle}}</span> 
                        <br>
                        <span class="light">{{commaSeparatedTags(recipe)}}</span>
                        <!-- <span class="light">{{recipe.dishType}}</span>
                        <span v-if="recipe.meatType" class="light">, {{recipe.meatType}}</span> -->
                    </a> 
                </li>
            </ul>
            
            <spinner class = "center-align" v-if="!recipesLoaded"/>

            <div class = "collection center-align " v-if="recipes.length==0 && recipesLoaded">    
                <br>
                <i class ="material-icons">inbox</i>
                <div class="light">Je hebt nog geen recepten.</div>
                <router-link to="/edit-recipe/new" class = "btn waves-effect waves-ripple quickAddPad">
                    Nieuw recept
                    <i class ="fa fa-plus"></i>
                </router-link>  
                <br>
            </div>

            <div class = "collection center-align " v-if="recipesFiltered.length==0 && recipes.length!=0">    
                <br>
                <i class ="material-icons">filter_list</i>
                <div class="light">Geen resultaten. Pas je filters aan.</div>
                <br>
            </div>
        </div>

        <div class="fixed-action-btn ">
            <router-link to="/edit-recipe/new" class = "btn-floating btn-large waves-ripple">
                <i class ="fa fa-plus"></i>
            </router-link>
        </div>
        <br>
        <br>
        <br>
        <br>
    </div>
</template>

<script>
import db from './firebaseInit'
import{doc, updateDoc} from "firebase/firestore"

import sharedlogic from '@/scripts/sharedlogic'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import TagSelect from './TagSelect.vue'
import Spinner from './Spinner.vue'


export default {
    name: 'dashboard',
    props: ['recipes','household','allTags', 'recipesLoaded'],
    data() {
        return {
            //recipes: [] ,  
            recipesFiltered: [] , 
            searchTerm: null,
            selectMode: false,
            date: null,
            datePretty: null,
            filterNIAWActive :false,
            filter4StarsActive: false,
            filter5StarsActive: false,
            filterScore:[],
            filterDishTypeActive : false,
            filterMeatTypeActive : false,
            filterNoScoreActive : false,
            filterEatenMaxOnceActive : false
        }
    },
    components: {
        TagSelect,
        Spinner
    },
    created() {
        //console.log('Created - Dashboard')
        
        if(this.$route.fullPath.startsWith('/selectRecipeToEatOn')){
            this.selectMode = true
            this.date = this.$route.params.date
            //console.log(this.$route)

            var dd = this.date.substring(6,8)
            var mm = this.date.substring(4,6)
            var yyyy = this.date.substring(0,4)
            console.log(dd)
            console.log(mm)
            console.log(yyyy)

            var date = new Date(yyyy,mm-1,dd)

            var dayOfWeekInt = date.getDay()
            var dayOfWeekString = sharedlogic.dayOfWeekString(dayOfWeekInt).toLowerCase()
            
            this.datePretty = dayOfWeekString + ' ' + dd + '/' + mm  + '/' + yyyy;
        }

        this.search()
     
    },
    mounted(){
        var elems = document.querySelectorAll('.collapsible');
        M.Collapsible.init(elems, {});

        var elemsT = document.querySelectorAll('.tooltipped');
        M.Tooltip.init(elemsT, {});
    },
    watch: {
        'recipes':'search'
    },
    methods:{
        commaSeparatedTags(recipe){
            var result = ''
            recipe.tags.forEach((tag,index)=>{
                result = result+tag
                if (index<recipe.tags.length-1){
                    result = result + ', '
                }
            })
            return result
        },
        filterOnTag(selectedTags){
            var selectedTagsInGroups = []

            this.household.tagSetup.forEach(tG=>{
                var activeTagsInTG = selectedTags.filter(tag=>tG.tags.includes(tag))
                if (activeTagsInTG.length>0){
                    selectedTagsInGroups.push({
                        name : tG.name,
                        tags : activeTagsInTG
                    })
                }
            })
            console.log('Selected tags in groups', selectedTagsInGroups)
            this.filter(selectedTags,selectedTagsInGroups)
        },
        search(){
            console.log('Dashboard / search')
            //console.log(this.searchTerm)
            this.filter()
        },
        compare(fieldToSearchIn , searchTerm){
            //Function that returns true when searchTerm is a part of the fieldToSearchIn. Assumed both inputs are strings. Case insensitive.
            
            if (fieldToSearchIn != "" && fieldToSearchIn != null && searchTerm != "" && searchTerm != null){
                if(fieldToSearchIn.toLowerCase().includes(searchTerm.toLowerCase())){
                    return true
                }
            }
            return false
        },
        selectRecipe(recipe){
            console.log('Select recipe')
            //console.log(recipe)

            if (recipe.eatDate ==null){
                recipe.eatDate = []
            }
            
            recipe.eatDate.push(this.date)
            this.date =null
            //console.log(recipe.id)
            
            console.log("About to update eatdate on recipe : ", recipe )

            const recipeRef = doc(db, "Recipes", recipe.id)
            updateDoc(recipeRef,{
                eatDate: recipe.eatDate
            }).then((msg)=>{
                console.log("Added new eatdate on recipe succesfully with message : ", msg)
                this.$router.push({ name:'calendar-page'})
            }).catch(error=>{
                console.error("Error while adding eatdate to recipe : ", error)
            })

            // db.collection('Recipes').doc(recipe.id).update({
            //     eatDate: recipe.eatDate
            // }).then((msg)=>{
            //     console.log("Added new eatdate on recipe succesfully with message : ", msg)
            //     this.$router.push({ name:'calendar'})
            // }).catch(error=>{
            //     console.error("Error while adding eatdate to recipe : ", error)
            // })
            
        },
        filter(selectedTags, selectedTagsInGroups){
            console.log('Selected tags',selectedTags)
            selectedTags = selectedTags ? selectedTags : []

            
            var ingrPresent = []
            this.recipesFiltered = this.recipes.filter(recipe=>{
                var ret = false

                if (this.searchTerm){
                    
                    if(this.compare(recipe.recipeTitle,this.searchTerm)){ret = true}

                    //ingredients
                    ingrPresent = recipe.ingredients.filter(ingr => {
                        if(this.compare(ingr.ingredientName,this.searchTerm)){ret = true}
                    })
                    if( ingrPresent.length>0){
                        ret = true
                    }
                } else {
                    ret = true
                }

                if(this.filterScore.length>0){
                    var scoreOK = this.filterScore.includes(recipe.score)
                    scoreOK && ret ? ret = true : ret = false
                }

                if(this.filterNIAWActive){
                    recipe.dueToEatFactor >=1 && ret ? ret = true : ret = false
                }

                if(this.filterDishTypeActive){
                    recipe.dishType == "Volledig gerecht" && ret ? ret = true : ret = false
                }

                if(this.filterMeatTypeActive){
                    recipe.meatType == "Vega" && ret ? ret = true : ret = false
                }

                if(this.filterNoScoreActive){
                    !recipe.score && ret ? ret = true : ret = false
                }

                if(this.filterEatenMaxOnceActive){
                    if(!recipe.eatDate){
                        ret ? ret = true : ret = false
                    } else {
                        recipe.eatDate.length <=1 && ret ? ret = true : ret = false 
                    }
                }

                if(selectedTags.length>0){
                    var tagOverlap = recipe.tags.some(tag=>{
                            return selectedTags.includes(tag)
                        }
                    ) 
                

                    // console.log('TAGS', selectedTags, recipe.tags, tagOverlap, ret)
                    
                    if (tagOverlap){
                        ret ? ret = true : ret = false 
                    } else {
                        ret = false
                    }
                }
                // console.log(ret)
                
                if(selectedTags.length>0){
                    var noGroups = 0
                    selectedTagsInGroups.forEach(tG=>{
                        var tagOverlap = recipe.tags.some(tag=>{
                                return tG.tags.includes(tag)
                            }
                        ) 
                        if (tagOverlap){
                            noGroups++
                            ret ? ret = true : ret = false 
                        }
                    })
                    if (noGroups<selectedTagsInGroups.length){ //At least 1 hit in every group
                        ret=false
                    }
                }



                return ret
            })
        },
        filterNIAW(){
            console.log('Dashboard - not in a while')
            this.filterNIAWActive=!this.filterNIAWActive
            this.filter()
        },
        filter4Stars(){
            console.log('Dashboard - filter4StarsActive')
            this.filter4StarsActive=!this.filter4StarsActive
            this.filter4StarsActive ? this.filterScore.push(4) : this.filterScore = this.filterScore.filter(score => score == 4 ? false : true)
            this.filter()
            // this.recipesFiltered = this.recipes.filter(recipe => this.filter4StarsActive ? recipe.score == 4 : true)
        },
        filter5Stars(){
            console.log('Dashboard - filter4StarsActive')
            this.filter5StarsActive=!this.filter5StarsActive
            this.filter5StarsActive ? this.filterScore.push(5) : this.filterScore = this.filterScore.filter(score => score == 5 ? false : true)
            this.filter()
            // this.recipesFiltered = this.recipes.filter(recipe => this.filter5StarsActive ? recipe.score == 5 : true)
        },
        filterDishType(){
            this.filterDishTypeActive = !this.filterDishTypeActive
            this.filter()
        },
        filterMeatType(){
            this.filterMeatTypeActive = !this.filterMeatTypeActive
            this.filter()
        },
        filterNoScore(){
            this.filterNoScoreActive = !this.filterNoScoreActive
            this.filter()
        },
        filterEatenMaxOnce(){
            this.filterEatenMaxOnceActive = !this.filterEatenMaxOnceActive
            this.filter()
        }
    }
}
    
</script>

<style scoped>
.extra-padding{
    padding-left : 12px
}
.normal{
    color:black
}

.light{
    color:grey
}

.selected-chip{
    color: white;
    background-color: rgb(35,168,112);
}

.pointer{
    cursor: pointer;
}
.material-icons{
    vertical-align: middle;   
}
.quickAddPad{
    margin-top: 20px;
    margin-bottom: 20px
}

.bpadding{
    padding-bottom: 50vh
}


</style>