<template>
    <div id="TagSetup">
        <h4 >Tags beheren 
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip=" Op deze pagina groepeer je je favoriete tags zodat je die nog makkelijker kunt toepassen op recepten en kunt terugvinden via de filter opties."
            >info_outline
            </i>
        </h4>  

        <div class="row">
            <div class="col s10">    
                <div class="input-field">
                    <input 
                        id="newItemInput" 
                        autocomplete="off"
                        type="text" 
                        v-model="newTagGroup" 
                        v-on:keyup.enter="addTagGroup()">
                    <label for="newItemInput">Nieuwe groep...</label>            
                </div>
            </div>
            <div class="col s2">    
                <button v-on:click="addTagGroup()" class="btn waves-effect waves-ripple btnpad">
                    <i class="material-icons">add</i>
                </button>    
            </div>
        </div>

        <div v-for="(tagGroup,index) in tagSetup" :key="tagGroup.name">
            <h6>
                {{tagGroup.name}}
                <i v-if="!tagGroup.remainingTags" class ="fa fa-trash right gromstyle" v-on:click="deleteTagGroup(tagGroup.name)"></i>
            </h6>
            <div :id="index" class="chips"></div>
        </div>
        <h6 class="padbelow"
            v-if="remainingTags.length>0"
            >
            Tags niet ingedeeld in een groep
            <i 
                class="material-icons tooltipped gromstyle-light" 
                data-position="bottom" 
                data-tooltip="Hier staan de tags die in gebruik zijn op je recepten maar niet zijn ingedeeld in een groep."
            >info_outline
            </i>
        </h6>

        <div class='chip' v-for="tag in remainingTags" :key="tag">{{tag}}</div>

    </div>
</template>

<script>
import M from '@materializecss/materialize/dist/js/materialize.min.js'
import {doc, updateDoc} from 'firebase/firestore'
import db from './firebaseInit'

export default {
    name: 'tag-setup',
    props: ['allTags','user','household'],
    data(){
        return{
            newTagGroup:null,
            chipsInstances : [],
            lastSelected : null

        }
    },
    watch:{
        household: function(){this.$nextTick(() => {this.initialiseChipsElements()})},
        allTags: function(){this.$nextTick(() => {this.initialiseChipsElements()})}      
    },
    computed:{
        remainingTags(){
            return this.allTags.filter(tag=>![].concat(...this.tagSetup.map(tS=>tS.tags)).includes(tag))
        },
        tagSetup(){
            if (this.household){
                return this.household.tagSetup ? this.household.tagSetup : []
            } else {
                return []
            }
        },
    },
    mounted(){
        console.log('Mounted - Tag Setup')

        var elems = document.querySelectorAll('.tooltipped');
        console.log(elems)
        M.Tooltip.init(elems, {});  

        this.initialiseChipsElements()

    },
    methods:{

        newTagSetup(lastAddedChip){
            console.log('New tag setup start', lastAddedChip)
            // console.log('CHIPS INSTANCES', this.chipsInstances)
            // console.log('Last added chip',lastAddedChip)
            // console.log('TAG SETUP', this.tagSetup)
            var newTagSetup=[]
            
            this.tagSetup.forEach((tS,index)=>{
                newTagSetup.push({
                    name:tS.name,
                    tags: this.chipsInstances[index].chipsData.map(cD=>cD.tag),
                    ...(tS.remainingTags && {remainingTags: true})
                })
            })
            console.log('NEW TAG SETUP RESULT', newTagSetup)
            return newTagSetup.filter(tS=>!tS.remainingTags)
        },
        initialiseChipsElements(){
            console.log('Initialise chips elements')
            this.chipsInstances.forEach(chipsInstance=>chipsInstance.destroy())
            this.chipsInstances=[]
            
            var elemsC = document.querySelectorAll('.chips');
            
            elemsC.forEach(elem=>{
                var chipsData = []
                this.tagSetup[elem.id].tags.forEach(tag=>{
                    chipsData.push({tag : tag})
                })

                this.chipsInstances.push(
                    M.Chips.init(elem, {
                        placeholder: 'Tags...',
                        secondaryPlaceholder: '+ Tag',
                        data : chipsData,
                        onChipDelete:(chips,chip)=>{
                            console.log('On delete',chips,chip)
                            
                            this.saveTagSetup(this.newTagSetup())

                            this.lastSelected= chips[0].id
                        },
                        onChipAdd:(chips,chip)=>{
                            console.log('On add',chips,chip)
                            var currentChipsInstance = chips[0].M_Chips
                            var chipsData = currentChipsInstance.chipsData
                            var lastAddedChip = chipsData[chipsData.length - 1].tag
                            console.log('Chip added:', lastAddedChip)
                            
                            var persistNewTag = true
                            this.chipsInstances.forEach(cI=>{
                                // console.log("CURRENT CI?",cI==chips[0].M_Chips)
                                if (cI!=currentChipsInstance){
                                    if (cI.chipsData.map(cD=>cD.tag).includes(lastAddedChip)){
                                        console.log("The added tag already exists in another tag group")
                                        persistNewTag = false
                                        M.toast({text: '❌ Deze tag bestaat al in een andere groep.'})
                                    }
                                }
                            })
                        
                            if (persistNewTag){
                                this.saveTagSetup(this.newTagSetup(lastAddedChip))
                            } else {
                                this.initialiseChipsElements()
                            }
                            
                            this.lastSelected= chips[0].id
                        }
                    })
                )

            })

            // console.log('ELEMSC', elemsC, this.lastSelected)
            if (elemsC.length>this.lastSelected && this.lastSelected){
                elemsC[this.lastSelected].lastChild.focus()
            }

        },
        addTagGroup(){
            console.log('New tag group', this.newTagGroup)

            if (!this.newTagGroup){
                console.error('No name specified, aborting...')
                return
            }

            if (this.tagSetup.map(tagGroup=>tagGroup.name==this.newTagGroup).includes(true)){
                console.error('Tag group name already exists, aborting...')
                return
            }

            const docRef = doc(db, "Household", this.user.household)
            updateDoc(docRef,{
                tagSetup : [{
                    name:this.newTagGroup,
                    tags:[]
                },...this.tagSetup]
            })

            this.newTagGroup=null            
        },
        deleteTagGroup(tagGroup){
            console.log('Delete tag group', tagGroup, this.newTagSetup().filter(tS=>tS.name!=tagGroup)) 
            this.lastSelected = null
            this.saveTagSetup(this.newTagSetup().filter(tS=>tS.name!=tagGroup))           
        },
        saveTagSetup(newTagSetup){
            console.log('Save tag setup')
            this.$nextTick(() => {
                const docRef = doc(db, "Household", this.user.household)
                updateDoc(docRef,{
                    tagSetup : newTagSetup
                })
            })
        }
    }
}

</script>

<style scoped>

.btnpad{
    margin-top: 20px;
}

.material-icons{
    vertical-align: middle;   
}

.padbelow{
    padding-bottom: 8px;
}

</style>