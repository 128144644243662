<template>
  <span id='QuantityPicker'>
    <span class=" quantity-picker" v-if="!quantityFixed">
        <!-- <button class="btn-minus " @click="decrement">-</button> -->
        
        <a  class="btn-floating btn-small scale-transition " :class="{'scale-out': quantity==0}" @click="decrement"><i class="material-icons">horizontal_rule</i></a>
        <!-- <a v-if="quantity>0" class="btn-floating btn-small" @click="decrement"><i class="material-icons">horizontal_rule</i></a> -->
        <div  class="input-field inputNumber scale-transition " :class="{'scale-out': quantity==0}">
            <!-- <input type="number" class=" validate" v-model.number="quantity" min = "0"> -->
            <input type="number" class=" validate" :value="quantity" @input="$emit('quantityChange', parseInt($event.target.value))" min = 0>
        </div>
        <a class="btn-floating btn-small" @click="increment"><i class="material-icons">add</i></a>
        <!-- <button class="btn-plus " @click="increment">+</button> -->
        
        

          
    </span>
    <div v-if="quantityFixed" class="chip  chipmargins">
      {{quantity}}
    </div>
  </span>

</template>

<script>
export default {
    name:'quantity-picker',
    props:['quantity','quantityFixed'],
    data() {
        return {
            
        }
    },
    methods: {
        increment() {
            console.log('increment emitting quantityChange')
            // this.quantity++;
            this.$emit('quantityChange', this.quantity+1)
        },
        decrement() {
            console.log('decrement emitting quantityChange')
            if (this.quantity > 0) {
                // this.quantity--;
                this.$emit('quantityChange', this.quantity-1)
            }
        }
    }
}
</script>

<style>
.quantity-picker {
  display: flex;
  align-items: center;
  
}

.btn-minus,
.btn-plus {
  
  font-size: 16px;
}
.inputNumber{
    width:24px;
    padding-left:6px;
    padding-right:6px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.chipmargins{
  margin-top:22px;
  margin-right:32px !important;
}
</style>