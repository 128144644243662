<template>
    <div id='Rating'>
        <span 
            class = "pointer" 
            v-for="n in 5" v-bind:key="n" 
            v-on:click="setRating(n)"
            >
        <Star 
            :on="score>=n"
        />
        </span>
    </div>
</template>

<script>
import Star from './Star.vue'

export default ({
    name: 'Rating',
    props:['score'],
    data() {
        return {
            // score: undefined
        }
    },
    components:{
        Star
    },
    methods:{
        setRating(score){
            // this.score=score
            this.$emit('score',score)
        }
    }
})
</script>

<style scoped>
.pointer{
    cursor: pointer;
}
</style>