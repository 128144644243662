<template>
    <div id="Settings">
        <h4>Instellingen</h4>
        <ul class="collection unselectable">
                <router-link to="/profile" class="collection-item">
                    <span class="normal">Profiel</span>
                    <i class="material-icons right normal">chevron_right</i>
                </router-link>
                <router-link to="/household" class="collection-item">
                    <span class="normal">Huis</span>
                    <i class="material-icons right normal">chevron_right</i>
                </router-link>
                <router-link to="/inventory" class="collection-item ">
                    <span class="normal">Vaste boodschappen</span>
                    <i class="material-icons right normal">chevron_right</i>
                </router-link>
                <router-link to="/tag-setup" class="collection-item ">
                    <span class="normal">Tags beheren</span>
                    <i class="material-icons right normal">chevron_right</i>
                </router-link>
        </ul>   
    </div>
</template>

<script>
import M from '@materializecss/materialize/dist/js/materialize.min.js'

export default {
    name: 'settings',
    data(){
        return {}
    },
    mounted(){
        var elems = document.querySelectorAll('.tabs');
        M.Tabs.init(elems, {});
    }
}
</script>

<style scoped>

.normal{
    color:black
}

</style>