//test
import db from '@/components/firebaseInit'
import {doc,setDoc,collection,addDoc, query, getDocs, where, limit, deleteDoc} from 'firebase/firestore'
import { getAuth } from "firebase/auth";
// import firebase from 'firebase/compat/app';
//import { resolve } from 'core-js/fn/promise'
var sharedlogic = {
    checkIfUserDocsExist(){
        //Can we do without this function?
        return new Promise ((resolve) => { resolve('Dummy')})
    },
    createUserDocs(name,householdName){
        //After login, we'll check if the user and household document for this user exist. If not, we'll create them.
        console.log('createUserDocs')

        // var firebaseUser = firebase.auth().currentUser
        var firebaseUser = getAuth().currentUser
        
        return new Promise ((resolve,reject) => {
                const docRefhh = doc(collection(db, "Household"))

                // var docRefHousehold = db.collection('Household').doc().id
                // console.log('Will create a household document first with document id : ' + docRefHousehold)
                console.log('Will create a household document first with document id : ' + docRefhh.id)

                setDoc(docRefhh,{
                // db.collection('Household').doc(docRefHousehold).set({
                    members:[firebaseUser.uid],
                    membersExtraInfo:[
                        {
                            id: firebaseUser.uid,
                            name: name,
                            email: firebaseUser.email
                        }
                    ],
                    name: householdName
                }).then(()=>{
                    console.log('Will create a new user document for user ID : ' + firebaseUser.uid)
                    const docRefu = doc(collection(db,"Users"),firebaseUser.uid)
                    setDoc(docRefu,{//Create a new user document
                    // db.collection('Users').doc(firebaseUser.uid).set({//Create a new user document
                        household : docRefhh.id,
                        email : firebaseUser.email,
                        creator : firebaseUser.uid,
                        name: name
                    })
                    
                    const docRefl = doc(collection(db,"List"))
                    // var listid = db.collection('List').doc().id
                    console.log('Creating a new list with ID: ' + docRefl.id)
                    setDoc(docRefl,{
                    // db.collection('List').doc(listid).set({
                        id : docRefl.id,
                        active : true,
                        createdDate: new Date(),
                        creator: firebaseUser.uid,
                        household: docRefhh.id,
                        items : []
                    })

                    const docRefn = doc(collection(db,"Notes"))
                    // var noteListId = db.collection('Notes').doc().id
                    console.log('Creating a new note list with ID: ' + docRefn.id)
            
                    // db.collection('Notes').doc(noteListId).set({
                    setDoc(docRefn,{
                        creator: firebaseUser.uid,
                        household: docRefhh.id,
                        notes : []
                    })

                    addDoc(collection(db,"InventoryList"),{
                    // db.collection('InventoryList').add({
                        creator: firebaseUser.uid,
                        household: docRefhh.id,
                        inventory : []
                    }).then(inventoryListRef=>{
                        console.log('Created a new inventorylist list with ID: ' + inventoryListRef.id)
                    })

                    const q = query(collection(db,"Recipes"),where('newUserContent','==',true))
                    getDocs(q).then(querySnapshot=>{
                    // db.collection('Recipes').where('newUserContent','==',true).get().then(qs=>{
                        querySnapshot.forEach(doc=>{
                            let recipe = doc.data()
                            recipe.household = docRefhh.id
                            recipe.creator = firebaseUser.uid
                            delete recipe.newUserContent
                            console.log(recipe)
                            addDoc(collection(db,"Recipes"),recipe)
                            // db.collection('Recipes').add(recipe)
                        })
                    })
                    .then(resolve('User + household doc successfully created.'))
                    .catch(err=>reject('Error while creating a new user document. Error message: ' +err))
                }).catch(err=>reject('Error while creating a new household document. Error message: ' +err))
            // }else{
            //   resolve('User document already exists.')
            // }
        //   })          
        })      
      },    
    categorize(item,categoryMasterData,user){
    console.log('Categorize item - ' + item)
        return new Promise( 
        (resolve, reject) => {
            var cat

            const q = query(collection(db,"IngredientMasterData"),
                where('ingredientNameLowerCase','==',item.toLowerCase()),
                where('household','==',user.household),
                limit(2) //This query should only return 1. We limit to 2 so we can flag up an issue if it returns 2.
            )
            
            getDocs(q).then(querySnapshot=>{
            // db.collection('IngredientMasterData')
            // .where('ingredientNameLowerCase','==',item.toLowerCase())
            // .where('household','==',user.household)
            // .limit(2) //This query should only return 1. We limit to 2 so we can flag up an issue if it returns 2.
            // .get()
            // .then(querySnapshot => {
                var i = 0 
                querySnapshot.forEach(doc =>{
                    if (i>0){
                        var msg = 'sharedlogic.categorize - More than 1 ingredient master data record found specific to the household'
                        console.error(msg)
                        reject(msg)
                    } else {
                        cat= doc.data().categoryName
                    }    
                    i++
                })
                if(cat != null){
                    console.log('sharedlogic.categorize - Found a category specific to this household : ' + cat)
                    resolve(categoryMasterData.find(catMd => catMd.categoryName == cat))
                } else {
                    console.log("sharedlogic.categorize - No masterdata known for this item. Will now query globally for results")
                    //No masterdata specific to this household. We'll now check all documents and see. 
                    const qe = query(collection(db,"IngredientMasterData"),
                        where('ingredientNameLowerCase','==',item.toLowerCase()),
                        limit(10) //TODO: This can be done smarter, with less reads probably. But fine for now.)
                    )

                    // db.collection('IngredientMasterData')
                    // .where('ingredientNameLowerCase','==',item.toLowerCase())
                    // .limit(10) //TODO: This can be done smarter, with less reads probably. But fine for now.
                    // .get()
                    getDocs(qe).then(querySnapshot => {
                        var results = []
                        querySnapshot.forEach(doc =>{
                            results.push(doc.data())
                        })
                        if (results.length==0){
                            console.log('sharedlogic.categorize - No results found (globally)')
                            resolve({icon:'🤷🏼‍♂️',supermarketOrder:0,categoryName:'Onbekend',id:'Onbekend'})
                        } else{
                            console.log('sharedlogic.categorize - Queried for this ingredient globally and found ' + results.length + ' results.')
                            console.log(results)
                            var mostMentionedCat = this.mostOccurences(results.map(result=>result.categoryName))
                            console.log('sharedlogic.categorize - Most mentioned category is: ' + mostMentionedCat)
                            resolve(categoryMasterData.find(catMd => catMd.categoryName == mostMentionedCat))
                        }
                    })

                }
            })  
        })
    },
    mostOccurences(arr){
        return arr.sort((a,b) =>
                arr.filter(v => v===a).length
            - arr.filter(v => v===b).length
        ).pop();
    },
    test(){
        console.log(this.user)
    },
    saveCategory(item,user){
        console.log('sharedlogic.saveCategory',item)
        if(!item.name){
            item.name=item.ingredientName
        }

        return new Promise ((resolve, reject) =>{
            
            const q = query(collection(db,"IngredientMasterData"),
                where('ingredientNameLowerCase','==', item.name.toLowerCase()),
                where('household','==',user.household)
            )

            // db.collection('IngredientMasterData')
            // .where('ingredientNameLowerCase','==', item.name.toLowerCase())
            // .where('household','==',user.household)
            // .get()
            getDocs(q)
            .then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    //console.log(doc.data())
                    console.log('sharedlogic.saveCategory - ' + item.name + ' was categorised as ' + doc.data().categoryName + '. This record will now be deleted.')
                    // doc.ref.delete()
                    deleteDoc(doc.ref)
                })
                
                //Now that all existing docs for this ingredient are removed, we can set a new one.
                if (item.category.icon != '🤷🏼‍♂️'){       
                    const docRef = doc(collection(db,"IngredientMasterData"))
                    setDoc(docRef,{
                    // db.collection('IngredientMasterData').doc().set({
                        ingredientName : item.name,
                        ingredientNameLowerCase : item.name.toLowerCase(),
                        categoryName : item.category.categoryName,
                        creator : user.id,
                        household : user.household
                    }).then(() =>{
                        console.log('sharedlogic.saveCatory - Set category of ' +item.name + ' to ' + item.category.categoryName)
                        resolve('Success')
                    }).catch(error=>{
                        console.error('sharedlogic.saveCatory - Problem while trying to set category of  ' +item.name + ' to ' + item.category.categoryName)
                        reject(error)
                    })
                    
                }else{
                    console.log('sharedlogic.saveCatory - Category of ' +item.name + ' is set to unkown, so not saved to DB.')
                    resolve('Category unkown, so no category is saved.')
                }
            })
            .catch(error=>{reject(error)})
        })


    },
    // getUserData(){
    //     console.log('getUserData')
    //     var userID = firebase.auth().currentUser.uid
    //     //console.log(userID)

    //     return new Promise( (resolve, reject) =>{
    //         db.collection('Users').doc(userID).get().then(user => {
    //             //  console.log('user')
    //             //  console.log(user)
    //             resolve (user.data())
    //             //  console.log(user.data().todoistAPIKey)
    //         }).catch(error =>{
    //             reject(error)
    //         })
    //     })

    // },

    addTodoistItem(item, user){
        console.log('Add todoist item')
        console.log(item);
        console.log(user);
            
        async function postData(url = '', data = {},user) {
            console.log(user)
            // Default options are marked with *
            const response = await fetch(url, {
              method: 'POST', // *GET, POST, PUT, DELETE, etc.
              mode: 'cors', // no-cors, *cors, same-origin
              cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
              credentials: 'same-origin', // include, *same-origin, omit
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + user.todoistAPIKey
                // 'Content-Type': 'application/x-www-form-urlencoded',
              },
              redirect: 'follow', // manual, *follow, error
              referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
              body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            // console.log('Response: ', response)
            return response
        }

        var additionalInfo
        if (item.quantity && item.origin){
            additionalInfo = '(' + item.quantity + ' / ' + item.origin.text + ')'
        } else if(item.quantity){
            additionalInfo = '(' + item.quantity + ')'
        } else if (item.origin){
            additionalInfo = '(' + item.origin.text + ')'
        } else {
            additionalInfo = ''
        }
        // console.log(additionalInfo)

        var dataString = {
            content : item.category.icon + ' ' + item.name,
            order : item.category.supermarketOrder,
            description : additionalInfo,
            priority: 2, 
            project_id : user.todoistProjectId 
        };

        // var dataString = '{"content": "test" , "priority": 1, "project_id": ' + user.todoistProjectId + '}';
        
        // console.log(dataString)
        return new Promise ((resolve)=>{
            postData('https://api.todoist.com/rest/v2/tasks',dataString,user)
            // postData('https://reqres.in/api/users',{"name":"morpheus","job":"leader"},user)
            .then((data) => {
                console.log('Returned:', data); // JSON data parsed by `data.json()` call
                resolve('success')
              })
        })



        // var request = require('request');
       
        // var headers = {
        //     'Content-Type': 'application/json',
        //     'Authorization': 'Bearer ' + user.todoistAPIKey
        // };

        // var additionalInfo
        // if (item.quantity && item.origin){
        //     additionalInfo = '(' + item.quantity + ' / ' + item.origin.text + ')'
        // } else if(item.quantity){
        //     additionalInfo = '(' + item.quantity + ')'
        // } else if (item.origin){
        //     additionalInfo = '(' + item.origin.text + ')'
        // } else {
        //     additionalInfo = ''
        // }

        // var dataString = '{"content": "'
        //     +item.category.supermarketOrder + ' ' 
        //     +item.category.icon + ' ' 
        //     +item.name + ' '
        //     +additionalInfo
        //     + '" , "priority": 2, "project_id": ' + user.todoistProjectId + '}';

        // var options = {
        //     url: 'https://api.todoist.com/rest/v1/tasks',
        //     method: 'POST',
        //     //mode: 'no-cors',
        //     headers: headers,
        //     body: dataString
        // };
        
        // // item.todoistResponseCode = 0
        // // item.success = false
        // item.todoistExportSuccess = false;
        // item.successMark = '';
        // item.todoistResponseCode = 0
        // item.attemptedExportTodoist = false

        // return new Promise (
        // (resolve, reject) => {

        //     function callback(error, response, body) {
                
        //         if (!error && response.statusCode == 200) {
        //         console.log(body);
        //         item.todoistExportSuccess = true;
        //         item.successMark = '✅';
        //         item.renderId = item.renderId + 'exportedToTodoist' //this forces the update of the list
                
        //         }
        //         // if (response.statusCode ==500 || response.statusCode == 429)
        //         if (response.statusCode != 200)
        //         {
        //             item.successMark = '❌';
        //             reject(response.statusCode)
        //         }
        //         item.todoistResponseCode = response.statusCode
        //         item.attemptedExportTodoist = true

        //         resolve(response.statusCode)
        //     }

        //     request(options, callback);

        // })
    },
    // addAHItem(item,user){  
    //     console.log(item,user)

    //     // var request = require('request');

    //     // var options = {
    //     //     url: user.AHConnector + item.name,
    //     //     method: 'GET',
    //     //     //mode: 'no-cors',
    //     //     //headers: headers,
    //     //     //body: dataString
    //     // };
    //     // console.log(options)
        
        
       
    //     // return new Promise (
    //     // (resolve,reject)=>{
    //     //     function callback(error, response, body) {
    //     //         // console.log('Callback AH')
    //     //         console.log(error)
    //     //         console.log(response)
    //     //         console.log(body)
                
    //     //         if(response.statusCode ==200){
    //     //             resolve(response.statusCode)
    //     //         } else {
    //     //             reject(response.statusCode)
    //     //         }
    //     //     }

    //     //     request(options, callback);
    //     // })
    // },
    uid(currentIDs){
        //console.log(currentIDs)
        // var filteredIDs = currentIDs.filter(id => {id ? id : false}) //Remove undefineds, nulls etc

        var filteredIDs = currentIDs.filter(item=> {
            if (Number.isFinite(item) && item !== 0) {
              return true
            }
            return false
          })
        //console.log(filteredIDs)

        if (filteredIDs.length==0){
            return 1
        }

        var ret = Math.max( ...filteredIDs ) +1;
        // console.log(ret)

        return ret
    },
    uidProm(currentIDs){
        //console.log(currentIDs)
        // var filteredIDs = currentIDs.filter(id => {id ? id : false}) //Remove undefineds, nulls etc

        var filteredIDs = currentIDs.filter(item=> {
            if (Number.isFinite(item) && item !== 0) {
              return true
            }
            return false
          })
        //console.log(filteredIDs)
        new Promise ((resolve) =>{
            if (filteredIDs.length==0){
                return resolve(1)
            }
            return resolve(Math.max( ...filteredIDs ) +1)
        })

    },
    newRenderIds(objectArray){
        console.log('newRenderIds')
        var i = 0
        objectArray.forEach(obj =>{
            obj.renderId = i
            i++
        })
    },
    safeUID (input){ //In use for recipe pictures, inventoryList entries

        // The idea is that this function takes a string, and returns a UID that can be safely used as UID but is also recognisable as the base string
        // and only contains the characters allowed in a UID. So basically just the whitelisted a-z, A-Z and 0-9 followed by or prefixed by a unique ID
        var safeUID

        // Strip out slashes
        var regExSlash = /\//g
        safeUID = input.replace(regExSlash,'')

        //Add a random string
        safeUID = safeUID + Math.random().toString(36).substring(7)

        return safeUID
    },
    dayOfWeekString(dayOfWeekInt){
        if(dayOfWeekInt == 0){
            return 'Zondag'
        } else if(dayOfWeekInt == 1){
            return 'Maandag'
        } else if(dayOfWeekInt == 2){
            return 'Dinsdag'
        } else if(dayOfWeekInt == 3){
            return 'Woensdag'
        } else if(dayOfWeekInt == 4){
            return 'Donderdag'
        } else if(dayOfWeekInt == 5){
            return 'Vrijdag'
        } else if(dayOfWeekInt == 6){
            return 'Zaterdag'
        } else {
            console.log('Error invalid day of week passed in to dayOfWeekString function')
            return 'Date problem dayOfWeekString'
        }
    },
    parseDateStringToDate(dateString){
        //Parses a date string in format YYYYMMDD to js Date
        // console.log(dateString)
        var year        = dateString.substring(0,4);
        var month       = dateString.substring(4,6);
        var day         = dateString.substring(6,8);

        return new Date(year, month-1, day);
    },
    avgTimeBetweenEatDates(recipe){
        // var recipe = this.recipes[0]
        // console.log(recipe)
        // console.log(recipe.eatDate.length)
        // console.log(recipe)
        if (recipe.eatDate){
            recipe.eatDate.sort()
        } else{
            return 0
        }
        
        var daysBetween= []
        for (let i = 1; i < recipe.eatDate.length; i++) {
            var dateA = sharedlogic.parseDateStringToDate(recipe.eatDate[i-1])
            var dateB = sharedlogic.parseDateStringToDate(recipe.eatDate[i])
            daysBetween.push(Math.round((dateB-dateA)/1000/60/60/24))
        }
        // console.log(daysBetween)
        const sum = daysBetween.reduce((a, b) => a + b, 0);
        var avg = (sum / daysBetween.length) || 0;
        
        recipe.score == 3 ? avg = Math.min(avg, 365) : null
        recipe.score == 4 ? avg = Math.min(avg, 180) : null
        recipe.score == 5 ? avg = Math.min(avg, 90) :null
        return avg
    },
    timeSinceLastEatDate(recipe){
        if (recipe.eatDate){
            const lastEatdate = Math.max(...recipe.eatDate)+''
            // console.log(lastEatdate)
            const timeSince = Math.round((new Date() - sharedlogic.parseDateStringToDate(lastEatdate))/1000/60/60/24)
            return timeSince 
        } else{
            return 0
        }

    }   
}
export default sharedlogic

