<!--
#### TODO: Something to consider: save recipe to be recipetitle + random doc ID - 
#### this would enable having multiple recipes with the same title 
#### (usefull when having multiple users too!). Notes already works this way.
-->

<template>
    <div id ="edit-recipe">

        <h3 v-if="newMode">Nieuw recept</h3>
        <h3 v-else>Pas recept aan</h3>
        
        <div class="row">
            <div class="col s12 l6">
                <div class="row">
                    <div class="input-field col s12">
                        <input type="text" v-model="recipe.recipeTitle" required>
                            <label v-if="recipe.recipeTitle" class="active">Naam recept</label>
                            <label v-else>Naam recept</label>
                    </div>
                </div>
                
                <div class="row">
                    <div class="input-field col s12">
                        <label v-if="recipe.recipeText" class="active">Recept tekst</label>
                        <label v-else>Recept tekst</label>
                        
                        <textarea v-model="recipe.recipeText" class="materialize-textarea"  id="recipeText"></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="input-field col s12">
                        <input type="text" v-model="recipe.urlRecipe" >
                            <label v-if="recipe.urlRecipe" class="active">Recept URL</label>
                            <label v-else>Recept URL</label>
                    </div>
                </div>
                
                <div v-if="household">
                    <div v-for="tagSetup in household.tagSetup.filter(tS=>tS.tags.length>0)" :key="tagSetup.name">
                        <h6 class="padbelow">{{tagSetup.name}}</h6>
                        <div 
                            :class="{chip : true, pointer : true, 'selected-chip': selectedTags.includes(tag)}"  
                            v-for="tag in tagSetup.tags" :key="tag"
                            v-on:click="toggleTag(tag)">
                        {{tag}}
                        </div>
                    </div>
                </div>

                <h6>Extra tags</h6>
                <div id="tags" class="chips"></div>

                <!--PHOTOS-->
                <div class="row">
                    <p class ="col s3">Foto gerecht</p>
                    <div class="input-field col s3 " id="recipe-photo" >
                        <input type="file" accept="image/*" @change="savePhotoLocal('G ', $event)">
                    </div>
                </div>

                <div class="row">
                    <p class ="col s3">Foto recept</p>
                    <div class="input-field col s3 " id="recipe-photo" >
                        <input type="file" accept="image/*" @change="savePhotoLocal('R ', $event)">
                    </div>
                </div>

                <div class="row">
                    <p class ="col s3">Diagram recept</p>
                    <div class="input-field col s3" id="recipe-photo" >
                        <input type="file" accept="image/*" @change="savePhotoLocal('D ', $event)">
                    </div>
                </div>
            </div>
            
        
            <!--INGREDIENTS-->
            <h5 class ="col s12 l6">Ingrediënten</h5>
            <!-- New ingredient      -->
            <!-- <div class="row"> -->
                <div class="input-field col s6 l3 ">
                    <input 
                        id="ingredient" type="text" 
                        class="autocomplete"
                        autocomplete="off"
                        v-model="newIngredientName" 
                        v-on:keydown.esc="autoComplete.close()"
                        tabindex="1"
                        v-on:keyup.enter="addIngredient"
                        required
                        >
                    <label>Ingredient</label>
                </div>
            
                <div class="input-field col s4 l2">
                    <input 
                        type="text" id="ingredientQty" 
                        v-model="newIngredientQty" 
                        v-on:keydown.enter="addIngredient"
                        tabindex="2">
                        <label>Aantal</label>
                </div>
                <div class="col s2 l1">    
                    <button 
                        v-on:click="addIngredient" 
                        tabindex="3"
                        class="btn btnpad waves-effect waves-ripple">
                        <i class="material-icons">add</i>
                    </button>    
                </div>
            <!-- </div>                             -->


            <!-- Ingredient list -->
            <div class="col s12 l6 ">
                <div class="bpadding">
                    <div class="row " v-if="recipe.ingredients[0]">    
                        <ul class="collection ">
                            <li 
                                class="collection-item"
                                v-for="record in recipe.ingredients"
                                v-bind:key="record.renderId" >
                                
                                <span v-if="record.category">{{record.category.icon}} </span>
                                <span>{{record.ingredientName}} </span>

                                <a href="#editListItem" v-on:click="setModalContent(record)" class="secondary-content modal-trigger">
                                    <i class="material-icons">more_vert</i>
                                </a>

                                <div class="light">{{record.quantity}}</div>

                            </li>    
                        </ul>  
                    </div>
                </div>
            </div>

        </div>
    
        <!-- <editIngredient
            :ingredient="editIngredient"
            v-on:deleteIngredient="deleteIngredient"
            :categoryMasterData="categoryMasterData"
            :user="user"
        /> -->
        <editListItem
            :item="editItem"
            v-on:deleteItem="deleteIngredient"
            v-on:amendItem="amendIngredient"
            :categoryMasterData="categoryMasterData"
            :user="user"
            :modal="editListItemModal"
        />        
    
    </div>
</template>

<script>
import db from './firebaseInit'
// import firebase from 'firebase/compat/app'
import {getAuth} from 'firebase/auth'
import {getDownloadURL, getStorage, ref, uploadBytes} from 'firebase/storage'
import {addDoc, collection, updateDoc, doc} from 'firebase/firestore'
import M from '@materializecss/materialize/dist/js/materialize.min.js'
// import editIngredient from './modalEditIngredient.vue'
import editListItem from './modalEditListItem.vue'
import sharedlogic from '@/scripts/sharedlogic'
import {autoCompleteList} from '@/data/autoComplete'

export default {
    name: 'edit-recipe',
    // props: ['saveTrigger'],
    props: ['saveTrigger', 'recipes', 'categoryMasterData','user','allTags','household'],
    data(){
        return{
            picture_recipe: {filename: null, filebinary:null},
            pictureRecipe: null,
            picture_dish: {filename: null, filebinary:null},
            pictureDish: null,
            diagram_recipe: {filename: null, filebinary:null},
            diagramRecipe: null,
            newIngredientName: null,
            newIngredientQty: null,
            newIngredient: null,
            // categories : [],
            selected: null,
            newMode: false,
            recipe : {
                recipeTitle:null,
                ingredients :[],
                dishType : null,
                diagramRecipe:null,
                carbType:null,
                pictureDish:null,
                urlRecipe:null,
                meatType:null,
                pictureRecipe:null,
                recipeText:null
                },
            // editIngredient : {
            //     ingredientName:null,
            //     category:{
            //         icon:null,
            //         supermarketOrder: null,
            //         categoryName:null
            //     }
            // },
            editItem : {
                name:"",
                renderId:1,
                category:{
                    icon:"🤷🏼‍♂️",
                    categoryName:"Onbekend",
                    supermarketOrder:0,
                    id: "Onbekend"
                },
                done:false
            },            
            autoComplete : null,
            editListItemModal: null,
            chipsInstance:null,
            mounted: false,
            recipeLoaded: false,
            selectedTags:[]
        }
    },    
    components: {
        // editIngredient
        editListItem
    },
    mounted(){
        //console.log('new recipe - mounted')

        //initialize modals
        var elems = document.querySelectorAll('.modal');
        M.Modal.init(elems, {
        });



        var elemsELI = document.querySelector("#editListItem")
        this.editListItemModal = M.Modal.getInstance(elemsELI);
        console.log('edit list item modal', this.editListItemModal)
        this.$nextTick().then(()=>{
            M.textareaAutoResize(document.querySelector('#recipeText'))
        })

        var elemsA = document.querySelector("#ingredient")
        var options = {
            data : autoCompleteList,
            onAutocomplete: (item) => {
                console.log('In onAutoComplete - item : ', item)
                // this.addToList(item)
                this.newIngredientName = item
                document.querySelector('#ingredientQty').focus()
                console.log("New ingredient name : " , this.newIngredientName)

                // this.addToList()
            }
        }
        this.autoComplete = M.Autocomplete.init(elemsA,options) 

        this.mounted = true

        this.initialiseTags()

        // this.autoComplete.dropdown.coverTrigger = false

        // var elemsA = document.querySelector('#ingredient')
        // var options = {
        //     data : {
        //         Brocolli : null,
        //         Brood : null,
        //         Appel : null
        //     }
        // }
        // M.Autocomplete.init(elemsA,options) 

    },
    created(){
        console.log('Edit recipe - created')
        // if (this.recipes.length>0){
            this.fetchData()
        // }
    },
    watch: {
        // '$route':'fetchData',
        'saveTrigger': 'saveRecipe',
        'recipes':'fetchData'
    },        
    methods: {
        toggleTag(tag){
            console.log('Toggle tag', tag)
            if(this.selectedTags.includes(tag)){
                const index = this.selectedTags.indexOf(tag);
                console.log('removing from arr',this.selectedTags, index)
                this.selectedTags.splice(index, 1);
            } else{
                this.selectedTags.push(tag)
            }
            
        },
        initialiseTags(){
            if (this.recipeLoaded && this.mounted){
                var chipsData = []
                if (this.recipe.tags){
                    this.recipe.tags.forEach(tag=>{
                        if ([].concat(...this.household.tagSetup.map(tS=>tS.tags)).includes(tag)){ //If the tag is in a configured group
                            this.selectedTags.push(tag)
                        } else {
                            chipsData.push({tag : tag})
                        }

                    })
                }

                // const allTags = [].concat(...this.recipes.map(recipe => recipe.tags)).filter(el=>el)
                console.log('All tags: ', this.allTags)
                var autocompleteOptions={}
                this.allTags.forEach(tag=>{
                    autocompleteOptions[tag]=null
                })

                console.log('Autocomplete options:', autocompleteOptions)

                var elemsC = document.querySelector('#tags');
                this.chipsInstance = M.Chips.init(elemsC, {
                    placeholder: 'Typ een tag...',
                    secondaryPlaceholder: '+ Tag',
                    autocompleteOptions : {
                        data : autocompleteOptions
                    },
                    data : chipsData,
                    onChipAdd: (chips,chip)=>{
                        console.log("On chip add, chips",chips)
                        console.log("newChip",chip)
                        // newChip.classList.add("greenChip")
                        chip.style.backgroundColor = "rgb(35,168,112)"
                        chip.style.color = "white"
                    }
                })
                console.log("Tags element initialised. Instance : ",this.chipsInstance)
                for (let chip of this.chipsInstance.el.children) {
                    if (chip.className=="chip"){
                        chip.style.backgroundColor = "rgb(35,168,112)"
                        chip.style.color = "white"
                    }
                }
            }
        },
        fetchData () {
            console.log('ViewRecipe - FetchData')

            if (this.$route.params.recipe_id=='new'){
                console.log('New recipe mode!')
                this.newMode = true
            } else {
                if (this.recipes.length>0){
                    this.recipe = JSON.parse(JSON.stringify(this.recipes.find(recipe => recipe.recipeTitle == this.$route.params.recipe_id)))
                }
                sharedlogic.newRenderIds(this.recipe.ingredients)
            }   
            this.recipeLoaded = true
            this.initialiseTags()
        },
        deleteCategory(record){
            console.log(record)
            delete record.category
            record.renderId  += 10000
            this.$forceUpdate()
        },
        setModalContent(ingredient){
            console.log('Set modal content',ingredient) 
            ingredient.uid = sharedlogic.safeUID(ingredient.ingredientName)
            ingredient.name = ingredient.ingredientName
            ingredient.category.id=ingredient.category.categoryName.replaceAll('/','ß') // Bit of an ugly fix to make v-bind on the dropdown in the editIngredient component work. There are many ingredients that have the id prop missing (while categoryMasterData has it).
            this.editItem = JSON.parse(JSON.stringify(ingredient))
        },
        findIndexOfIngredient(ingr){
            var orgIngr = this.recipe.ingredients.find(originalIngredient=>originalIngredient.uid==ingr.uid)
            return this.recipe.ingredients.indexOf(orgIngr)        
        },
        amendIngredient(ingr){
            console.log('Amend ingredient', ingr)
            // var orgIngr = this.recipe.ingredients.find(originalIngredient=>originalIngredient.uid==ingr.uid)
            // var indexToRemove = this.recipe.ingredients.indexOf(orgIngr)    
            
            var indexToRemove = this.findIndexOfIngredient(ingr)
            ingr.ingredientName = ingr.name
            
            this.recipe.ingredients.splice(indexToRemove,1,ingr)
        },
        deleteIngredient(ingr){
            console.log('delete ingredient')

            // var orgIngr = this.recipe.ingredients.find(originalIngredient=>originalIngredient.uid==ingr.uid)
            // var indexToRemove = this.recipe.ingredients.indexOf(orgIngr)  
            var indexToRemove = this.findIndexOfIngredient(ingr)
            console.log('index to remove',indexToRemove)        
            
            this.recipe.ingredients.splice(indexToRemove,1)
            
            // var indexToRemove = this.recipe.ingredients.indexOf(ingr)          
            // console.log(ingr)
            // console.log(indexToRemove)
            
            // this.recipe.ingredients.splice(indexToRemove,1)
        },
        
        saveRecipe() {
            console.log('save recipe')
            console.log(this.chipsInstance)

            this.recipe.tags = [].concat(this.chipsInstance.chipsData.map(el=>el.tag),this.selectedTags)
            console.log('Recipe tags', this.recipe.tags)
            
            // if (this.picture_recipe.filename!= null){
            //     this.recipe.pictureRecipe = this.picture_recipe.filename
            // }
            // if (this.picture_dish.filename!= null){
            //     this.recipe.pictureDish = this.picture_dish.filename
            // }
            // if (this.diagram_recipe.filename!= null){
            //     this.recipe.diagramRecipe = this.diagram_recipe.filename
            // }

            //Sanitize the ingredient objects before saving.
            this.recipe.ingredients.forEach(ingr =>{
                delete ingr.renderId

                if (ingr.category == null){
                    delete ingr.category
                }
            })

            if (!this.recipe.creator){
                console.log('About to set current user')
                this.recipe.creator = getAuth().currentUser.uid
                console.log('Set current user')
                }

            if (!this.recipe.pictures){
                this.recipe.pictures = {}
            }
            
            var recUpload = this.savePhotoToFirebase(this.picture_recipe)
            var recUrl = this.extractUrl(recUpload)

            var dishUpload = this.savePhotoToFirebase(this.picture_dish)
            var dishUrl = this.extractUrl(dishUpload)

            var diagramUpload = this.savePhotoToFirebase(this.diagram_recipe)
            var diagramUrl = this.extractUrl(diagramUpload)

            Promise.all([recUrl,dishUrl,diagramUrl]).then((results)=>{
                console.log(results)
                console.log('In promise all settled handler')
                console.log(this.recipe)
                if(this.picture_recipe.filename){
                    this.recipe.pictures.recipe = {
                        url : results[0],
                        filename : this.picture_recipe.filename
                    }
                }

                if(this.picture_dish.filename){
                    this.recipe.pictures.dish = {
                        url : results[1],
                        filename : this.picture_dish.filename
                    }
                }

                if(this.diagram_recipe.filename){
                    this.recipe.pictures.diagram = {
                        url : results[2],
                        filename : this.diagram_recipe.filename
                    }
                }

                if (this.newMode){
                    addDoc(collection(db,"Recipes"),{
                        recipeTitle : this.recipe.recipeTitle,
                        meatType : this.recipe.meatType,
                        carbType : this.recipe.carbType,
                        dishType : this.recipe.dishType,
                        urlRecipe: this.recipe.urlRecipe,
                        recipeText: this.recipe.recipeText,
                        ingredients: this.recipe.ingredients,
                        // pictureDish: this.recipe.pictureDish,
                        // diagramRecipe: this.recipe.diagramRecipe,
                        // pictureRecipe: this.recipe.pictureRecipe,
                        creator: this.recipe.creator,
                        household: this.user.household,
                        pictures: this.recipe.pictures,
                        tags:this.recipe.tags
                    }).then(
                        // this.$router.go(-1)
                        this.$router.push({name: 'view-recipe', params: {recipe_id: this.recipe.recipeTitle}})
                    ) //this.$router.push('/')
                    //.catch(error => console.log(error))
                } else {
                    const docRef = doc(db,"Recipes",this.recipe.id)
                    updateDoc(docRef,{
                        recipeTitle : this.recipe.recipeTitle,
                        meatType : this.recipe.meatType,
                        carbType : this.recipe.carbType,
                        dishType : this.recipe.dishType,
                        urlRecipe: this.recipe.urlRecipe,
                        recipeText: this.recipe.recipeText,
                        ingredients: this.recipe.ingredients,
                        // pictureDish: this.recipe.pictureDish,
                        // diagramRecipe: this.recipe.diagramRecipe,
                        // pictureRecipe: this.recipe.pictureRecipe,
                        creator: this.recipe.creator,
                        pictures: this.recipe.pictures,
                        tags: this.recipe.tags
                    }).then(
                        // this.$router.go(-1)
                        this.$router.push({name: 'view-recipe', params: {recipe_id: this.recipe.recipeTitle}})
                    ) //this.$router.push('/')
                    //.catch(error => console.log(error))
                }
            })

        },

        extractUrl(uploadPromise){
            console.log('Extracting url from upload promise.')
            console.log(uploadPromise)

            return new Promise ((resolve,reject)=>{
                uploadPromise.then(snapshot=>{
                    console.log(snapshot)
                    if(snapshot =='No picture to be saved.'){
                        resolve('No picture to be saved.')
                    } else{
                        getDownloadURL(snapshot.ref).then(url=>{resolve(url)})
                        // snapshot.ref.getDownloadURL().then(url=>{resolve(url)})
                    }
                }).catch(err=>{
                    console.error('Problem extracting the URL from file ref.')
                    reject(err)
                })
            })
        },
        savePhotoToFirebase(photo){
            //Prechecks
            
            console.log('Saving photo to firebase')
            console.log(photo.filename)
            if(!this.user.household){
                console.error('Save photo to firebase - Unable to save file - no user household known.')
                return new Promise ((reject) =>{reject('Save photo to firebase - Unable to save file - no user household known.')})
            }

            //Upload and get fileref


            if (photo.filebinary){
                // Create a root reference
                var storage = getStorage()
                // var storageRef = firebase.storage().ref();

                var metadata = {
                    customMetadata: {
                        creator: this.user.id,
                        household : this.user.household
                    }
                }

                // var fileRefD = storageRef.child(photo.filename);
                var fileRefD = ref(storage, photo.filename)
                return uploadBytes(fileRefD, photo.filebinary,metadata)
                // return fileRefD.put(photo.filebinary,metadata)//.then(snapshot => {
                //     console.log('Uploaded a blob or file!')
                //     console.log(snapshot)
                //      snapshot//.ref//.getDownloadURL().then(url => {return new Promise (resolve => resolve(url))})
                // })
            }  else {
                return new Promise (resolve =>{resolve('No picture to be saved.')})
            }  
            
            //Extract url from file ref.
        },
        savePhotoLocal(type, e){
            console.log('savePhoto')
 
            console.log(type)
            console.log(e)
            console.log(e.target.files)

            var extension = '.jpg'
            if (e.target.files[0].name.includes('.png')){
                extension = '.png'
            }

            // var random = ' ' + Math.random().toString(36).substring(7)
            var baseFilename = sharedlogic.safeUID(this.recipe.recipeTitle)
            console.log('baseFilename')
            console.log(baseFilename)

            if (type == 'R '){
                // this.picture_recipe.filename = type + this.recipe.recipeTitle + random + extension
                this.picture_recipe.filename = type + baseFilename + extension
                this.picture_recipe.filebinary = e.target.files[0]
            }
            if (type == 'G '){
                // this.picture_dish.filename = type + this.recipe.recipeTitle + random + extension
                this.picture_dish.filename = type + baseFilename + extension
                this.picture_dish.filebinary = e.target.files[0]
            }
            if (type == 'D '){
                // this.diagram_recipe.filename = type + this.recipe.recipeTitle + random + extension
                this.diagram_recipe.filename = type + baseFilename + extension
                this.diagram_recipe.filebinary = e.target.files[0]
            }
        },
        addIngredient(){
            console.log('addIngredient')
            this.autoComplete.close()
            if(this.newIngredientName == null){return}
            
            this.newIngredient = {
                ingredientName : this.newIngredientName.trim(),
                quantity: this.newIngredientQty,
                renderId: sharedlogic.uid(this.recipe.ingredients.map(ingr=>ingr.renderId))
            }
            this.newIngredientName = null
            this.newIngredientQty = null

            sharedlogic.categorize(this.newIngredient.ingredientName, this.categoryMasterData, this.user).then(cat=>{
                this.newIngredient.category = cat
                this.recipe.ingredients.push(this.newIngredient)
                
                document.getElementById("ingredient").focus();
            }).catch(error=>{
                console.log(error)
            })            
        }
    }
}
</script>

<style scoped>
.btnpad{
    margin-top: 20px;
}
.touchlist{
    margin-top: 15px
}
.fa { 
    line-height: inherit
}
.light{
    color:grey
}

.bpadding{
    padding-bottom: 50vh
}

.padbelow{
    padding-bottom: 14px;
}

.selected-chip{
    color: white;
    background-color: rgb(35,168,112);
}
.pointer{
    cursor: pointer;
}
</style>