import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import './registerServiceWorker'

// import firebase from 'firebase/app'
// import 'firebase/auth';        // for authentication
// import 'firebase/compat/storage';     // for storage
import 'firebase/storage';     // for storage
// import 'firebase/firestore';   // for cloud firestore

// v9 compat packages are API compatible with v8 code
// import firebase from 'firebase/compat/app';

// import 'firebase/compat/auth';
// import 'firebase/compat/firestore';

import { getAuth, onAuthStateChanged } from "firebase/auth";
// import 'firebase/compat/storage';

import './components/firebaseInit'

// import '@materializecss/materialize/dist/css/materialize.min.css'
import './materialize-sass/materializeGromstyle.css'

//import '@/test.js'

//import jQuery from 'jquery'
//global.jQuery = jQuery
//global.$ = jQuery
//import 'materialize-css/dist/js/materialize.js'

Vue.config.productionTip = false

let app;
let auth = getAuth()
onAuthStateChanged(auth, user =>{
// firebase.auth().onAuthStateChanged(user => {
  if(!app) {
    app = new Vue({
      render: h => h(App),
      router,
    }).$mount('#app')    
    // console.log('mainjs')
    console.log(user)
  }
})

