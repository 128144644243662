<template>
    <div id="ListItem">
        <label>  
            <input type="checkbox"  class="gromstyle-bg" v-model="item.done" @change="$emit('save-list')"/>
            <span v-bind:class="{'is-notToOrder':item.done, 'normal':true}" >
                <!-- {{item.successMark}} -->
                {{item.category.icon}}
                {{item.name}}    
            </span>
                            
        </label>  
        
        <span class="secondary-content">
            <a 
                class="gromstyle pointer unselectable customSmall"
                v-on:click="setStarred(item)"
            >
                <Star     
                    :on="item.starred"        
                />
            </a>

            <a v-if="supermarketSearchPrefix" v-bind:href="supermarketSearchPrefix+item.name" 
                class="gromstyle" > 
                <i  class="material-icons customSmall">launch</i>
            </a>

            <a href="#editListItem" v-on:click="$emit('set-modal-content',item)" 
                class="gromstyle modal-trigger">
                <i class="material-icons">more_vert</i>
            </a>
        </span>

        <div v-if="additionalInfo(item)" class="recipeData light "> 
        {{additionalInfo(item)}}
        
        </div>
        <div v-if="item.attemptedExportTodoist" class = "recipeData"> 
            <span v-if="item.attemptedExportTodoist && !item.todoistExportSuccess">Problem exporting to: </span>
            <img v-if="item.attemptedExportTodoist" 
                class = 'as-icon'
                src="https://firebasestorage.googleapis.com/v0/b/recipes-d95e9.appspot.com/o/todoist.png?alt=media&token=d83f9d8e-6815-4c6e-b4c1-3eb3b1a66943" 
                alt="Todoist" height="12"> 
            <!-- <img src="https://firebasestorage.googleapis.com/v0/b/recipes-d95e9.appspot.com/o/ah.png?alt=media&token=216bb60f-d8b1-419f-aab3-5836000c918b" 
                    alt="AH" height="12"> -->
        </div>
    </div>
</template>

<script>
import Star from './Star.vue'

export default {
    name:'list-item',
    props:['item','supermarketSearchPrefix'],
    data(){
        return{

        }
    },
    components:{
        Star
    },
    methods:{
        setStarred(item){
            item.starred = !item.starred
            // this.saveList()
            this.$emit('save-list')
        },
        additionalInfo(item){
            var additionalInfo
            
            if ( item.origin){    
                if (item.quantity && item.origin.text){
                    additionalInfo =   item.quantity + ' / ' + item.origin.text 
                } else if(item.quantity){
                    additionalInfo = item.quantity 
                } else if (item.origin.text){
                    additionalInfo =  item.origin.text 
                } else {
                    additionalInfo = ''
                }
            }
            return additionalInfo
        }
    }
}
</script>

<style scoped>
.normal{
    color:black
}
.is-notToOrder {
    text-decoration: line-through;
  }
  .recipeData{
    padding-left:36px
}


.light{
    color:grey
}

.customSmall{
    font-size: 23px;
    padding-right:12px
}

.pointer{
    cursor: pointer;
}
</style>