<template>
    <div id = "Home">
        <div class="container ">
            <div class="hero ">
                <div class="row ">
                    <!-- <img 
                        class="col l8 m6 s12" 
                        src="cooking.jpg"
                        width = max-content
                    > -->
                    <picture id='Picture' >
                        <source class="col l8 m6 s12" srcset="cooking.avif" type="image/avif" alt="Man cooking" width = max-content >
                        <source class="col l8 m6 s12" srcset="../assets/cooking.jpg" type="image/jpeg" alt="Man cooking" width = max-content>
                        <img class="col l8 m6 s12" src="../assets/cooking.jpg" alt="Man cooking" width = max-content>
                    </picture>

                    <div class="col l4 m6 s12">
                        <!-- <h4>No pizza</h4> -->
                        <h5>Lekker eten zonder gedoe</h5>
                        <p>
                            
                            Er gaat niets boven zelf koken. Gezond, vers, gezellig en goed voor je portomonee. 
                            <br><br>
                            Maar lekker koken vereist voorbereiding en dat is minder leuk dan het koken zelf.
                            <br><br>
                            No pizza helpt je met die voorbereiding.

                        </p>
                    </div>
                </div>
            </div>

            <div class="explainer">
                <div class="row flex-s">
                    <div class="col l4 m6 s12 box-a">
                        <br>
                        <h5>Bedenken wat je wilt eten</h5>
                        <p>
                            Je weet best wat je lekker vindt. 
                            <br><br>
                            De recepten zitten in je hoofd, staan in kookboeken, 
                            in tijdschrijften of op blogs.
                            <br><br>
                            Maar toch is het niet altijd makkelijk om te bedenken wat je wilt eten omdat het overzicht ontbreekt.

                        </p>
                    </div>

                    <!-- <img 
                        class="col l8 m6 s12 box-b" 
                        src="noidea.jpg"
                        width = max-content
                    > -->
                    <picture id='Picture' >
                        <source class="col l8 m6 s12 box-b"  srcset="noidea.avif" type="image/avif" alt="No idea what to eat" width = max-content >
                        <source class="col l8 m6 s12 box-b"  srcset="../assets/noidea.jpg" type="image/jpeg" alt="No idea what to eat" width = max-content>
                        <img class="col l8 m6 s12 box-b"    src="../assets/noidea.jpg" alt="No idea what to eat" width = max-content>
                    </picture>                    
                    
                </div>
            </div>

            <div class="explainer">
                <div class="row ">
                    <!-- <img 
                        class="col l4 m6 s12" 
                        src="notebook.jpg"
                        width = max-content
                    > -->

                    <picture id='Picture' >
                        <source class="col l5 m6 s12"   srcset="notebook.avif" type="image/avif" alt="Shopping list" width = max-content >
                        <source class="col l5 m6 s12"   srcset="../assets/notebook.jpg" type="image/jpeg" alt="Shopping list" width = max-content>
                        <img    class="col l5 m6 s12"     src=    "../assets/notebook.jpg" alt="Shopping list" width = max-content>
                    </picture>                    
                    
                    <div class="col l7 m6 s12">
                        <h5>Een boodschappenlijstje opstellen</h5>
                        <p>
                            Hoe vaak heb je al de ingrediënten van je favoriete recepten
                            overgeschreven op je boodschappenlijstje? 
                            
                            

                        </p>
                    </div>
                </div>
            </div>

            <div class="explainer">
                <div class="row flex-s">
                    <div class="col l4 m4 s12 box-a">
                        <h5>Boodschappen doen</h5>
                        <p>
                            En dan het boodschappen doen zelf. 
                            <br><br>
                            Zeker met een lange lijst kan dit een behoorlijke opgave zijn. 
                            <br><br>
                            Sta je helemaal achterin bij de diepvries, zie je dat je nog een van je verse groenten bent vergeten! 
                        </p>
                    </div>

                    <!-- <img 
                        class="col l8 m8 s12 box-b" 
                        src="shopping.jpg"
                        width = max-content
                    > -->
                    
                    <picture id='Picture' >
                        <source class="col l8 m8 s12 box-b"    srcset="shopping.avif" type="image/avif" alt="Grocery shopping" width = max-content >
                        <source class="col l8 m8 s12 box-b"    srcset="../assets/shopping.jpg" type="image/jpeg" alt="Grocery shopping" width = max-content>
                        <img    class="col l8 m8 s12 box-b"      src= "../assets/shopping.jpg" alt="Grocery shopping" width = max-content>
                    </picture> 

                </div>
            </div>

            <div class="row">
                <div class="center-align">
                    <h5>Vandaag geen pizza!</h5>
                    <p>No pizza zit bordenvol features om snel en makkelijk voorbereidingen
                         te treffen om lekker te koken.</p>
                </div>
                <div class="col xl3  s12 ">
                    <h6>Recepten</h6>
                    <div><i class="material-icons">check</i>Al je recepten op één plek</div>
                    <div><i class="material-icons">check</i>Zo uitgebreid of simpel als je zelf wilt</div>
                    <div><i class="material-icons">check</i>Zoeken en filteren van recepten</div>
                    <div><i class="material-icons">check</i>Automatisch categoriseren van ingredienten</div>
                    <div><i class="material-icons">check</i>Slimme filters als 'Tijdje niet gegeten'</div>
                    <br>
                </div>
                
                <div class="col xl3  s12 ">
                    <h6>Maaltijdplanner</h6>    
                    <div><i class="material-icons">check</i>Inplannen van maaltijden</div>
                    <div><i class="material-icons">check</i>Notities toevoegen</div>
                    <br>
                </div>

                <div class="col xl3  s12 ">
                    <h6>Lijst</h6>
                    <div><i class="material-icons">check</i>Recepten direct toevoegen aan je lijst</div>
                    <div><i class="material-icons">check</i>Vaste boodschappen snel toevoegen</div>
                    <div><i class="material-icons">check</i>Sorteren op looproute</div>
                    <div><i class="material-icons">check</i>Direct zoeken bij je favoriete supermarkt</div>
                    <div><i class="material-icons">check</i>Op je lijstje zien waar je een ingedient voor nodig hebt</div>
                    <br>
                </div>

                <div class="col xl3  s12 ">
                    <h6>Algemeen</h6>
                    <div><i class="material-icons">check</i> Delen met je huisgenoten</div>
                    <div><i class="material-icons">check</i> Onafhankelijk van supermarkt</div>
                    <div><i class="material-icons">check</i> Jouw recepten blijven van jou</div>
                    <br>
                </div>
            </div>
        </div> <!-- container     -->

        <div class = "container center-align">
            <h5>
                Overtuigd? Probeer het zelf!
            </h5>
            <br>
            <router-link to="/register" class = "btn waves-effect waves-ripple">
                Registreer
                <!-- <i class ="fa fa-plus"></i> -->
            </router-link>  
            <br><br>
        </div>

        <footer class=" gromstyle-bg-light ">
            <div class="container">
                <br>
                <p class="hyperlink" v-on:click="toggleShowCredits">Image credits</p>
                <div v-if="showCredits">
                    <div class="attribution"><a href='https://www.freepik.com/vectors/baking-ingredients'>Baking ingredients vector created by pch.vector - www.freepik.com</a></div>
                    <div class="attribution"><a href='https://www.freepik.com/vectors/paper'>Paper vector created by gstudioimagen1 - www.freepik.com</a></div>
                    <div class="attribution"><a href='https://www.freepik.com/vectors/supermarket-food'>Supermarket food vector created by pch.vector - www.freepik.com</a></div>
                    <div class="attribution"><a href='https://www.freepik.com/vectors/food-flat'>Food flat vector created by freepik - www.freepik.com</a></div>
                </div>
                <br>
            </div>
        </footer>
        
    </div>
</template>

<script>
// import M from 'materialize-css/dist/js/materialize.min.js'

export default{
    name: 'home',
    data(){
        return{
            showCredits:false
        }
    },
    mounted(){
        // var elems = document.querySelectorAll('.parallax');
        // var par = M.Parallax.init(elems, {});
        // console.log('par')
        // console.log(par)
    },
    methods:{
        toggleShowCredits(){
            console.log('toggle show credits')
            this.showCredits=!this.showCredits
        }
    }

}
</script>

<style scoped>
.hero{
    margin-top:120px;
    margin-bottom:120px;
    
}

.explainer{
    margin-top:80px;
    margin-bottom:80px
}

.material-icons{
    vertical-align: middle;
}

@media (max-width: 600px) {
  .flex-s {
    display: flex;
    flex-direction: column; /* Stack on top */
  }
  .box-a {
    order: 2; /* Go down, bring Box B up */
  }
}
.attribution{
    font-size:12px
}

.hyperlink {
     cursor:pointer;
     color:rgb(19,96,57);
     text-decoration:underline;
}

</style>

