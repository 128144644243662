<template>
    <span id='Star'>       
        
        <i v-if="on" class="material-icons">star</i>
        <i v-else class="material-icons">star_border</i>
    </span>
</template>

<script>

export default {
    name:'Star',
    props:['on'],
    data() {
        return {

        }       
    },
}
</script>
