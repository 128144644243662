<template>
    <div id="Product">
        <div class="parentContainer">
            <div class="imageclass">
                <img class="" :src="imgUrl" width=150 >
            </div>
            
            <p class="pricefloat">{{price}}</p>
            <p class="amountfloat">{{amount}}</p>
            <p v-if="unitPriceDescription" class="updfloat">{{unitPriceDescription}}</p>
            <!-- <div class="row">
                <p class="text col s9">{{description}}</p>
                <quantity-picker class = "col s3 qpicker"/>  
            </div> -->
            <div class="flexbox">
                <p class="text ">{{description}}</p>
                <quantity-picker 
                    class = "col qpicker"
                    :quantity="quantity"
                    :quantityFixed="quantityFixed"
                    @quantityChange="order($event)"
                />  

            </div>    
        </div>
        
    </div>
</template>

<script>
import QuantityPicker from './QuantityPicker.vue';

export default {
    name: 'product',
    props:['webshopId','imgUrl','price','description','amount', 'quantity','unitPriceDescription','quantityFixed'],
    data() {
        return {

        }
    },
    components: {
        QuantityPicker
    },
    created(){
        // console.log('Product created')
    },
    methods:{
        order(qty){
            console.log('order')
            var orderte = {
                webshopId:this.webshopId,
                description:this.description,
                quantity:qty,
                imgUrl:this.imgUrl,
                amount:this.amount,
            }
            
            this.unitPriceDescription ? orderte.unitPriceDescription= this.unitPriceDescription : null
            this.price ? orderte.price = this.price : null
            console.log('Product.vue emitting order', orderte)
            this.$emit('order', orderte)
        }
    }
}
</script>
<style scoped>
/* .pricefloat {
  float: right;
  width: 33.33%; 
  padding: 5px; 
} */

.parentContainer {
position: relative;
/*  */
color: black;
/* outline: 2px dotted green; */
}

.pricefloat {
    text-align: end;
    position: absolute;
    height: 3em;
    width: 3em;
    top: 70px;
    right: 16px;
    background: #ffffff71;
    font-size:20px;
    font-weight: bold;
    vertical-align: baseline;
    line-height: 3;
}


.amountfloat {
    text-align: end;
    position: absolute;
    height: 3em;
    /* width: 10em; */
    top: 110px;
    right: 16px;
    color: grey;
    font-size:14px;
    font-weight: bold;
    vertical-align: baseline;
    line-height: 3;
}
.updfloat {
    text-align: end;
    position: absolute;
    height: 3em;
    /* width: 10em; */
    top: 135px;
    right: 16px;
    color: grey;
    font-size:10px;
    font-weight: bold;
    vertical-align: baseline;
    line-height: 3;
}

.imageclass{
    display:flex;
    justify-content:center
}

.text{ overflow: hidden;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2; 
   -webkit-box-orient: vertical;
   width:65%;
   height: 3em;

}

.qpicker{
    height: 3em;
    /* margin-top:0em; */
}

.flexbox{
    display: flex;
    justify-content:space-between;
}

</style>
