<template>
    <div id ="bulk-export">

        <div v-if="!user.admin" class='card-panel red lighten-4'>
            This page is only accessible for administrators.
        </div>
        <div v-else>

            <h4>Download data from firestore</h4>
            <p>The below links allow you to download the various collections from the database as JSON files.</p>
            <!--<a href="data:application/octet-stream;charset=utf-16le;base64,//5mAG8AbwAgAGIAYQByAAoA">text file</a>-->
            <button v-on:click="prepareDataDownloads" class="btn waves-effect waves-ripple">Prepare downloads</button>
            <br>
            <a v-if="recipesDl" v-bind:href="recipesDl" v-bind:download="recipesFn">{{recipesFn}}</a><br>
            <a v-if="catmdDl" v-bind:href="catmdDl" v-bind:download="catmdFn">{{catmdFn}}</a><br>
            <a v-if="ingmdDl" v-bind:href="ingmdDl" v-bind:download="ingmdFn">{{ingmdFn}}</a><br>
            <a v-if="invDl" v-bind:href="invDl" v-bind:download="invFn">{{invFn}}</a><br>
            <!-- <a v-if="recingDl" v-bind:href="recingDl" v-bind:download="recingFn">{{recingFn}}</a><br> -->
            <a v-if="notesDl" v-bind:href="notesDl" v-bind:download="notesFn">{{notesFn}}</a><br>
            <a v-if="usersDl" v-bind:href="usersDl" v-bind:download="usersFn">{{usersFn}}</a><br>
            <a v-if="listDl" v-bind:href="listDl" v-bind:download="listFn">{{listFn}}</a>

            <h4>Backup pictures from firebase</h4>
            <p>Below is a powershell script to download all pictures from firebase to a local destination as backup. You can enter the target destination for the download below.</p>
            <div class="input-field">
                <input type="text"  v-model="target">
                <label class="active">Destination location</label>
            </div>
            <div v-for="pic in pictures"
                    v-bind:key="pic.name">
                $url = '{{pic.url}}' <br>
                $output = '{{target}}{{pic.name}}' <br>
                Invoke-WebRequest -Uri $url -OutFile $output
            </div>     

            <!-- <h4>Download links airtable pictures</h4>
            <ul>
                <li v-for="recipe in recipes"
                    v-bind:key="recipe.id" class="collection-item">
                    <div v-if="recipe.pictureDish">
                        $url = '{{recipe.pictureDish}}' <br>
                        $output = '{{target}}G {{recipe.recipeTitle}}{{recipe.pictureDishExtension}}' <br>
                        Invoke-WebRequest -Uri $url -OutFile $output
                    </div>
                    <div v-if="recipe.pictureRecipe">
                        $url = '{{recipe.pictureRecipe}}' <br>
                        $output = '{{target}}R {{recipe.recipeTitle}}{{recipe.pictureRecipeExtension}}' <br>
                        Invoke-WebRequest -Uri $url -OutFile $output
                    </div>
                    <div v-if="recipe.diagramRecipe">
                        $url = '{{recipe.diagramRecipe}}' <br>
                        $output = '{{target}}D {{recipe.recipeTitle}}{{recipe.diagramRecipeExtension}}' <br>
                        Invoke-WebRequest -Uri $url -OutFile $output
                    </div>
                </li>
            </ul>   -->
        
        </div>
               
                                  
        
    </div>
</template>

<script>
import db from './firebaseInit'
// import firebase from 'firebase/compat/app'
import {query, getDocs, collection, orderBy} from 'firebase/firestore'
import {listAll, getStorage, ref, getDownloadURL} from 'firebase/storage'

export default {
    name: 'bulk-export',
    props: ['user'],
    data() {
        return {
            recipes: [],
            urls: [],
            pictures : [],
            target: 'C:\\temp\\firebase\\',
            recipesDl: null,
            catmdDl: null,
            ingmdDl: null,
            invDl: null,
            // recingDl: null,
            notesDl: null,
            usersDl: null,
            listDl: null,
            householdDl: null,
            recipesFn: 'Recipes.json',
            catmdFn: 'CategoryMasterData.json',
            ingmdFn: 'IngredientMasterData.json',
            invFn: 'Inventory.json',
            // recingFn: 'RecipeIngredient.json',
            // calnotesFn: 'CalendarNotes.json',
            notesFn: 'Notes.json',
            usersFn: 'Users.json',
            listFn: 'List.json',
            householdFn: 'Household.json',
            catmd: [],
            ingmd : [],
            inv : [],
            // recing: [],
            notes: [],
            users: [],
            list: [],
            household: []

        }
    },
    created() {
        console.log('Bulk export - created')
        
        if(this.user.admin){
            //const regex =/\((.*?)\)/
            // var storage = firebase.storage()
            // var storageRef = storage.ref()
            // var listRef = storageRef.child('');
            
            const storage = getStorage();

            // Create a reference under which you want to list
            const listRef = ref(storage, '');

            // Find all the prefixes and items.
            listAll(listRef)
            .then((res) => {
                res.items.forEach(itemRef => {
                    // All the items under listRef.
                    getDownloadURL(itemRef).then(url=>{ 
                        this.urls.push(url) 
                        this.pictures.push({
                            url: url,
                            name: itemRef.name
                        })
                    })
                    // itemRef.getDownloadURL().then(url=>{ 
                    //     this.urls.push(url) 
                    //     this.pictures.push({
                    //         url: url,
                    //         name: itemRef.name
                    //     })
                    // })
                })
            }).catch((error) => {
                console.error(error)
            });
            
            // listRef.listAll().then(res =>{
            //     res.items.forEach(itemRef => {
            //         // All the items under listRef.
            //         itemRef.getDownloadURL().then(url=>{ 
            //             this.urls.push(url) 
            //             this.pictures.push({
            //                 url: url,
            //                 name: itemRef.name
            //             })
            //         })
            //     })
            // }).catch(error =>{
            //     console.error(error)
            // })
        }

    },
    methods:{
        pictureDetails(pictureString){
        var extension
        var downloadUrl
        //console.log(pictureString)
        const regex =/\((.*?)\)/
            if (pictureString){
                extension = 
                    pictureString.includes('.jpg') ? '.jpg' : 
                    pictureString.includes('.jpeg') ? '.jpeg' :
                    pictureString.includes('.png') ? '.png' :
                    console.log('No extension found for : ' + pictureString)
                    //console.log(extension)
                    //console.log(pictureString.match(regex))
                
                if(pictureString.includes('airtable')){
                    downloadUrl = pictureString.match(regex)[1]
                }
                return {
                    downloadUrl : downloadUrl,
                    extension: extension
                }
            }
            else {
                return {
                    downloadUrl : "",
                    extension: ""
                }
            }

        },
        prepareDataDownloads(){
            //Create download of data in firestore

            const qr = query(collection(db,"Recipes"),orderBy('recipeTitle'))
            getDocs(qr).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    
                    var recipeEnriched = doc.data()

                    var pictureRecipe = this.pictureDetails(recipeEnriched.pictureRecipe)
                    recipeEnriched.pictureRecipe = pictureRecipe.downloadUrl
                    recipeEnriched.pictureRecipeExtension = pictureRecipe.extension

                    var pictureDish = this.pictureDetails(recipeEnriched.pictureDish)
                    recipeEnriched.pictureDish = pictureDish.downloadUrl
                    recipeEnriched.pictureDishExtension = pictureDish.extension

                    var diagramRecipe = this.pictureDetails(recipeEnriched.diagramRecipe)
                    recipeEnriched.diagramRecipe = diagramRecipe.downloadUrl
                    recipeEnriched.diagramRecipeExtension = diagramRecipe.extension

                    this.recipes.push(recipeEnriched)
                })  
                this.recipesDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.recipes))
            })
            // db.collection('Recipes').orderBy('recipeTitle').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
                    
            //         var recipeEnriched = doc.data()

            //         var pictureRecipe = this.pictureDetails(recipeEnriched.pictureRecipe)
            //         recipeEnriched.pictureRecipe = pictureRecipe.downloadUrl
            //         recipeEnriched.pictureRecipeExtension = pictureRecipe.extension

            //         var pictureDish = this.pictureDetails(recipeEnriched.pictureDish)
            //         recipeEnriched.pictureDish = pictureDish.downloadUrl
            //         recipeEnriched.pictureDishExtension = pictureDish.extension

            //         var diagramRecipe = this.pictureDetails(recipeEnriched.diagramRecipe)
            //         recipeEnriched.diagramRecipe = diagramRecipe.downloadUrl
            //         recipeEnriched.diagramRecipeExtension = diagramRecipe.extension

            //         this.recipes.push(recipeEnriched)
            //     })  
            //     this.recipesDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.recipes))
            // }) 

            const qc = query(collection(db,"CategoryMasterData"))
            getDocs(qc).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.catmd.push(doc.data())
                })  
                this.catmdDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.catmd))
            })

            // db.collection('CategoryMasterData').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.catmd.push(doc.data())
            //     })  
            //     this.catmdDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.catmd))
            // }) 

            const qimd = query(collection(db,"IngredientMasterData"))
            getDocs(qimd).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.ingmd.push(doc.data())
                })  
                this.ingmdDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.ingmd))
            })

            // db.collection('IngredientMasterData').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.ingmd.push(doc.data())
            //     })  
            //     this.ingmdDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.ingmd))
            // }) 

            const qinv = query(collection(db,"InventoryList"))
            getDocs(qinv).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.inv.push(doc.data())
                })  
                this.invDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.inv))
            })

            // db.collection('Inventory').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.inv.push(doc.data())
            //     })  
            //     this.invDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.inv))
            // }) 

            // db.collection('RecipeIngredient').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.recing.push(doc.data())
            //     })  
            //     this.recingDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.recing))
            // }) 

            // db.collection('CalendarNotes').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.calnotes.push(doc.data())
            //     })  
            //     this.calnotesDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.calnotes))
            // }) 

            const qn = query(collection(db,"Notes"))
            getDocs(qn).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.notes.push(doc.data())
                })  
                this.notesDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.notes))
            })

            // db.collection('Notes').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.notes.push(doc.data())
            //     })  
            //     this.notesDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.notes))
            // }) 

            const ql = query(collection(db,"List"))
            getDocs(ql).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.list.push(doc.data())
                })  
                this.listDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.list))
            })

            // db.collection('List').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.list.push(doc.data())
            //     })  
            //     this.listDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.list))
            // }) 

            const qu = query(collection(db,"Users"))
            getDocs(qu).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.users.push(doc.data())
                })  
                this.usersDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.users))
            })

            const qh = query(collection(db,"Household"))
            getDocs(qh).then(querySnapshot=>{
                querySnapshot.forEach(doc=>{
                    this.household.push(doc.data())
                })  
                this.householdDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.household))
            })

            // db.collection('Users').get().then((querySnapshot) =>{
            //     querySnapshot.forEach(doc=>{
            //         this.users.push(doc.data())
            //     })  
            //     this.usersDl = 'data:application/octet-stream;charset=utf-16le,' + encodeURI(JSON.stringify(this.users))
            // })

            //this.jsonDownload = 'data:application/octet-stream;charset=utf-16le;base64,//5mAG8AbwAgAGIAYQByAAoA'            
            
        }
    }
}
</script>