<template>
    <div id ="bulk-add">
        <div v-if="user">
            <div v-if="!user.admin" class='card-panel red lighten-4'>
                This page is only accessible for administrators.
            </div>
            <div v-else>

                <h3>Bulk add</h3>
                NOTE: This page needs maintainance before use!!!
                <!--
                <h5>JSON add a single recipe</h5>
                <form>
                    <label>JSON Recipe</label>
                    <textarea v-model="jsonInput" class="materialize-textarea"></textarea>
                    <button v-on:click="jsonAdd" class="btn">Submit</button>
                </form>
                -->
                        
                <h5>Add JSON array of {{targetCollection}}</h5>
                <form>
                    <div>
                        <button v-on:click="jsonBulkAdd()" class="btn waves-effect waves-ripple ">Submit</button>
                    </div>
                    <div class="input-field">
                        
                        <input type="text" v-model="targetCollection" class="materialize-textarea">
                    </div>
                    
                    <div class="input-field">
                        <label>Array of JSON objects</label>
                        <textarea v-model="jsonArray" class="materialize-textarea"></textarea>
                    </div>
                    
                </form>

                <h5>Categorize data</h5>
                <p>CATEGORIZE will run through all elements of each recipe.ingredients array and convert these into nested objects that include the category information. SET OWNER will set an owner on all recipes that do not yet have one.</p>
                <button v-on:click="categorize" class="btn waves-effect waves-ripple ">Categorize recipe ingredients</button>   
                <br>
                <br>
                <button v-on:click="categorizeInv" class="btn waves-effect waves-ripple ">Categorize inventory items</button>   
                <br>
                <br>
                <button v-on:click="setHousehold" class="btn waves-effect waves-ripple ">Set household</button>   
                <br>
                <br>
                <button v-on:click="setCreator" class="btn waves-effect waves-ripple ">Set creator</button>   
                <br>
                <br>
                <button v-on:click="setIngredientLowerCase" class="btn waves-effect waves-ripple ">Set ingredient lowercase</button>   
                <br>
                <br>
                <button v-on:click="setHouseholdMetadata" class="btn waves-effect waves-ripple ">Set household metadata</button>   
                <br>
                <br>
                <button v-on:click="fixPictureFilenames" class="btn waves-effect waves-ripple ">Fix picture filenames</button>   
                <br>
                <br>
                <button v-on:click="addCategoryId" class="btn waves-effect waves-ripple ">Add category id</button>   
                <br>
                <br>
                <button v-on:click="addTimeBetween" class="btn waves-effect waves-ripple ">Add AVG time between eat dates</button>   
                <br>
                <br>

                <!--
                <h5>Add JSON array of ingredients</h5>
                <form>
                    <div>
                        <button v-on:click="jsonBulkAdd('RecipeIngredient')" class="btn">Submit</button>
                    </div>
                    <label>Array of JSON ingredients</label>
                    <textarea v-model="jsonArray" class="materialize-textarea"></textarea>
                </form>
                -->

            </div>
           
        </div>    
        

    </div>
</template>

<script>
import db from './firebaseInit'
import sharedlogic from '@/scripts/sharedlogic'
// import firebase from 'firebase/compat/app';

export default {
    name: 'bulk-add',
    props: ['recipes','inventory','user'],
    data() {
        return {
            jsonInput: null,
            jsonArray: null,
            targetCollection: "Recipes",
            //recipes:[],
            ingredients:[],
            ingredientMasterData:[],
            categoryMasterData:[]
            //inventory: []
        }
    },
    methods : {
        /*
        fixPictureFilenames(){
            
            var rStorage = firebase.storage();     
            var i =1

            this.recipes.forEach(recipe=>{
                if(i){

                    console.log(recipe.recipeTitle)
                    
                    var urlp = null
                    var filename = recipe.pictures.recipe.filename
                    if(filename){
                        var PathReference = rStorage.ref(filename)
                        urlp = PathReference.getDownloadURL()//.then(url=>console.log(url))
                    } 

                    // }
                    if(urlp){
                        urlp.then(url => {
                            db.collection('Recipes').doc(recipe.id).update({
                                // backup : { 
                                //     pictureDish : recipe.pictureDish,
                                //     pictureRecipe : recipe.pictureRecipe,
                                //     diagramRecipe : recipe.diagramRecipe
                                
                                pictures : {
                                    recipe : {
                                        filename : this.desiredPictureName(recipe.recipeTitle,recipe.pictureRecipe,'R '),
                                        url : url
                                        
                                    },
                                    dish : {
                                        filename : this.desiredPictureName(recipe.recipeTitle,recipe.pictureDish,'G '),
                                        url : recipe.pictures.dish.url ? recipe.pictures.dish.url : null
                                    },
                                    diagram : {
                                        filename : this.desiredPictureName(recipe.recipeTitle,recipe.diagramRecipe,'D '),
                                        url : recipe.pictures.diagram.url ? recipe.pictures.diagram.url : null
                                    }
                                }
                            })
                        })
                    }
                   
                }
                i++
                


            })
            
        }, 
        desiredPictureName(recipeTitle, currentfilename, prefix){
            // console.log(recipeTitle)
            // console.log(currentfilename)
            // console.log(prefix)
            console.log(currentfilename)
        
            var filename = null
            if (currentfilename){
                if(currentfilename.includes('airtable')){
                    //TODO: If I rename all picture data on the airtable recipes, this logic can be removed.
                    // console.log('Currentfilename contains "airtable" - Entering legacy logic for determining filename in storage. Picture info : ' + currentfilename)
                                        
                    var extension = '.jpg'
                    if (currentfilename.includes('.png')){
                        extension = '.png'
                    }
                    filename = prefix + recipeTitle + extension
                }
                else{
                    // console.log('Assuming the filename is known. Assumed filename : ' + currentfilename)
                    filename = currentfilename
                }
            }
            console.log(filename)
            return filename
        },*/
        jsonAdd(){
            console.log('Json add function')
            
            db.collection('Recipes').add(JSON.parse(this.jsonInput))
            .then(this.$router.push('/'))
            .catch(error => console.log(error))
            
        },
        setIngredientLowerCase(){
                db.collection('IngredientMasterData').get().then(querySnapshot=>{
                this.ingredientMasterData = []
                querySnapshot.forEach(doc=>{
                    var imd = doc.data()
                    imd.id = doc.id
                    this.ingredientMasterData.push(imd)
                })
                this.ingredientMasterData.forEach(ing=>{
                    //console.log(ing)
                    if(!ing.ingredientNameLowerCase){
                        console.log(ing.ingredientName)
                        ing.ingredientNameLowerCase = ing.ingredientName.toLowerCase()
                        db.collection('IngredientMasterData').doc(ing.id).set(ing)
                    }
                    
                })
            })
        },
        setCreator(){
            console.log('Set creator')
            this.recipes.forEach(recipe=>{
                    //console.log(recipe)
                if (!recipe.creator){
                    console.log('No creator on : ' + recipe.recipeTitle + '. Adding it now!')
                    db.collection('Recipes').doc(recipe.id).update({
                            //owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
                        creator:  recipe.owner ? recipe.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
                    })
                }
            })
                
            this.inventory.forEach(inv=>{
                //console.log(inv)
                if (!inv.creator){
                    console.log('No creator on : ' + inv.invItemName + '. Adding it now!')
                    db.collection('Inventory').doc(inv.id).update({
                        creator:  inv.owner ? inv.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2",
                        household:  "uRsLe6Z6Ur4CiLIJdb0Y"
                    })
                }
            })

            db.collection('IngredientMasterData').get().then(querySnapshot=>{
                this.ingredientMasterData = []
                querySnapshot.forEach(doc=>{
                    var imd = doc.data()
                    imd.id = doc.id
                    this.ingredientMasterData.push(imd)
                })
                this.ingredientMasterData.forEach(ing=>{
                    //console.log(inv)
                    if (!ing.creator){
                        console.log('No creator on : ' + ing.ingredientName + '. Adding it now!')
                        db.collection('IngredientMasterData').doc(ing.id).update({
                            creator:  ing.owner ? ing.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
                        })
                    }

                    if (!ing.household){
                        console.log('No household on : ' + ing.ingredientName + '. Adding it now!')
                        db.collection('IngredientMasterData').doc(ing.id).update({
                            household:  "uRsLe6Z6Ur4CiLIJdb0Y"
                        })
                    }
                })
            })

            // this.list.forEach(l=>{
            //     //console.log(inv)
            //     if (!l.creator){
            //         console.log('No creator on : ' + l.name + '. Adding it now!')
            //         db.collection('List').doc(l.id).update({
            //             creator:  l.owner ? l.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
            //         })
            //     }
            // })

            // this.noteList.forEach(l=>{
            //     //console.log(inv)
            //     if (!l.creator){
            //         console.log('No creator on : ' + l.name + '. Adding it now!')
            //         db.collection('Notes').doc(l.id).update({
            //             creator:  l.owner ? l.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
            //         })
            //     }
            // })

            // this.users.forEach(u=>{
            //     //console.log(inv)
            //     if (!u.creator){
            //         console.log('No creator on : ' + u.name + '. Adding it now!')
            //         db.collection('Users').doc(u.id).update({
            //             creator:  u.owner ? u.owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
            //         })
            //     }
            // })
        },
        setHousehold(){
            // console.log('Set household')
            // console.log('On recipes')
            // this.recipes.forEach(recipe=>{
            //     //console.log(recipe)
            //     if (!recipe.household){
            //         console.log('No household on : ' + recipe.recipeTitle + '. Adding it now!')
            //         db.collection('Recipes').doc(recipe.id).update({
            //             //owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
            //             household: "uRsLe6Z6Ur4CiLIJdb0Y"
            //         })
            //     }
            // })
            
            // console.log('On inventory')
            // this.inventory.forEach(inv=>{
            //     //console.log(inv)
            //     if (!inv.household){
            //         console.log('No household on : ' + inv.invItemName + '. Adding it now!')
            //         db.collection('Inventory').doc(inv.id).update({
            //             //owner : "4KtN4KJPbcb25HlJRALM9MeMa7b2"
            //             household: "uRsLe6Z6Ur4CiLIJdb0Y"
            //         })
            //     }
            // })


        },
        /*
        setHouseholdMetadata(){

            //const regex =/\((.*?)\)/
            var storage = firebase.storage()
            var storageRef = storage.ref()
            var listRef = storageRef.child('');

            // console.log(listRef)
            
            var metadata = {
                customMetadata: {
                    creator : '4KtN4KJPbcb25HlJRALM9MeMa7b2',
                    household : 'uRsLe6Z6Ur4CiLIJdb0Y'
                }
            }
            
            // console.log(metadata)            
            listRef.listAll().then(res =>{
                res.items.forEach(itemRef => {
                    itemRef.updateMetadata(metadata)
                    console.log(itemRef.name)
                });

            }).catch(error =>{
                console.error(error)
            });
        },*/
        jsonBulkAdd(){
            console.log(this.targetCollection)

            //TODO: Add handling in case jsonArray cannot be parsed to JSON
            const jsonArrayp = JSON.parse(this.jsonArray)
            //console.log(jsonArrayp)
            
            
            jsonArrayp.forEach(element => {
                if( element.ingredientsCommaSeparated){
                    element.ingredients = element.ingredientsCommaSeparated.split(',')
                }
                if(!element.ingredients){
                    element.ingredients = [element.recipeTitle]
                }

            })


            //console.log(jsonArrayp)
           
            
            var batch = db.batch()
            if(this.targetCollection == null || this.targetCollection === '' ){
                console.log('Target collection cannot be blank! - Aborting')
            } else if (!Array.isArray(jsonArrayp)){
                console.log('Content is not a JSON Array - Aborting')
            } else {
                if (this.targetCollection != null && Array.isArray(jsonArrayp)){
                    jsonArrayp.forEach((doc) => {
                        //var docRef = db.collection(this.targetCollection).doc(); //automatically generate unique id

                        //To chose the document ID manually, use lines below.
                        //var docRef = db.collection(this.targetCollection).doc(doc.categoryName.replaceAll('/','ß')); //CategoryMasterData - Works in chrome 85!
                        //var docRef = db.collection(this.targetCollection).doc(doc.ingredientName.replaceAll('/','ß')); //IngredientMasterData & RecipeIngredient - Works in chrome 85!
                        //var docRef = db.collection(this.targetCollection).doc(doc.recipeTitle.replaceAll('/','ß')); //Recipes - Works in chrome 85!
                        var docRef = db.collection(this.targetCollection).doc(sharedlogic.safeUID(doc.recipeTitle));
                        //var docRef = db.collection(this.targetCollection).doc(doc.recipeTitle.replace('/','ß').replace('/','ß').replace('/','ß')); //Nasty hack - not necessary in chrome 85!
                        //console.log(docRef)

                        //var docRef = doc.recipeTitle
                        batch.set(docRef, doc);
                        console.log(batch)
                        })
                    batch.commit().then(error => console.log(error))
                }
            }
            
        },
        categorizeInv(){
            //this.getData()
            
            var ingi = 0
            var newInventory
            this.inventory.forEach(inventory => {
                console.log(inventory)
                
                if (inventory.doc.category == undefined){ //Check if conversion has not already been done.
                    
                    newInventory = {
                        category: this.findCatMaster(this.findCategory(inventory.doc.ingredientName)), 
                        invItemName: inventory.doc.ingredientName,
                        frequency : inventory.doc.frequency,
                        order : inventory.doc.order
                        }
                    this.inventory[ingi].doc = newInventory
                    
                    ingi++
                }
                else{
                    console.log('Inventory item already categorised')
                    console.log(inventory.doc.invItemName)
                    console.log(inventory.doc.categoryName)
                }
                
                //console.log(inventory)
                db.collection('Inventory').doc(inventory.id).set(inventory.doc)
            }) 
        },

        categorize(){
            //this.getData()
            
            var reci = 0
            var ingi = 0
            this.recipes.forEach(recipe => {
                
                recipe.doc.ingredients.forEach(ingredient =>{
                    
                    if (ingredient.category == undefined){ //Check if conversion has not already been done.

                        if ( ingredient ==''){
                            ingredient = recipe.doc.recipeTitle
                        }
                        ingredient = {
                            category: this.findCatMaster(this.findCategory(ingredient)), 
                            ingredientName: ingredient
                            }
                        this.recipes[reci].doc.ingredients[ingi] = ingredient
                        
                        ingi++
                    }
                    else{
                        
                        console.log('Ingredient already categorised')
                        console.log(ingredient.ingredientName)
                        console.log(ingredient.categoryName)
                    }
                })
                console.log(recipe.id)
                console.log(recipe.doc)
                /*
                if (recipe.id == undefined){
                    console.log(reci)
                }

                if (recipe.doc == undefined){
                    console.log(reci)
                }
                */

                db.collection('Recipes').doc(recipe.id).set(recipe.doc)

                reci++
                ingi = 0

            }) 

        },
        addCategoryId(){
            //Loops through all ingredients and makes sure the ingredient has a property id on the category.
            let i = 0
            let somethingToSave = false;
            this.recipes.forEach(recipe => {
                i++
                console.log(i)
                somethingToSave = false;
                console.log('RECIPE - ' + recipe.recipeTitle)
                recipe.ingredients.forEach(ingredient =>{
                    
                    if (!ingredient.category.id){ //Check if conversion has not already been done.
                        console.log('Ingredient category NO id')
                        console.log(ingredient.category.categoryName)
                        ingredient.category.id = ingredient.category.categoryName.replaceAll('/','ß')
                                                
                        console.log(ingredient.ingredientName)
                        console.log(ingredient.category.id)
                        somethingToSave = true;
                    }
                    else{
                        
                        // console.log('Ingredient category has an id')
                        
                        // console.log(ingredient.ingredientName)
                        // console.log(ingredient.category.id)
                    }
                })
                if (i<=50 && somethingToSave){
                    console.log('RECIPE WILL BE SAVED')
                    console.log(recipe)
                    db.collection('Recipes').doc(recipe.id).set(recipe)
                }

                // db.collection('Recipes').doc(recipe.id).set(recipe.doc)

            }) 

        },

        findCategory(ingredient){
            var imd = this.ingredientMasterData.find(md => md.ingredientName == ingredient)

            if (imd == undefined){
                return 'undefined'
            }
            else{
                return imd.categoryName
            }
            
        },
        findCatMaster(category){
            var cmd = this.categoryMasterData.find(catmd => catmd.categoryName == category)
            
            if (cmd == undefined){
                return 'undefined'
            }
            else{
                return cmd
            }
        }

    }
}
 
</script>